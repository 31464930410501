import { EDIT_FINANCE, LOAD_FINANCE } from '../actionTypes';

const INITIAL_STATE = [];

export default function financeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EDIT_FINANCE:
      const editedFinance = state.find(finance => finance._id === action.finance._id);
      const index = state.indexOf(editedFinance);
      state[index] = action.finance;
      return [...state];
    case LOAD_FINANCE:
      return action.finances;
    default:
      return state;
  }
};