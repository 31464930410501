const routes = {
  //
  // Auth
  //
  login: '/login',// Si se va cambiar esto, cambiar en el server
  reset_password: '/reset-password',// Si se va cambiar esto, cambiar en el server
  confirmation: '/confirmation',// Si se va cambiar esto, cambiar en el server
  welcome: '/welcome',// con /:token// Si se va cambiar esto, cambiar en el server

  //
  // Expert
  //
  home: '/',
  intro: '/intro',
  patients: '/patients',
  patients_report: '/patients/report',
  // patient_profile: '/patients/',// con /:patient_id
  scheduler: '/scheduler',
  tours: '/tours',
  appointment: '/appointment',
  mealPlans: '/plans',
  mealPlan_create: '/plans/create',
  mealPlan_edit: '/plans/edit',
  mealPlan_view: '/plans/view',
  workouts: '/workouts',
  foods: '/foods',
  equivalents: '/equivalents',
  templates: '/templates',
  template_create: '/templates/create',
  template_edit: '/templates/edit',
  template_view: '/templates/view',
  recipes: '/recipes',
  recipe_create: '/recipes/create',
  recipe_edit: '/recipes/edit',
  recipe_view: '/recipes/view',
  stats: '/stats',
  finance: '/finance',
  referrals: '/referrals',
  settings: '/settings',
  settings_profile: '/settings/profile',
  settings_services: '/settings/services',
  settings_web_tools: '/settings/web',
  settings_general: '/settings/general',
  settings_general_hours: '/settings/general/hours',
  settings_custom_fields: '/settings/general/custom-fields',
  settings_mailing: '/settings/mailing',
  settings_visual: '/settings/visual',
  settings_security: '/settings/security',// Si se va cambiar esto, cambiar en el server
  settings_subscription: '/settings/subscription',// Si se va cambiar esto, cambiar en el server
  billing_plans: '/subscribe',

  //
  // Personal Page
  //
  book_appointment: '/booking',
  know_me: '/info',
  recipes_web: '/recipe',
  // recipes_web_view: '/recipe/',// con /:recipe_id
  blog: '/blog',
  // blog_view: '/blog/',// con /:blog_id
  links: '/links',
};

const routesPaths = {
  '/': 'Inicio',
  '/patients': 'Pacientes',
  '/patients/': 'Perfil de Paciente',
  '/scheduler': 'Agenda',
  '/tours': 'Tours y videos',
  '/appointment': 'Consulta',
  '/plans': 'Dietas',
  '/plans/create': 'Nuevo plan alimenticio',
  '/plans/edit': 'Editar plan alimenticio',
  '/plans/view': 'Ver plan alimenticio',
  '/foods': 'Alimentos',
  '/equivalents': 'Equivalentes',
  '/templates': 'Plantillas',
  '/templates/create': 'Crear plantilla',
  '/templates/edit': 'Editar plantilla',
  '/templates/view': 'Ver plantilla',
  '/recipes': 'Recetas',
  '/recipes/create': 'Crear receta',
  '/recipes/view': 'Ver receta',
  '/stats': 'Estadísticas',
  '/finance': 'Finanzas',
  '/referrals': 'Referidos',
  '/reset-password': 'Restablecer Contraseña',
  '/settings': 'Configuración',
  '/settings/profile': 'Configuración - Perfil',
  '/settings/services': 'Configuración - Paquetes Nutricionales',
  '/settings/web': 'Configuración - Herramientas Web',
  '/settings/general': 'Configuración - Ajustes Generales',
  '/settings/general/hours': 'Configuración - Horario de atención',
  '/settings/mailing': 'Configuración - Correo',
  '/settings/visual': 'Configuración - Visual',
  '/settings/security': 'Configuración - Seguridad',
  '/settings/subscription': 'Configuración - Suscripción',
  '/subscribe': 'Planes',
  '/info': 'Página personal - Inicio',
  '/recipe': 'Página personal - Recetas',
  '/recipe/': 'Página personal - Ver Receta',
  '/blog': 'Página personal - Blog',
  '/blog/': 'Página personal - Artículo del blog',
};

export const getRouteName = (route) => {
  if (route.slice(0, 11) === '/patients/') {
    return routesPaths['/patients/'];
  } else if (route.slice(0, 8) === '/recipe/') {
    return routesPaths['/recipe/'];
  } else if (route.slice(0, 6) === '/blog/') {
    return routesPaths['/blog/'];
  } else {
    return routesPaths[route];
  }
};

export default routes;
