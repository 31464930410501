import { ADD_WORKOUT_TEMPLATE, LOAD_WORKOUT_TEMPLATES, EDIT_WORKOUT_TEMPLATE, DELETE_WORKOUT_TEMPLATE} from '../actionTypes';

const INITIAL_STATE = [];

export default function workoutsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_WORKOUT_TEMPLATES:
      return [...action.workoutTemplates];
    case ADD_WORKOUT_TEMPLATE:
      return [...state, action.workoutTemplate];
    case EDIT_WORKOUT_TEMPLATE:
      const editedWorkoutTemplate = state.find(e => e._id === action.workoutTemplate._id);
      const index = state.indexOf(editedWorkoutTemplate);
      state[index] = action.workoutTemplate;
      return [...state];
    case DELETE_WORKOUT_TEMPLATE:
      return state.filter(e => e._id !== action.id);
    default:
      return state;
  }
};
