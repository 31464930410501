import * as Sentry from '@sentry/react';
import packageJson from '../package.json';

// Initial set-up
export const initSentry = () => {
  if(process.env.NODE_ENV === "production"){
    Sentry.init({
      dsn: "https://5d567e1ff9d4458690390a0a2eb664f1@sentry.io/1517710",
      environment: process.env.REACT_APP_ENV,
      ignoreErrors: [
        'Network Error', 
        'ResizeObserver loop limit exceeded', 
        'ChunkLoadError', 
        "Cannot read property 'isSameNode' of undefined",
        'Socket server did not execute the callback for setAttributes with data',
        "Cannot read properties of undefined (reading 'isSameNode')"
      ],
    });
    Sentry.setTag("app_version", packageJson.version);
  }
}

// Manually capture errors
export const captureError = err => {
  if(process.env.NODE_ENV === 'production' && err){
    Sentry.captureException(err);
  }
} 

/**
 * 
 * @param {string} errorTitle - Identificador del evento. Puede ser un título claro. 
 * Los errores que se capturen se agruparan en Sentry por cada errorTitle.
 * 
 * @param {Array} events 
 * Array de objetos cuyas propiedades son category y message.
 * @param {String} events.$.category 
 * Categoría del evento. Podría ser el nombre del file + la función donde se pasa el message.
 * @param {String} events.$.message Mensaje del evento. En caso se quiera pasar un objeto, aplicarle JSON.stringify
 *  IMPORTANTE: no exceder los 400kb.
 * 
 * Ejemplo: 
 * - errorTitle = 'add_food_to_mealPlan'
 * - events = [ { category: 'foodAdd', message: "{foodId: undefined, quantity: 2}"}]
 */
export const captureCustomError = (errorTitle, events = []) => {
  try {
    for (let i = 0; i < events.length; i++) {
      if(events[i] && events[i].category && events[i].message){
        Sentry.addBreadcrumb({
          category: events[i].category,
          message: events[i].message,
          level: Sentry.Severity.Info
        });
      }
    }
    throw new Error(errorTitle)
  } catch (err) {
    Sentry.captureException(err);
  }
}

/**
 * 
 * @param {String} category 
 * Categoría del evento. Podría ser el nombre del file + la función donde se pasa el message.
 * @param {String} message Mensaje del evento. En caso se quiera pasar un objeto, aplicarle JSON.stringify
 *  IMPORTANTE: no exceder los 400kb.
 */
export const addBreadcrumb = (category, message) => {
  Sentry.addBreadcrumb({
    category,
    message,
    level: Sentry.Severity.Info
  });
}
