import { useState } from 'react';
import { Button } from 'antd';
import CancelActionSVG from '../../../images/cancel_action.svg';
import { useTranslation } from 'react-i18next';
import './Error500.css';
import { useHistory, useLocation } from 'react-router';

const Error500 = ({ dismissError }) => {
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const isDismissable = () => {
    let errorBoundary = window.localStorage.getItem("error_boundary_r");
    errorBoundary = errorBoundary * 1 + 1;
    if (isNaN(errorBoundary)) {
      window.localStorage.setItem("error_boundary_r", 1);
      return true;
    } else if(errorBoundary === 2) {
      window.localStorage.setItem("error_boundary_r", 0)
      return false;
    } else {
      window.localStorage.setItem("error_boundary_r", errorBoundary)
      return true;
    }
  }

  const handleClick = () => {
    setLoading(true);
    const dismissable = dismissError && isDismissable();
    if (dismissable) {
      setTimeout(() => {
        dismissError();
        history.replace(location.pathname);
      }, 500);
    } else {
      window.location.href = '/';
    }
  };
  return (
    <div className="error-500-boundary">
      <img src="https://assets.almendra.io/Logo_Almendra_Double.svg" className="logo" width="192" alt="" />
      <div className="browser">
        <div className="controls">
          <i style={{ backgroundColor: '#FF605C' }}></i>
          <i style={{ backgroundColor: '#FFBD44' }}></i>
          <i style={{ backgroundColor: '#00CA4E' }}></i>
        </div>
        <div className="browser-container">
          <div
            style={{
              width: 192,
              backgroundImage: `url(${CancelActionSVG}`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100%',
              backgroundPosition: 'center',
            }}
          />
        </div>
      </div>
      <div>
        <h1>{t('error_bug_title')}</h1>
        <p>{t('error_bug_description')}</p>
        <div style={{ display: 'block', margin: '6px auto 20px' }}>
          <Button type="primary" shape="round" onClick={handleClick} loading={loading}>
            {dismissError ? t('reload') : t('error_bug_cta')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Error500;
