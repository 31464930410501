import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import App from './Main/containers/App';
import './index.css';
import { initSentry } from './Sentry';
import reportWebVitals from './reportWebVitals';
import './translations/i18n';

const history = createBrowserHistory();
initSentry();

if (process.env.REACT_APP_ENV === 'production') {
  function noop() {}
  console.log = noop;
}

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
  history.replace(path);
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
