import { ADD_CUSTOM_FIELD, LOAD_CUSTOM_FIELDS, EDIT_CUSTOM_FIELD, DELETE_CUSTOM_FIELD } from '../actionTypes';

const INITIAL_STATE = [];

export default function customFieldsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_CUSTOM_FIELD:
      return [...state, action.customField];
    case LOAD_CUSTOM_FIELDS:
      return [...action.customFields];
    case EDIT_CUSTOM_FIELD:
      const editedCustomField = state.find(x => x._id === action.customField_id);
      const index = state.indexOf(editedCustomField);
      state[index] = action.customField;
      return [...state];
    case DELETE_CUSTOM_FIELD:
      return state.filter(x => x._id !== action.customField_id);
    default:
      return state;
  }
};