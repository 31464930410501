export const TRANSLATIONS_EN = {
  // GenericResponses
  res_error_catch: 'Oops! There was problem while processing this action',
  // ServerResponses
  error: 'Error',
  res_error: "Oops! Your request couldn't be made.",
  res_email_not_found: "We haven't found that email. Please, verify if it's entered correctly.",
  res_expert_auth_invalid: 'Incorrect email or password.',
  res_expert_login_try_attempts: 'You have made many attempts to log in. For your security, you will be able to retry in 10 minutes.',
  res_patient_needs_activation: 'In order to reset your password, ask your professional to activate your account.',
  res_token_invalid: 'The link entered is invalid or has expired.',
  res_token_validation_error: 'There was a problem while validating this link. Please reset your password again.',
  res_patient_auth_invalid: 'Incorrect email or password.',
  res_patient_not_linked_expert: 'Your account is not linked with this business.',
  // HomeMain
  patient: 'Patient',
  patient_other: 'Patients',
  close: 'Close',
  upcoming_appointments: 'Upcoming appointments',
  upcoming_appointments_empty: 'No upcoming appointment for the next {{minutesBeforeAppointment}} minutes',
  last_appointments: 'Last appointments',
  last_appointments_empty: "You haven't started an appointment yet",
  new_patients: 'New patients',
  new_patients_empty: "You haven't registered any patients",
  pending_mealPlans: 'Pending meal plans',
  pending_mealPlans_empty: 'No pending meal plans',
  mealPlan_format: 'Format',
  mealPlan_objectives: 'Caloric target',
  mealPlan_foods: 'Food choice',
  appointments_revenues: 'Revenues per appointment',
  appointments_revenues_empty: 'No revenues per appointment',
  change_from: 'Change from',
  weekly_change: 'Change from last week',
  quick_actions: 'Quick actions',
  appointments_status: 'Appointments status',
  appointments_status_empty: 'No started appointments for today',
  appointments_day: "Today's appointments",
  appointments_finished: 'finished',
  appointments_finished_other: 'finished',
  appointments_process: 'in progress',
  appointments_pending: 'pending',
  appointments_pending_other: 'pending',
  appointments_cancelled: 'canceled',
  appointments_cancelled_other: 'canceled',
  search_patient: 'Patient search',
  search_patient_action: 'Search',
  search_patient_action_empty: 'No patients found',
  search_patient_action_button: 'Go to profile',
  referrals: 'Referrals',
  referrals_share: 'Share it with your colleagues',
  referrals_share_copy: 'Copied to clipboard',
  referrals_current: 'You currently have <1>{{count}}</1> referral',
  referrals_current_other: 'You currently have <1>{{count}}</1> referrals',
  referrals_todo: 'With <1>{{count}}</1> referral you would pay',
  referrals_free_text: 'With <1>{{count}}</1> referral Almendra is',
  referrals_free_tag: 'FREE',
  activePatients_widget_you_have: 'You have <1>{{ count }}</1> active patient on this period',
  activePatients_widget_you_have_other: 'You have <1>{{ count }}</1> active patients on this period',
  activePatients_widget_plan: 'Plan: <1>{{ planName }}</1>',
  activePatients_widget_limit: 'Limit: <1>{{ limit }}</em>',
  activePatients_widget_report: 'Report',
  activePatients_widget_empty: "Here you'll be able to visualize your active patient usage when subscribed to a plan",
  activePatient: 'Active patient',
  activePatient_other: 'Active patients',
  // AppointmentCard
  scheduled: 'Scheduled',
  delayed: 'Delayed',
  in_progress: 'In progress',
  appointment_on_site: 'Appointment on-site',
  started_on: 'Started on',
  will_start_on: 'Will start on',
  should_started_on: 'Should started on',
  finish_appointment: 'Finish appointment',
  delete_appointment: 'Delete appointment',
  // EditWidgets
  edit_widgets_error: 'You can have 8 visible widgets at most',
  edit_widgets_title: 'Organize your dashboard with the following widgets',
  edit_widgets_content:
    'Choose the widgets that you want to be displayed on your dashboard. First, click on the checkbox at the left side of the widget name. After that, click on the blue arrow on the center in order to use the widget. Pick up max 7 widgets.',
  widget_button: 'Customize',
  widget_available: 'Available',
  widget_used: 'Used',
  widget_empty: 'There are no widgets on this list',
  widget_search: 'Search for widgets...',
  // PopupNewPatient
  add_patient: 'Add new patient',
  register_patient: 'Add new patient',
  add_patient_action: 'Add patient',
  firstName: 'First name',
  patient_v_firstName: "Please, enter the patient's first name",
  lastName: 'Last name',
  patient_v_lastName: "Please, enter the patient's last name",
  gender: 'Sex',
  patient_v_gender: "Please, select the patient's sex",
  patient_v_gender_f: 'Woman',
  patient_v_gender_m: 'Man',
  nationality: 'Country',
  patient_v_nationality: "Please, select the patient's country of residence",
  patient_show_extra: 'Show more',
  patient_hide_extra: 'Hide',
  birthDate: 'Birthdate',
  patient_v_birthDate: "Please, enter the patient's birthdate",
  email: 'Email',
  email_suggestion: 'Did you mean',
  email_required: 'Please, enter a valid email',
  patient_v_mail: "Please, enter the patient's email",
  patient_v_mail_duplicated: 'You already have registered a patient with this email',
  idNumber: 'ID number',
  patient_v_idNumber: "Please, enter the patient's ID number",
  occupation: 'Occupation',
  patient_v_occupation: "Please, enter the patient's occupation",
  phone: 'Mobile phone',
  patient_v_phone: "Please, enter the patient's mobile phone number",
  sendPasswordMail: 'Send access email?',
  yes: 'Yes',
  no: 'No',
  add_patient_error: 'The patient could not be registered. Support team has been notified about this.',
  add_patient_success: 'Patient registered successfully',
  // NewAppointmentForm
  start_appointment_online_text:
    'To start an online appointment with <1>{{service}}</1> you must link your Almendra account with your {{service}} account. Would you like to do it now?',
  loading_patient_error: "This patient's information couldn't be loaded",
  appointment_prices_includes: 'Price includes {{count}} appointment',
  appointment_prices_includes_other: 'Price includes {{count}} appointments',
  appointment_prices_includes_short: 'includes {{count}} appointment',
  appointment_prices_includes_short_other: 'includes {{count}} appointments',
  zoomLinked_msg: "You haven't linked your Zoom account yet",
  googleLinked_msg: "You haven't linked your Google account yet",
  googleMeetLinked_msg: "You haven't linked your Google Meet yet",
  googlePermissions_msg: "You haven't allowed the correct permissions on your Google account",
  appointment_v_patient: 'Select the patient',
  healthPlan: 'Appointment service',
  appointment_v_healthPlan: 'Select the appointment service',
  price: 'Price',
  appointment_v_price: 'Enter the price',
  modality: 'Method',
  modality_onsite: 'On-site',
  modality_delivery: 'Delivery',
  modality_online: 'Online',
  conferenceType: 'Video call',
  appointment_v_conferenceType: 'Select the video call service',
  conferenceType_other: 'Other service',
  conferenceRoomUrl: 'Link',
  conferenceRoomUrl_extra: 'If you use other service (such as Telegram or WhatsApp call), leave this space blank',
  appointment_v_conferenceRoomUrl: 'Enter the link to access the video call',
  appointment_v_conferenceRoomUrl_valid: 'Please, enter a valid link',
  conferenceRoomUrl_placeholder: 'Link to access the video call',
  location: 'Location',
  appointment_v_location: 'Select the appointment location',
  location_empty_pre: 'Add your locations on General Settings.',
  goToGeneralSettings: 'Go to General Settings',
  date: 'Date',
  date_existing: 'You have other appointment for this date',
  date_existing_other: 'You have other appointments for this date',
  appointment_v_date: 'Select the date',
  incomplete: 'Incomplete',
  minute: 'minute',
  minute_other: 'minutes',
  startTime: 'Start',
  endTime: 'End',
  appointment_customForm: 'Choose a survey',
  appointment_no_customForm: "Don't send a survey",
  sendAppointmentInstantMailBoolean: 'Send email?',
  chose_option: 'Select an option',
  appointment_instat_mail_details: 'We will send an email to the patient with the schedule appointment details',
  appointment_conflict: 'We remind you that you have one appointment that makes a conflict with the selected date and time.',
  // Appointments
  appointment_in_progress: 'Appointment in progress',
  appointment_in_progress_other: 'Appointments in progress',
  appointment_scheduled: 'Scheduled appointment',
  appointment_scheduled_other: 'Scheduled appointments',
  appointment_absence: 'Appointment absence',
  appointment_absence_other: 'Appointments absence',
  appointment_finished: 'Finished appointment',
  appointment_finished_other: 'Finished appointments',
  php_full: 'All the appointments for this service has been already scheduled.',
  php_usedAppointments: 'performed appointment',
  php_usedAppointments_other: 'performed appointments',
  hadPHP: "Doesn't have a service plan...",
  neverHadPHP: 'Never bought a service plan...',
  offerPHP: 'Offer your service plans!',
  offerPHP_2: 'Share your service plans!',
  price_includes: 'Price includes {{count}} appointment',
  price_includes_other: 'Price includes {{count}} appointments',
  // PopupScheduleAppointment
  schedule_appointment: 'Schedule appointment',
  schedule_new_appointment: 'Schedule new appointment',
  watch_video_tutorial: 'Watch tutorial',
  taken_appointments: 'All the appointments for this service has been already scheduled',
  schedule_appointment_with: 'You scheduled an appointment with',
  schedule_appointment_success: 'Scheduled appointment with success',
  done: 'Done!',
  error_google_calendar:
    'There was a problem scheduling this appointment on your Google Calendar. If this error persists, try to link your Google account again.',
  error_zoom: 'There was a problem creating the Zoom video call. If this error persists, try to link your Zoom account again.',
  error_php: 'All the available appointments for this service has been already scheduled. Reload the page and try again.',
  // PopupNewMealPlan
  mealPlan_for: 'Meal plan for',
  mealPlan_success: 'Meal plan succesfully created',
  mealPlan_add: 'Add meal plan',
  mealPlan_create_new: 'Create new meal plan',
  create: 'Create',
  select_patient: 'Select a patient',
  mealPlan_v_patient: 'Please, select the patient',
  // PopupNewRecipe
  recipe_add: 'Add recipe',
  recipe_add_new: 'Add new recipe',
  recipe_create_new: 'Create new recipe',
  recipe_create: 'Create recipe',
  cancel: 'Cancel',
  recipe_foodName: 'Recipe',
  recipe_v_foodName: 'Please, enter the recipe name',
  tags: 'Tags',
  recipe_v_tags: 'Please, select at least one tag',
  portions: 'Servings',
  recipe_v_portions: 'Please, enter the number of servings for the recipe',
  people: 'person',
  preparationTime: 'Preparation time',
  recipe_v_preparationTime: 'Please, enter the preparation time for the recipe',
  minutes: 'minutes',
  cookingTime: 'Cooking time',
  recipe_v_cookingTime: 'Please, enter the cooking time for the recipe',
  difficulty: 'Difficulty',
  recipe_v_difficulty: 'Select the preparation difficulty for the recipe',
  difficulty_easy: 'Easy',
  difficulty_medium: 'Medium',
  difficulty_hard: 'Hard',
  publicRecipe: 'Share recipe',
  recipe_share_true: 'This recipe will be shared among other Almendra users upon its completion.',
  recipe_share_false: "This recipe won't be shared among other Almendra users upon its completion.",
  public: 'Public',
  private: 'Private',
  // PopupCreatedFood
  createdFood_edit_success: 'Food successfully edited',
  createdFood_edit_error: 'It seems that the food could not be edited. Please, try again.',
  createdFood_add_success: 'Food successfully created',
  createdFood_add_error: 'It seems that the food could not be created. Please, try again.',
  createdFood_weights_limit: 'You have reached the maximum number of custom measurements',
  createdFood_v_weight_name: 'Please, enter the name of the custom measurement',
  createdFood_v_weight_name_duplicated: 'You already have entered a custom measurement with this name.',
  createdFood_manual_add: 'Create food manually',
  createdFood_add: 'Add food',
  createdFood_add_new: 'Add new food',
  createdFood_edit: 'Edit food',
  createdFood_create_new: 'Create new food',
  save_changes: 'Save changes',
  name: 'Name',
  createdFood_v_foodName: 'Please, enter the name of the food',
  category: 'Category',
  category_other: 'Categories',
  createdFood_v_category: 'Please, select the category',
  createdFood_nutrients: 'Nutritional details (optional)',
  createdFood_nutrients_label: 'Nurtitional details per: ',
  gram: 'gram',
  gram_other: 'grams',
  ounce: 'ounce',
  ounce_other: 'ounces',
  add_other_nutrient: 'Add another nutrient',
  createdFood_measurements: 'Custom measurements (optional)',
  quantity: 'Quantity',
  createdFood_v_measurement_quantity: 'Please, enter the quantity of this custom measurement',
  measurement: 'Measurement',
  createdFood_extra_measurement_desc: 'Remind to just include here the name of the unit and not the quantity.',
  tbsp: 'tablespoon',
  add_new_customMeasurement: 'Add new custom measurement',
  createdFood_v_measurement_gram: 'Please, enter the weight in grams',
  // FoodProperties
  alcohol_g: 'Alcohol (g)',
  ash_g: 'Ash (g)',
  caffeine_mg: 'Caffeine (mg)',
  calcium_mg: 'Calcium (mg)',
  carbs_g: 'Carbohydrates (g)',
  carbs_avl_g: 'Available carbohydrates (g)',
  alpha_carotene_ug: 'Alpha carotene (ug)',
  beta_carotene_mcg: 'Beta carotene (mcg)',
  cholesterol_mg: 'Cholesterol (mg)',
  choline_mg: 'Choline (mg)',
  copper_mg: 'Copper (mg)',
  beta_cryptoxanthin_mcg: 'Beta cryptoxanthin (mcg)',
  calories_kcal: 'Calories (kcal)',
  calories_kJ: 'Calories (kJ)',
  monounsaturated_fat_g: 'Monounsaturated fat (g)',
  polyunsaturated_fat_g: 'Polyunsaturated fat (g)',
  saturated_fat_g: 'Saturated fat (g)',
  trans_fat_g: 'Trans fat (g)',
  fiber_g: 'Fiber (g)',
  fluoride_mcg: 'Flouride (mcg)',
  vitaminB9_ug: 'Vitamin B9 (ug)',
  fructose_g: 'Fructose (g)',
  galactose_g: 'Falactose (g)',
  glucose_g: 'Glucose (g)',
  iron_mg: 'Iron (mg)',
  lactose_g: 'Lactose (g)',
  lutein_zeaxanthin_mcg: 'Lutein Zeaxanthin (mcg)',
  lycopene_mcg: 'Lycopene (mcg)',
  magnesium_mg: 'Magnesium (mg)',
  maltose_g: 'Maltose (g)',
  manganese_mg: 'Manganese (mg)',
  niacin_mg: 'Niacin (mg)',
  vitaminB5_mg: 'Vitamin B5 (mg)',
  phosphorus_mg: 'Phosphorus (mg)',
  potassium_mg: 'Potassium (mg)',
  protein_g: 'Protein (g)',
  retinol_mcg: 'Retinol (mcg)',
  riboflavin_mg: 'Riboflavin (mg)',
  selenium_mcg: 'Selenium (mcg)',
  sodium_mg: 'Sodium (mg)',
  starch_g: 'Starch (g)',
  sucrose_g: 'Sucrose (g)',
  sugar_g: 'Sugar (g)',
  theobromine_mg: 'Theobromine (mg)',
  thiamin_mg: 'Thiamin (mg)',
  fat_g: 'Fat (g)',
  vitaminA_mcg: 'Vitamin A (mcg)',
  vitaminB12_ug: 'Vitamin B12 (ug)',
  vitaminB6_mg: 'Vitamin B6 (mcg)',
  vitaminC_mg: 'Vitamin C (mg)',
  vitaminD_mcg: 'Vitamin D (mcg)',
  vitaminD2_mcg: 'Vitamin D2 (mcg)',
  vitaminD3_mcg: 'Vitamin D3 (mcg)',
  vitaminE_mg: 'Vitamin E (mg)',
  water_g: 'Water (g)',
  zinc_mg: 'Zinc (mg)',
  iodine_mcg: 'Iodine (mcg)',
  nonreducing_sugar_g: 'Non reducing sugar (g)',
  reducing_sugar_g: 'Reducing sugar (g)',
  aspartic_acid_g: 'Aspartic acid (g)',
  threonine_g: 'Threonine (g)',
  serine_g: 'Serine (g)',
  glutamic_acid_g: 'Glutamic acid (g)',
  proline_g: 'Proline (g)',
  glycine_g: 'Glycine (g)',
  alanine_g: 'Alanine (g)',
  cysteine_g: 'Cysteine (g)',
  valine_g: 'Valine (g)',
  methionine_g: 'Methionine (g)',
  isoleucine_g: 'Isoleucine (g)',
  leucine_g: 'Leucine (g)',
  tyrosine_g: 'Tyrosine (g)',
  phenylalanine_g: 'Phenylalanine (g)',
  histidine_g: 'Histidine (g)',
  lysine_g: 'Lysine (g)',
  arginine_g: 'Arginine (g)',
  tryptophan_g: 'Tryptophan (g)',
  glycemic_index: 'Glycemic index',
  glycemic_load: 'Glycemic load',
  iron_non_heme_g: 'Iron non heme (g)',
  // PatientsMain
  top_revenue_empty: "You don't have finished appointments",
  add_pending_patient_error: "The patient couldn't be added to your patient history",
  add_pending_patient_error_accept: 'There was an error while accepting the request of the patient',
  add_pending_patient_error_reject: 'There was an error while rejecting the request of the patient',
  add_pending_patient_error_description:
    'Please, reload the page and try again. If the error persists, please get in touch with us through the chat.',
  add_pending_patient_success_accept: "You've accepted the patient's request. The patient was added to your patient history",
  add_pending_patient_success_reject: 'The patient was removed from the pending patients list',
  pending_patients_empty: "You don't have pending requests",
  patients_per_php: 'Patients per service offered',
  pending_patients_to_accept: 'Pending patients request',
  top_revenue_patients: 'Best patients of the month',
  // PatientsReportsMain
  patients_reports: 'Patients Reports',
  patients_reports_description: 'See different reports of your patients',
  // ActivePatientsReport
  active_patients_report_title: 'Patients per activation',
  view_all_periods: 'View all periods',
  view_current_period: 'View current period',
  active_patients_signup_date: 'Patient register date',
  active_patients_tigger_date: 'Trigger date',
  active_patients_period: 'Activation period',
  active_patients_period_start: 'Start date of the period',
  active_patients_report_file_title: 'Active patients report to {{-date, MM/DD/YYYY}}',
  active_patients_data: 'Data',
  // NotificationsBadge
  notifications_linked_account_error: 'There was an error with your <1>{{accountName}}</1> linked account.',
  notifications_linked_account_error_action: 'Please, link it again to use this service.',
  // InactiveAccountModal
  inactive_account_title: 'Hello {{name}}!',
  inactive_account_description: 'There has been no activity on your Almendra account for a long time',
  inactive_account_description_2: 'We have scheduled the deletion of your account for <1>{{-date, LL}}</1>.',
  inactive_account_description_3:
    'Generally, Almendra accounts that are inactive for more than 90 days are deleted in order to maintain security, limit data retention, and protect your privacy. It is considered that there is inactivity when the user does not log into their account.',
  inactive_account_description_4:
    'In case you want to keep your account, click on "Keep my account active" button. Otherwise, it would be enough for you to close this page, we will proceed to delete your account on the aforementioned date.',
  keep_active_account: 'Keep my account active',
  keep_active_account_success: 'Done! Your account has just been reactivated',
  // IdleTimer
  inactivity_in_account: 'There was a period of inactivity in your account',
  please_reload: 'Please, reload the page to continue.',
  reload: 'Reload',
  // AppointmentPDF
  appointment_report: 'Appointment report',
  no_measure: 'No measure',
  age: 'Age',
  year: 'year',
  year_other: 'years',
  not_specified: 'Not specified',
  general_objective: 'General goal',
  specific_objective: 'Specific goal',
  reach: 'Reach',
  before_date: 'before',
  upcoming_appointment: 'Upcoming appointment',
  measurement_history: 'Measurements History',
  bmi: 'Body Mass Index (BMI)',
  under_weight: 'Underweight',
  normal_weight: 'Normal Weight',
  over_weight: 'Overweight',
  pre_obesity: 'Pre-obesidad',
  obesity_class_1: 'Obesity Class 1',
  obesity_class_2: 'Obesity Class 2',
  extreme_obesity_class_3: 'Extreme Obesity Class 3',
  appointment_notes: 'Appointment notes',
  // MeasurementsWithUnitsWithTypeWithStateName
  weight: 'Weight',
  height: 'Height',
  seatedHeight: 'Sitting height',
  armSpan: 'Arm span',
  femurBiepicondylarDiameter: 'Biepicondylar femur width',
  humerusBiepicondylarDiameter: 'Biepicondylar humerus width',
  wristBistanoidDiameter: 'Wrist width',
  elbowDiameter: 'Elbow width',
  ankleDiameter: 'Ankle width',
  biacromialDiameter: 'Biacromial diameter',
  bicrestalDiameter: 'Bicrestal diameter',
  transverseThoraxDiameter: 'Transverse thorax width',
  anteroPosteriorThoraxDiameter: 'Anteroposterior thorax diameter',
  headGirth: 'Head circumference',
  shoulderGirth: 'Shoulder circumference',
  abdominalGirth: 'Abdominal circumference',
  hipGirth: 'Hip circumference',
  waistGirth: 'Wait circumference',
  minimumWaistGirth: 'Minimum waist circumference',
  maximumWaistGirth: 'Maximum waist circumference',
  wristGirth: 'Wirst circumference',
  thighGirth: 'Thigh circumference',
  forearmGirth: 'Forearm circumference',
  armGirth: 'Arm circumference',
  armFlexedGirth: 'Flexed arm circumference',
  armRelaxedGirth: 'Relaxed arm circumference',
  neckGirth: 'Neck circumference',
  ankleGirth: 'Ankle circumference',
  calfGirth: 'Calf circumference',
  midThighGirth: 'Mid thigh circumference',
  chestGirth: 'Chest circumference',
  abdominalSkinFold: 'Abdominal skinfold',
  midaxillarySkinFold: 'Midaxillary skinfold',
  bicepsSkinFold: 'Biceps skinfold',
  medialCalfSkinFold: 'Medial calf skinfold',
  iliacCrestSkinFold: 'Iliac crest skinfold',
  chestSkinFold: 'Chest skinfold',
  subscapularSkinFold: 'Subscapular skinfold',
  supraspinaleSkinFold: 'Supraspinal skinfold',
  tricepsSkinFold: 'Triceps skinfold',
  frontThighSkinFold: 'Front thigh skinfold',
  abdominalSubcutaneousSkinFold: 'Abdominal subcutaneous skinfold',
  ALT_TGP: 'ALT/TGP',
  AST_TGO: 'AST/TGO',
  albumin: 'Albumin',
  amylase: 'Amylase',
  apolipoproteinA: 'Apolipoprotein A',
  apolipoproteinB: 'Apolipoprotein B',
  basophils: 'Basophils',
  totalBilirubin: 'Total bilirubin',
  directBilirubin: 'Direct bilirubin',
  calcium: 'Calcium',
  chlorides: 'Chlorides',
  copper: 'Copper',
  HDLCholesterol: 'HDL Cholesterol',
  LDLCholesterol: 'LDL Cholesterol',
  totalCholesterol: 'Total Cholesterol',
  creatinine: 'Creatinine',
  eosinophils: 'Eosinophils',
  erythrocytes: 'Erythrocytes',
  ferritin: 'Ferritin',
  heartRate: 'Heart rate',
  phosphorus: 'Phosphorus',
  gammaGT: 'Gamma GT',
  glucagon: 'Glucagon',
  fastingBloodSugar: 'Fasting blood sugar',
  afterEatingBloodSugar: 'After eating blood sugar',
  redBloodCells: 'Red blood cells',
  hematocrit: 'Hematocrit',
  hemoglobin: 'Hemoglobin',
  a1cHemoglobin: 'A1c Hemoglobin',
  iron: 'Iron',
  parathyroidHormone_PTH: 'Parathyroid hormone (PTH)',
  igE: 'IgE',
  igG: 'IgG',
  igM: 'IgM',
  leukocytes: 'Leukocytes',
  lymphocytes: 'Lymphocytes',
  magnesium: 'Magnesium',
  monocytes: 'Monocytes',
  nH3: 'NH3',
  neutrophils: 'Neutrophils',
  platelets: 'Platelets',
  potassium: 'Potassium',
  prealbumin: 'Prealbumin',
  diastolicBloodPressure: 'Diastolic blood pressure',
  systolicBloodPressure: 'Systolic blood pressure',
  prolactin: 'Prolactin',
  reactiveProteinC: 'Reactive protein C',
  proteinsInUrine: 'Proteins in urine',
  totalProteins: 'Total proteins',
  sodium: 'Sodium',
  thyrotropinTSH: 'Thyrotropin (TSH)',
  thyroxine: 'Thyroxine (T4)',
  transferrin: 'Transferrin',
  triglycerides: 'Triglycerides',
  triiodothyronine_T3: 'Triiodothyronine (T3)',
  urea: 'Urea',
  vitaminA: 'Vitamin A',
  vitaminB12: 'Vitamin B12',
  vitaminB6: 'Vitamin B6',
  vitaminD: 'Vitamin D',
  vitaminE: 'Vitamin E',
  zinc: 'Zinc',
  arterialPh: 'Arterial pH',
  ascorbicAcid: 'Ascorbic acid (Vitamin C)',
  folicAcid: 'Folic acid',
  uricAcid: 'Uric acid',
  metabolicAge: 'Metabolic age',
  extracellularFluid: 'Extracelular fluid',
  intracelullarFluid: 'Intracelular fluid',
  totalBodyWater: 'Total body water',
  bodyMassIndex: 'Body Mass Index',
  visceralFatIndex: 'Visceral fat index',
  waistHeightRatio: 'Waist-height ratio',
  waistHipRatio: 'Waist-hip ratio',
  bodyDensity: 'Body density',
  visceralFat: 'Visceral fat',
  fatMass: 'Fat mass',
  muscleMass: 'Muscle mass',
  residualMass: 'Residual mass',
  fatFreeMass: 'Fat free mass',
  boneMass: 'Bone mass',
  leanBodyMass: 'Lean body mass',
  visceralFatPercentage: 'Visceral fat percentage',
  bodyFatPercentage: 'Body fat percentage',
  residualMassPercentage: 'Residual mass percentage',
  fatFreeMassPercentage: 'Fat free mass percentage',
  leanBodyMassPercentage: 'Lean body mass percentage',
  bodyWaterPercentage: 'Body water percentage',
  muscleMassPercentage: 'Muscle mass percentage',
  boneMassPercentage: 'Bone mass percentage',
  idealBodyWeight: 'Ideal body weight',
  adjustedBodyWeight: 'Adjusted body weight',
  acromionRadial: 'Acromion-Radial',
  radialeStylon: 'Radiale stylon',
  wristToMiddleFinger: 'Wrist to middle finger',
  iliospinaleHeight: 'Iliospinale height',
  trochanterionHeight: 'Trochanterion height',
  trochanterionTibialeLaterale: 'Trochanterion tibiale laterale',
  tibialeHeight: 'Tibiale height',
  tibialeMedialetoMalleolar: 'Tibial tubercle-medial distance (TMD)',
  footLength: 'Foot length',
  // ObjectivesSummary
  objective: 'Goal',
  objective_patient_undefined:
    "{{name}} doesn't have a defined goal. Complete all the specific goal section on the patient's profile to see the analysis.",
  value: 'Value',
  initial: 'Initial',
  appointment: 'Appointment',
  appointment_other: 'Appointments',
  current: 'Current',
  target: 'Target',
  cant_calculate_bmi: "Can't calculate the patient's BMI",
  bmi_short: 'Body mass index',
  objectives_need_two_measurements: 'At least two measurements since the initial date of the goal are required to see the progress',
  objective_increased: '{{patient}} increased {{difference}} {{unit}}',
  objective_decreased: '{{patient}} reduced {{difference}} {{unit}}',
  objective_maintain: '{{patient}} maintained {{measurement}}',
  since_last_appointment: 'since last appoointment',
  // Error500
  error_bug_title: 'It seems there is an issue',
  error_bug_description: "We've just notified our development team about this issue in order to solve it as soon as possible",
  error_bug_cta: 'Go to Home',
  // SessionTimer
  session_expired: 'Your session has expired',
  session_expired_des: 'For security, we make the sessions expire every month. To continue, enter your password.',
  // ExpertAuthForm
  forgot_password: 'Forgot password?',
  great: 'Great!',
  validating_info: 'Validating info...',
  log_in: 'Log in',
  enter_your_password: 'Enter your password',
  password: 'Password',
  email_v_valid: 'Please, enter a valid email',
  email_2: 'Email',
  hello: 'Hello',
  welcome_to_software: 'Welcome to Almendra!',
  enter_my_account: 'Go to my account',
  want_login_mobile: 'Do you want to continue with this mobile device?',
  mobile_compatibility_disclaimer: 'To assure you have a great experience on Almendra, log in with a computer or a tablet.',
  account_verified: 'Your account has been verified successfuly',
  improve_your_experience: 'Improve your experience',
  oops: 'Oops!',
  expired_link: 'Link is invalid or has expired',
  blocked_ip: "We're sorry, you cannot log in.",
  // PasswordUpdate
  reset_password: 'Reset password',
  enter_new_password: 'Enter your new password',
  new_password: 'New password',
  confirm_password: 'Confirm password',
  reset_password_error: 'There was an error while saving your new password.',
  reset_password_success: 'Password successfully changed.',
  password_validator_lowercase: 'At least one lowercase',
  password_validator_uppercase: 'At least one uppercase',
  password_validator_length: 'At least 8 characters',
  password_validator_number: 'At least one number',
  password_validator_match: 'Confirm password matches',
  // FeaturesPoll
  please_choose_an_option: 'Please, choose an option',
  features_thanks: 'Thanks for participating on the poll!',
  choose_new_features: 'Choose our new features!',
  featuresPoll_title: 'What would you like to have on Almendra?',
  featuresPoll_description: 'Choose the new features on Almendra. Your feedback is important to us!',
  featuresPoll_pick_others: 'Please, explain us what feature would you like to have on Almendra and why? Provide us details as much as you can.',
  featuresPoll_pick_option: 'Why would you like this features? Provide us details as much as you can.',
  send: 'Send',
  featuresPoll_vote_submitted: 'Great! Your vote has been submitted!',
  featuresPoll_submit_description:
    "If your chosen option is not ranked as favorite, don't worry! We will eventually take it into account on the next release. Please contact us if you have any doubt, comment or suggestion.",
  featuresPoll_votes: 'Percentage of votes',
  // FeedbackPopup
  feedbackPopup_title: 'Rate your experience on Almendra',
  feedbackPopup_suggestion: 'Do you have any comment or suggestion?',
  feedbackPopup_suggestion_placeholder: 'Enter your comment or suggestion and help us improve',
  feedbackPopup_submitted_title: 'We appreciate your help! Thank you!',
  feedbackPopup_submitted_description: 'We will take into account your comments or suggestions',
  feedbackPopup_validator: 'Please, rate us or enter a comment/suggestion',
  successfully_sent: 'Successfully sent!',
  // FinancialsExpenses
  workTools: 'Work tools', // cambiar sven
  others: 'Others', // cambiar sven
  expenses_empty: "You haven't entered any expenses",
  expenses_for_current_period: 'Expenses for current period',
  // FinancialsIncome
  income_empty:
    "You haven't entered any revenue. The transactions will appear here as soon as you finish an appointment or register an extra revenue.",
  income_edit_disclaimer: 'Only extra revenue can be editted, not revenue generated by appointments',
  income_for_current_period: 'Revenue for current period',
  // NewTable
  amount: 'Amount',
  extra_income: 'Extra revenue',
  edit_recurrent_expense_question: 'Do you want to edit only this expense or all this recurrent expenses',
  going_to_edit_recurrent_expense: 'You are about to edit a recurrent expense',
  recurrent_expense: 'Recurrent expense',
  showColumns: 'Show columns',
  filters_all: 'All',
  filters: 'Filters',
  reset: 'Reset',
  viewColumns: 'View Columns',
  filterTable: 'Filter table',
  next: 'Next',
  previous: 'Previous',
  rowsPerPage: 'Rows per page',
  displayRows: 'of',
  order: 'Order',
  financials_empty_table: 'No {{name}} found for this period',
  transactions: 'transactions',
  expenses: 'expenses',
  income: 'revenue',
  // JoyrideTour
  joyride_back: 'Back',
  joyride_close: 'Close',
  joyride_last: 'Finish tour',
  joyride_next: 'Next',
  joyride_skip: 'Skip tour',
  // ToursHelp
  toursHelp_title: 'Tutorials',
  toursHelp_description: 'Discover how to use Almendra',
  view: 'View',
  tutorial_start_appointment: 'How to start an appointment',
  tutorial_schedule_appointment: 'How to schedule an appointment',
  tutorial_appointment_process: 'The process of the appointment',
  tutorial_create_meal_plan: 'How to create a meal plan',
  tutorial_flexible_meal_plan_1: 'How to create a flexible meal plan - Part 1',
  tutorial_flexible_meal_plan_2: 'How to create a flexible meal plan - Part 2',
  tutorial_flexible_meal_plan_3: 'How to create a flexible meal plan - Part 3',
  tutorial_strict_meal_plan_1: 'How to create a strict meal plan - Part 1',
  tutorial_strict_meal_plan_2: 'How to create a strict meal plan - Part 2',
  tutorial_strict_meal_plan_3: 'How to create a strict meal plan - Part 3',
  tutorial_meal_plan_from_other: 'How to create a meal plan from another one',
  tutorial_meal_plan_from_template: 'How to create a meal plan from a template',
  tutorial_create_recipe: 'How to create a recipe',
  tutorial_create_service: 'How to create a service',
  tutorial_setup_agenda: 'How to set up your agenda',
  tutorial_configure_account: 'How to configure your account',
  tutorial_online_booking: 'How to set up your Online Booking System',
  tutorial_financials: 'How to register revenues and expenses (financials)',
  // PopupIndicationsTemplate
  indicationsTemplate_v_title: 'Plese, enter a title for the indication template.',
  indicationsTemplate_v_empty: 'Indications must not be empty.',
  indicationTemplate_create_success: 'Indications template created successfully',
  indicationTemplate_edit_success: 'Indications template editted successfully',
  edit: 'Edit',
  indicationTemplate_create: 'Create indications template',
  indicationTemplate_edit_title: 'Edit indications template',
  indicationTemplate_create_title: 'Add new indications template',
  save_template: 'Save template',
  create_template: 'Create template',
  title: 'Title',
  indicationTemplate_title_placeholder: 'Ex: Template for diabetics',
  indications: 'Indications',
  indicationsTemplate_delete_indication: 'Do you want to delete this indication?',
  indicationsTempalte_add_indication: 'Add indication',
  // UseIndicationsTemplates
  templates: 'Templates',
  useIndicationTemplates_title: 'Insert an indication template to the meal plan',
  your_templates: 'Your indication templates',
  insert: 'Insert',
  system_templates: 'Default indication templates',
  // BrowserCompatibilityHoc
  unsupported_browser: "You are using a browser that we don't support.",
  unsupported_browser_version: "You are using an outdated browser version that we don't support: {{browserName}} ({{browserVersion}}).",
  unsupported_browser_details: 'Please, {{name}}migrate to another browser to improve your experience.',
  update_browser_version: 'update to the latest browser version or ',
  download_latest_browser_version: 'Click this link to learn how to download the latest version of your browser.',
  download_google_chrome: 'Click this link to download Google Chrome.',
  // OnBoardingGoToVideo
  go_to_video: 'Go to video',
  // OnBoardingInitialModal
  onboarding_b2c_content_0: '{{name}}, we still do not have meal plans for the public',
  onboarding_b2c_content_1:
    'Very soon we will have this functionality at a very accessible price and we will contact you with a <1>special discount</1>. But for now we are only software made for nutrition professionals.',
  onboarding_b2c_content_2:
    'If you think this is a mistake and if you are a nutrition professional, please contact us at <1>support@almendra.io</1> or write to us via chat.',
  demo_mealPlan: 'Meal plan (demo)',
  error_message: 'There was a problem... Try again in a few minutes.',
  onboarding_start: "Let's start!",
  save: 'Save',
  onboarding_lets_start: "Let's start your journey with Almendra!",
  onboarding_contact_prospect:
    "During the next 7 days a team member will guide you though this initial step. In no time you'll handle the software like the palm of your hand. You'll soon receive the first guidance email :)",
  onboarding_we_recommend: 'For now, we recommend:',
  onboarding_tour_mealPlan: 'Make a meal plan',
  onboarding_tour_appointment: 'Make an appointment',
  onboarding_tour_ai_assistant: 'Chat with my AI Assistant',
  onboarding_tour_skip: 'Skip for now',
  // OnBoardingMobileModal
  onboarding_width_compatible:
    "Improve your experience! Log in using a computer or a tablet. You won't be able to use all the features because of this device screen width. Don't worry, here you have some videos we made in order for you to understand better the software.",
  mealPlan: 'Meal plan',
  mealPlan_other: 'Meal plans',
  online_booking: 'Online booking',
  // MetsDropdownMenu
  calculate_pal: 'Calculate PAL',
  // PatientDisciplineCard
  patient_discipline_card_tooltip: 'This activity is not in the METs list',
  mets_activity: 'This activity has <strong>{{name}} METs</strong>.',
  mets_activity_on_patient: '{{patient}} will burn approximately <strong>{{calories}}</strong> calories per week.',
  minutes_per_session: 'Minutes per session',
  minutes_per_session_placeholder: 'Ex: 120',
  activity_frequence: 'Activity frequence',
  monday_short: 'Mon',
  tuesday_short: 'Tue',
  wednesday_short: 'Wed',
  thursday_short: 'Thu',
  friday_short: 'Fri',
  saturday_short: 'Sat',
  sunday_short: 'Sun',
  // PopupActivePatientInfo
  click_for_more_info: 'Click for more information',
  whats_an_active_patient: 'What is an active patient?',
  active_patient_explanation_1: 'A patient is active during this month if he had an appointment or meal plan.',
  for_example: 'For example',
  active_patient_explanation_2:
    'Suppose you joined Almendra on March 10th on a monthly plan. Your billing period will go from March 10th to April 10th.',
  active_patient_explanation_3:
    'You treated Sophie, Paul, and Albert between those two dates. Sophie went twice to your office, while Paul and Albert went just once.',
  active_patient_explanation_4:
    "Since you've had 3 different patients that went through an appointment, that means you have 3 active patients. If the same patient has more than 1 appointment during your billing period, it will count just as 1 active patient for that period.",
  active_patient_explanation_5:
    'The same example applies for the meal plans assigned to the patients. However, meal plans occur in a range of dates, not just in single day.',
  active_patient_explanation_6:
    'If you have a meal plan which has a start date and an end date that are inside two billing periods, the patient will be considered as active on both periods.',
  active_patient_explanation_7:
    "Imagine your billing period goes from March 10th to April 10th and your patient's meal plan goes from April 6th to April 13th. The patient will be counted as active on both billing periods (04/10-05/10 and 05/10-06/10).",
  active_patient_actual: 'So far this month, you have {{count}} active patient.',
  active_patient_actual_other: 'So far this month, you have {{count}} active patients.',
  have_doubts: 'Do you have any questions?',
  contact_us_mail_pre: 'Contact us at ',
  contact_us_mail_post: ', we will be always available to you.',
  // ScheduleMain
  dragAndSchedule_validator: 'Select a future date to schedule an appointment',
  drag_event_edit_validator: 'You are dragging an event to a past date',
  to_date: 'to',
  drag_event_edit_title: 'Do you want to change the scheduled date for this appointment?',
  before: 'Before',
  now: 'Now',
  edit_schedule_appointment_email_send: 'Send reschedule email?',
  edit_schedule_appointment_email_send_description:
    'In case you select "Yes", an email will be sent to the patient with the new date of the appointment',
  edit_scheduled_appointment_success: "You've just editted an scheduled appointment with {{name}}",
  yes_change: 'Yes, change',
  loading_info: 'Loading information...',
  empty_schedule_finished: "You don't have finished appointments for this week",
  schedule_finished: 'Finished',
  schedule_finished_appointments_week: 'You have {{count}} finished appointment for this week',
  schedule_finished_appointments_week_other: 'You have {{count}} finished appointments for this week',
  empty_schedule_scheduled: "You don't have scheduled appointments for this week",
  schedule_scheduled: 'Scheduled',
  schedule_scheduled_appointments_week: 'You have {{count}} scheduled appointment for this week',
  schedule_scheduled_appointments_week_other: 'You have {{count}} scheduled appointments for this week',
  empty_schedule_cancelled: "You don't have canceled appointments for this week",
  schedule_cancelled: 'Canceled',
  schedule_cancelled_appointments_week: 'You have {{count}} canceled appointment for this week',
  schedule_cancelled_appointments_week_other: 'You have {{count}} canceled appointments for this week',
  loading_appointments: 'Loading appointments...',
  weekly_list: 'Weekly list',
  monthly_list: 'Monthly list',
  // CopyToClipboard
  link_copied: 'Link copied',
  copy_this_link_description: 'Copy this link and paste it wherever you wish',
  copy_link: 'Copy link',
  // HoursAndMinutesInput
  mets_activity_minutes_validator: 'Enter the daily time spent for this activity',
  // NoMatchEquivalentsModal
  no_match_equivalents_title: "You haven't created equivalents for this food",
  no_match_equivalents_description: 'Remember you can create your own equivalents on',
  understood: 'Ok',
  feeding: 'Feeding',
  equivalents: 'Equivalents',
  // TagInput
  tag_input_validator: 'This option is already included',
  // SomatoChart
  somatograph: 'Somatograph',
  endomorphy: 'Endomorphy',
  ectomorphy: 'Ectomorphy',
  mesomorphy: 'Mesomorphy',
  // EmailVerificationRequiredAlert
  resend_verification_loading: 'Sending verification email...',
  resend_verification_email_success: 'Verification email sent!',
  resend_verification_email_error: 'Oops! It seems there was an error trying to resend the verification email. Try again in a few minutes.',
  verification_email_title: "You haven't verified your email with us",
  verification_email_description:
    'We need to verify your email <strong>{{email}}</strong> in order to continue. We remind you that the email may take up to 5 minutes to arrive or could be in your promotions/spam inbox. If you have problems logging in, send us an email to <strong>hola@almendra.io</strong> and we will help you.',
  // AlertPatientsLimit
  click_here_for_info: 'Click here for more information',
  active_patient: 'active patient',
  active_patient_other: 'active patients',
  patientsLimit_hit:
    'If you have <strong>{{count}} more active patient</strong> until <strong>{{- date, dddd MMMM/DD/YYYY}}</strong>, you will have to upgrade your plan.',
  patientsLimit_hit_other:
    'If you have <strong>{{count}} more active patients</strong> until <strong>{{- date, dddd MMMM/DD/YYYY}}</strong>, you will have to upgrade your plan.',
  patientsLimit_gracePeriod:
    'We give you <strong>{{count}} more day, until {{- date, MM/DD/YYYY}}</strong>, so you can subscribe to a plan that can allow at least {{patientsLimit}} monthly active patients in order to keep using the software. Otherwise, your account will be disabled until you upgrade your plan.',
  patientsLimit_gracePeriod_other:
    'We give you <strong>{{count}} more days, until {{- date, MM/DD/YYYY}}</strong>, so you can subscribe to a plan that can allow at least {{patientsLimit}} monthly active patients in order to keep using the software. Otherwise, your account will be disabled until you upgrade your plan.',
  patientsLimit_gracePeriod_onDemand:
    'We give you <strong>{{count}} more day, until {{- date, MM/DD/YYYY}}</strong>, so you can subscribe to the Pro Plan that can allow at least {{patientsLimit}} monthly active patients in order to keep using the software. Otherwise, your account will be disabled until your next billing reset date.',
  patientsLimit_gracePeriod_onDemand_other:
    'We give you <strong>{{count}} more days, until {{- date, MM/DD/YYYY}}</strong>, so you can subscribe to the Pro Plan that can allow at least {{patientsLimit}} monthly active patients in order to keep using the software. Otherwise, your account will be disabled until your next billing reset date.',
  trialEnding_alert: "<0 /> {{count}} day left until your trial period ends. Don't wait until the last day!",
  trialEnding_alert_other: "<0 /> {{count}} days left until your trial period ends. Don't wait until the last day!",
  transactionFailed_alert:
    'There was an error on your last subscription charge of <strong>{{planName}}</strong> for the period from <strong>{{- period_start, MM/DD/YYYY}}</strong> to <strong>{{- period_end, MM/DD/YYYY}}</strong>. <linkTag>Click here</linkTag> to regularize your subscription.',
  patientsLimit_reached: 'you reached the maximum allowed number of active patients of your plan and we want you to help you keep growing. ',
  patientsLimit_reached_date: 'On {{- date, MMMM/DD/YYYY}}',
  upgrade_plan: 'Upgrade plan',
  subscribe: 'Subscribe',
  apply_offer: 'Apply offer',
  // NewPatientForm
  newPatient_firstName_v: 'Enter your first name',
  newPatient_lastName_v: 'Enter your last name',
  newPatient_gender_v: 'Select your sex',
  newPatient_phone_v_required: 'Enter your phone number',
  newPatient_phone_v_valid: 'Enter a valid phone number',
  newPatient_email_extra:
    'Your email will be used to log into your account on Almendra, where you will see your meal plans, progress and schedule appointments.',
  newPatient_email_v_valid: 'Enter a valid email',
  newPatient_email_v_required: 'Enter your email',
  // ReviewAndCofirm
  notes: 'Notes',
  additional_notes: 'Extra comments',
  notes_v_max: 'Max 120 characters',
  notes_placeholder: '(optional) Add a note',
  // PopupDeleteExpertConfirmation
  you_have_an_active_subscription: 'You have an active subscription',
  cancel_before_delete_account: 'In order to delete your account, cancel your current subscription first.',
  delete_my_account: 'Delete my account',
  delete_account_title_step_0: 'Are you thinking to leave us?',
  delete_account_title_step_1: 'Are you really gonna leave us?',
  delete_account_title_step_2: 'Last chance :(',
  continue: 'Next',
  delete_account_close: 'Close, I want to stay',
  delete_account_description_step_0: 'Remember that Almendra is the best software for nutrition profesionals on the market. Here are some reasons:',
  why_almendra_0: 'Official food databases of the country you reside.',
  why_almendra_1: "Easy follow-up and management of your patients' meal plans",
  why_almendra_2: 'Profound data analytics of all the aspects of your business.',
  why_almendra_3: 'Precise information of all your patients that will never get lost or stolen.',
  contact_us_delete_account: 'Contact us at <1>soporte@almendra.io</1> if you want to talk with us! We will be always available for you.',
  delete_account_description_step_1:
    'We would like to know on what we have failed. Please, tell us the reason(s) of why you are leaving us (you may select more than one checkbox):',
  delete_account_option_hardToUse: 'Difficult to use',
  delete_account_option_highPrice: 'High price',
  delete_account_option_lackOfNutritionInfo: 'Lack of nutrition information',
  delete_account_option_badCustomerSupport: 'Bad customer support (delayed replies, mistreatment, others)',
  delete_account_option_badUI: 'Bad user interface',
  delete_account_option_notAttendingPatients: "I'm not attending patients at the moment",
  enter_here: 'Enter here',
  pre_delete_account:
    'If you click this button, your account with the email <1>{{email}}</1> will be deleted permanently and you will be redirected to our homepage.',
  apologize: "We apologize for not meeting your needs.<1 />Don't worry, we will try our best and get better. Hope to meet again in the future! ",
  delete_account_reason_min_length: 'Please explain with at least 10 characters how can we improve',
  delete_account_checkbox_required: 'Please select at least one reason',
  // ExpertSettings
  profile: 'Profile',
  services: 'Health Services',
  web_tools: 'Web Tools',
  general_settings: 'General Settings',
  mailing: 'Mailing',
  visual: 'Visual',
  security: 'Security',
  subscription: 'Subscription',
  almendraAICredits_success_title: 'Purchase made with success',
  almendraAICredits_success_description: '{{quantity}} AlmendraAI credits added to your account',
  googleAccountLinked_success: 'Linking to Google made with success',
  googleAccountLinked_error: 'There was an error trying to link your account with Google',
  zoomAccountLinked_success: 'Linking to Zoom made with success',
  zoomAccountLinked_error: 'There was an error trying to link your account with Zoom',
  mercadoPagoAccountLinked_success: 'Linking to MercadoPago made with success',
  mercadoPagoAccountLinked_error: 'There was an error trying to link your account with MercadoPago',
  mercadoPagoAccountLinked_description: 'Learn how to use this integration in the next article: <1>Almendra and MercadoPago</1>',
  payPalAccountLinked_success: 'Linking to PayPal made with success',
  payPalAccountLinked_error: 'There was an error trying to link your account with PayPal',
  // EquivalentsMain
  equivalence_default_edit_content:
    'Upon confirming, the equivalents group will not be from the default source anymore. We remind you this action cannot de undone.',
  equivalence_default_edit_error: 'There was an error while you editted the equivalence group. Please, reload the page and try again.',
  equivalence_default_edit_title: 'You are editting an equivalence group from {{name}}, do you want to continue?',
  yes_edit: 'Yes, edit',
  equivalence_edit_success: 'Equivalence editted successfully',
  equivalence_create_success: 'Equivalence created successfully',
  equivalence_group: 'Equivalence group',
  equivalence_groupName_v: 'The name of the equivalence group must not be empty',
  update_success: 'Updated successfully',
  equivalence_delete_title: 'Do you want to delete this equivalence group?',
  equivalence_delete_content_0:
    'In case you have used this group in some Exchange Meal Plan, it will be deleted from all meal plans and meal plan templates.',
  equivalence_delete_content_1: 'Doing this is not recommended and may cause some distortion on the meal plans. This action cannot be undone.',
  yes_delete: 'Yes, delete',
  equivalence_delete_success: 'Equivalence group deleted successfully',
  food_add_success: 'Food added successfully',
  food_edit_success: 'Food editted successfully',
  food_delete_success: 'Food deleted successfully',
  search_by_group: 'Search by group',
  loading_equivalents_groups: 'Loading equivalence groups...',
  groupName: 'Group',
  equivalence_groupName_placeholder: 'Equivalence group name',
  equivalence_groupName_details: 'My equivalence group',
  equivalence_default_groupName_details: 'Equivalence group from {{name}}',
  equivalence_stats_average: 'Average calories and macros',
  fat_short: 'FAT',
  fat_one_letter: 'F',
  carbs_short: 'CHO',
  carbs_one_letter: 'C',
  protein_short: 'PRO',
  protein_one_letter: 'P',
  delete_group: 'Delete group',
  create_equivalence: 'Create equivalence',
  equivalence_empty: "You haven't created any equivalences group yet.",
  equivalence_empty_search: 'There are no results matching "<1>{{name}}</1>".',
  equivalence_groupDescription_placeholder: 'Enter a description for this equivalence group',
  equivalence_foods: 'Equivalent foods',
  equivalence_group_empty: 'Add a food to this equivalence group',
  // FavoriteFoodsTable
  favoriteFoods_edit_error: 'There was an error while editting your favorite food. Please, reload the page and try again.',
  favoriteFoods_delete_title: 'You do want to delete this food from your favorites list?',
  irreversible_action: 'Remember this action cannot be undone',
  search_food: 'Search food',
  unit: 'Unit',
  food: 'Food',
  food_other: 'Foods',
  calories: 'Calories',
  no_value: 'No value',
  fat: 'Fat',
  carbs: 'Carbs',
  prot: 'Protein',
  favoriteFoods: 'Favorite foods',
  favoriteFoods_empty_search: 'There were no matches for your search "<1>{{name}}</1>".',
  favoriteFoods_empty: "You haven't added your favorite foods yet",
  favoriteFoods_empty_description:
    'Add the foods you always use on meal plans to your favorites list to facilitate the work on your nexts meal plans.',
  // PatientPhysicalActivity
  very_sedentary: 'Very Sedentary',
  sedentary: 'Sedentary',
  sedentary_desc: '(little or no exercise, desk job)',
  lightly_active: 'Lightly Active',
  lightly_active_desc: '(light exercise/sports 1-3 days per week)',
  moderately_active: 'Moderately Active',
  moderately_active_desc: '(moderate exercise/sports 6-7 days per week)',
  very_active: 'Very Active',
  very_active_desc: '(hard exercise every day, or exercising 2 xs/day)',
  extra_active: 'Extra Active',
  extra_active_desc: '(hard exercise 2 or more times per day, or training for marathon, or triathlon, etc.)',
  manually_calculated_pal: '(PAL manually calculated)',
  pal: 'PAL',
  // StatsCard
  since_you_joined: 'since you joined',
  // PatientsBySexBarGraph
  men: 'Men',
  women: 'Women',
  // PatientsGraph
  total: 'Total',
  new: 'New',
  new_other: 'New',
  // PlanUsageGraph
  accumulated_active_patients: 'Accumulated active patients',
  active_patients_plan_empty: "Here you'll be able to visualize the activity of a period when you subscribe to a plan",
  range_from: 'from',
  range_to: 'to',
  // NewBillingMain
  plans_fetch_error_title: "It was not possible to load Almendra's plans",
  plans_fetch_error_content: 'Please refresh the page and try again',
  verifying_subscription: 'Verifying subscription',
  regularize_subscription_title: 'Regularize your subscription',
  regularize_subscription_content_0: 'We encountered an issue with your payment method and your plan could not be renewed.',
  regularize_subscription_content_1:
    'We encountered an issue with your payment method and your plan could not be renewed. There is an outstanding balance of <strong>{{outstandingAmount}}</strong> for the period from {{- dateFrom, DD/MM/YYYY}} to {{- dateTo, DD/MM/YYYY}}.',
  regularize_subscription_content_2: 'To continue enjoying the benefits of the {{planName}} Plan, this balance needs to be settled.',
  regularize_subscription_content_2_free: 'In order to downgrade to the {{planName}} Plan, this balance needs to be settled.',
  regularize_subscription_content_3: 'Would you like to settle your subscription now?',
  yes_regularize: 'Yes, regularize',
  pass_to_freemium_on_plan_title: 'You are currently in the {{planName}} Plan',
  pass_to_freemium_on_plan_content:
    'To go to the Free Plan, cancel your current subscription. Once your plan expires on {{- date, MM/DD/YYYY}}, you will automatically switch to the Free Plan.',
  // PaymentMethod
  ok: 'Ok',
  redirecting: 'Redirecting',
  action_waiting_seconds: 'This action may take a few seconds.',
  action_status_error: 'The operation could not be performed successfully',
  on_demand_delete_method_title: 'The cancellation of your subscription is scheduled for  {{-date, MM/DD/YYYY}}',
  on_demand_delete_method_content: 'You will be able to delete your payment method from that day on.',
  cancel_plan_before_method_delete: 'To remove the payment method, cancel any active plans or future subscriptions you have.',
  deleting_payment_method: 'Deleting payment method',
  payment_method_delete_success: 'Payment method successfully removed',
  you_dont_have_active_payment_method: 'You do not have an active payment method',
  required_payment_method_0: 'Your current plan needs to have a linked payment method',
  required_payment_method_1: 'Add a new payment method to your account to continue using Almendra.',
  add_payment_method: 'Add payment method',
  payment_method: 'Payment method',
  add_method: 'Add',
  payment_method_question_delete: 'Do you want to delete your payment method?',
  payment_method_question_change: 'Do you want to change your payment method?',
  payment_method_question_add: 'Do you want to add a new payment method?',
  yes_add: 'Yes, add',
  method_credit_card: 'Credit card',
  method_debit_card: 'Debit card',
  method_added_date: 'Added on {{- date, MM/DD/YYYY}}',
  payment_method_not_found: 'You do not have a payment method entered',
  payment_method_message_change: 'At the time of subscribing you can also change your payment method.',
  payment_method_message_add_also: 'At the time of subscribing you can also add your payment method.',
  payment_method_message_add: 'At the time of subscribing you can add your payment method.',
  // BillingEstimation
  estimate_bill: 'Estimate invoice',
  bill_period_range: 'Period from {{- startDate, MM/DD/YYYY}} to {{- endDate, MM/DD/YYYY}}',
  bill_patients_estimation: 'How many different active patients do you estimate to have in this period?',
  more_than_x: 'More than {{count}}',
  your_estimated_bill_0: 'Your estimated billing is ',
  faq_what_is_active_patient: 'Active patient is that patient for whom you have had a appointment or had a meal plan in use during the period.',
  // TransactionStatus
  transaction_status_title: 'Awesome!',
  transaction_status_scheduled: 'Your subscription has been scheduled successfully',
  transaction_status_success: 'Your subscription has been successful',
  transaction_status_plan: 'Plan',
  transaction_status_startDate: 'Start date',
  transaction_status_nextBillingDate: 'Next billing',
  // SubscriptionSettings
  receipt: 'Invoice',
  up_to_3_active_patients: 'up to 3 active patients',
  unlimited_active_patients: 'unlimited active patients',
  x_active_patients: '{{count}} active patient',
  x_active_patients_other: '{{count}} active patients',
  active_patients_ratio: '{{count}} of {{limit}}',
  without_subscription: 'Without subscription',
  upgrade_subscription_title: 'Upgrade your subscription',
  upgrade_subscription: 'Upgrade Subscription',
  plan_alert_limit_error: 'You have exceeded the number of active patients allowed by your plan.',
  regulate_your_subscription: 'Regularize your subscription',
  requires_action: 'Requires action',
  plan_alert_payment_error: 'There was an error charging your plan on {{- date, LL}}.',
  regulate_subscription: 'Regularize Subscription',
  plan_renewal: 'Renewal',
  plan_renewal_status_cancelled: 'Canceled',
  billing_discount: 'off',
  current_billing: 'Current billing',
  plan_status: 'Status',
  plan_status_failed: 'Collection error',
  plan_status_active: 'Active',
  plan_active_patients: 'Active patients',
  plan_period: 'Period',
  plan_next_billing: 'Next billing',
  plan_billing_cancelled: 'renewal canceled',
  billing_transactions: 'Billing',
  billing_actual_period_use: 'Current use of the period',
  cancel_future_subscription: 'Cancel future subscription',
  cancel_scheduled_subscription_title: 'Do you want to cancel your scheduled subscription to {{name}}?',
  cancel_scheduled_subscription_content:
    "We remind you that your current plan ({{name}}) is valid until {{- date, MMMM DD YYYY}}. Until then, you won't be subscribed to any plan.",
  cancel_scheduled_subscription_success: 'Your scheduled subscription to {{name}} was successfully canceled.',
  cancel_scheduled_subscription_error: "We couldn't cancel your scheduled subscription to {{name}}.",
  transactionId: 'Transaction code',
  details: 'Details',
  transaction_amount: 'Amount',
  status: 'Status',
  transaction_charged: 'Success',
  transaction_failed: 'Error',
  transaction_refunded: 'Refund',
  view_billing_plans: 'View Plans',
  billingPlan: 'Plan',
  subscribed_since_f: 'Subscribed since',
  subscribed_since_m: 'Subscribed since',
  days_left: 'Days left',
  monthly: 'monthly',
  annually: 'annually',
  subscription_status_error: 'Charge error',
  subscription_status_success: 'Active',
  user_since: 'User since',
  subscription_details: 'Subscription details',
  current_plan_details: 'Plan details',
  transactionHistory: 'Billing history',
  date_format_short: '{{- date, MM/DD/YYYY}}',
  date_format_short_year: '{{- date, MM/DD/YY}}',
  date_format_short_mmm: '{{- date, MMM/DD/YYYY}}',
  dateTime_format_short: '{{- date, MM/DD/YYYY hh:mmA}}',
  dateTime_format_short2: '{{- date, MM/DD/YYYY, h:mm a}}',
  date_format_short_m: '{{- date, MMMM/DD/YYYY}}',
  date_format_long: '{{- date, MMM Do[, ]YYYY}}',
  next_billing: 'Next billing',
  active_patients: 'Active patients',
  today_expires_trial: 'Today your trial period expires',
  choose_your_plan: 'Choose your plan',
  you_arent_subscribed_m: "You aren't currently subscribed to a plan",
  you_arent_subscribed_f: "You aren't currently subscribed to a plan",
  next_subscription_in_process: 'We are transferring you to the {{name}}.\n Please, reload the page.',
  cancel_and_subscribe: 'Oops! Cancel your scheduled subscription and subscribe again.',
  start_subscription: 'Start subscription',
  renew_was_cancelled: 'Renew was canceled',
  start: 'Start',
  scheduled_plan_details:
    'You changed your plan to {{nextSubscriptionName}}, which will start on {{- nextSubscriptionDate, MM/DD/YYYY }}. Until then you may enjoy the benefits of your current {{currentSubscriptionName}}.',
  cancel_scheduled_subscription: 'Cancel scheduled subscription',
  regularize_subscription: 'Regularize subscription',
  almendra_ai_credits: 'Available credits - AlmendraAI',
  // GoogleLinkAccount
  link_account: 'Link account',
  unlink_account: 'Unlink account',
  google_reminder_extra:
    'This section is not related to the appointment time reminder from the Mailing section. To disable the native Google Calendar reminder, leave field as blank.',
  google_reminder_label: 'Reminder of events in Google Calendar (minutes)',
  google_color_label: 'Color of events in Google Calendar',
  google_email_label: 'Send software emails from my Gmail',
  google_email_extra_title: 'The next emails will be sent from your Gmail',
  google_email_extra_desc_0: 'Custom welcome to patient',
  google_email_extra_desc_1: "Patient's birthday greeting",
  google_email_extra_desc_2: 'Appointment reminder',
  google_email_extra_desc_3: 'Meal plan PDF',
  google_email_extra_desc_4: 'New patient registration',
  google_email_extra_desc_5: 'Expert confirmation to patient',
  google_calendar_label: 'Show the scheduled appointments on my Google Calendar',
  google_linking: 'Linking with Google',
  google_reminder_v_min: 'The max value allowed is 10 minutes before the start',
  google_reminder_v_max: 'The min value allowed is 48 hours before the start (2880 minutes)',
  google_unlink_title: 'Do you want you unlink your Google account?',
  account_successfully_unlinked: 'Account successfully unlinked',
  google_link_title: 'Do you want to link your Google account with Almendra?',
  google_link_content_0:
    'The scheduled appointments will be shown on your Google Calendar. However, changes that you make on your Google Calendar will not be updated on Almendra.',
  google_link_content_1: 'The emails sent to your patients will be sent directly from your Gmail.',
  google_link_content_2: "We will redirect you to Google's so you can make this action.",
  google_link_error: 'There was an eror while reaching Google. Try again.',
  unlink_error_message: 'There was an eror while unlinking your account. Please, try again.',
  yes_link_account: 'Yes, link my account',
  google_extra_access:
    "When you linked your Google account, you didn't allowed this permission. Please, link your account again and accept all the permissions.",
  // PopupNewAppointment
  appointment_in_process_v_title: 'You have an appointment in process',
  appointment_in_process_v_content: 'Finish the appointment {{name}}to start a new appointment.',
  with_patient: 'with {{name}}',
  all_php_appointments_taken: 'All the appointments on this service has been scheduled',
  appointment_with_videocall_title: 'This appointment has a video call',
  appointment_with_videocall_content: 'Do you want to open the video call link on a new tab?',
  error_PHPLimitReached: 'All of the available appointments on this service has been already used.',
  start_appointment: 'Start appointment',
  do_you_want_to_start_other_appointment: 'Do you want to start a new appointment?',
  start_new_appointment: 'Start a new appointment',
  you_have_next_appointments: 'You have appointments that will start soon',
  see_next_appointments: 'See next appointments',
  pending_past_appointments: '{{count}} delayed appointment',
  pending_past_appointments_other: '{{count}} delayed appointments',
  pending_future_appointments: '{{count}} appointment that will start soon',
  pending_future_appointments_other: '{{count}} appointments that will start soon',
  you_have_x_and_y: 'You have {{x}} and {{y}}',
  you_have_x: 'You have {{x}}',
  // PopupPDFAppointmentPreview
  files_attached: 'attachment',
  files_attached_other: 'attachments',
  subject: 'Subject',
  email_pdf_placeholder: "Enter the patient's email",
  to_email: 'To',
  appointment_report_included: 'Appointment report included',
  send_appointment_report_email: 'Send the Appointment Report',
  send_pdf_v_email_required: 'You must enter an email',
  send_pdf_v_email_valid: 'You must enter a valid email',
  send_pdf_v_subject_valid: 'You must enter a subject',
  sending: 'Sending...',
  sending_appointment_report_to_patient: 'We are sending the Appointment Report to {{name}}',
  this_action_could_take_seconds: 'This action could take a couple of seconds',
  sending_pdf_success: 'Email successfully sent to {{email}}',
  sending_pdf_error_title: "The email couldn't be sent",
  sending_pdf_error_content: 'Please, reload the page and try again',
  attachment_error_title: "The email couldn't be sent",
  attachment_error_content: 'There was a problem with the files attached',
  // EditDateMealPlanSummaryModal
  current_format: 'Current format',
  option: 'Option',
  option_other: 'Options',
  edit_mealPlan_date_change_days: 'Change its day',
  edit_mealPlan_date_maintain_days: 'Maintain on its day',
  edit_mealPlan_date_foods_are_example: 'The following foods are just an example',
  edit_mealPlan_date_question: 'What do you want to do with the current foods?',
  dayColumn: 'Day',
  day: 'day',
  day_other: 'days',
  week: 'week',
  week_other: 'weeks',
  date_day_month_format: 'MMMM Do',
  date_format_short_format: 'MM/DD/YYYY',
  date_format_shorter_format: 'MM/DD/YY',
  date_format_long_format: 'MM/DD/YYYY h:mm a',
  from_date: 'From',
  to_date_2: 'to',
  confirm: 'Confirm',
  what_is_this: "What's this?",
  mealPlan_dates: 'Meal plan dates',
  duration: 'Duration',
  date_format_long_time: '{{- date,MMM/DD/YYYY hh:mm a}}',
  date_format_short_time: '{{- date,MM/DD/YYYY h:mm a}}',
  food_mango: 'Mango',
  food_kiwi: 'Kiwi',
  food_banana: 'Banana',
  food_milk: 'Milk',
  food_egg: 'Eggs',
  food_pecan: 'Pecan',
  food_pear: 'Pear',
  monday_long: 'Monday',
  tuesday_long: 'Tuesday',
  wednesday_long: 'Wednesday',
  thursday_long: 'Thursday',
  friday_long: 'Friday',
  saturday_long: 'Saturday',
  sunday_long: 'Sunday',
  please_select_an_option: 'Please, select an option',
  edit_mealPlan_date_v: 'Please, enter the meal plan duration',
  edit_mealPlan_date_v_interference: 'The dates of this meal plan make conflict with another meal plan for this patient.',
  // EquivalentsFoodSwap
  patient_food_allergies: 'Foods allergies',
  patient_food_unliked: 'Unliked Foods',
  patient_food_liked: 'Liked Foods',
  none_foods: 'No foods',
  equivalents_swap_empty: "You don't have more equivalents for this food",
  of_food: 'of',
  equivalents_swap_heading: '¿Which of the following equivalents would you like to swap?',
  is_equivalent_to: 'Is equivalent to',
  equivalents_swap_title: 'Swap food for equivalence',
  choose_equivalence: 'Choose equivalence',
  swap: 'Swap',
  // AddExchangeGroup
  add_exchange_group: 'Add exchange group',
  add_group: 'Add group',
  add_exchange_group_title: 'You have unselected groups that was previously included on the meal plan, do you want to continue?',
  add_exchange_group_content_0: 'The following groups were included on the meal plan and will be removed',
  yes_continue: 'Yes, continue',
  group: 'Group',
  // ExchangePopupMeal
  cant_open_meal_details: "The details of this meal couldn't be opened",
  delete_option_title: 'Do you want to delete this option?',
  delete_option_content: 'All the included foods ({{count}}) will be deleted. This action cannot be undone.',
  save_and_close: 'Save and close',
  delete_option: 'Delete option',
  no_extra_options: 'No extra options',
  meal_without_options: "This meal doesn't have extra options assigned",
  option_not_created: 'Option not created yet. To create option {{count}}, insert a food to this section',
  each_option_must_contain: 'Each option must contain',
  analysis: 'Analysis',
  exchange_groups: 'Exchange groups',
  no_exchange_groups: 'No exchange groups',
  actions: 'Actions',
  add_food: 'Add food',
  copy_meal: 'Copy meal',
  delete_options: 'Delete options',
  time: 'Time',
  no_time: 'No time',
  no_comments: 'No comments',
  comment: 'Comment',
  comment_other: 'Comments',
  meal_comment_placeholder: 'Ex: Mix yogurt with oatmeal',
  // ExchangeTableMealPlanDays
  click: 'Click on',
  custom_notification_exchange_add: 'to add this food on the meal',
  custom_notification_exchange_delete: 'to remove this food from the meal',
  custom_notification_exchange_finish: 'Click on Finish to add this food to the selection',
  finish: 'Finish',
  edit_recipe_and_finish: 'Edit recipe and finish',
  indexesToAddFood_empty: "You haven't selected options to add this food",
  handleAddFoodOption_error: 'There was an error while adding some foods',
  handleDeleteFoodOption_error: 'There was an error while deleting this food',
  confirm_delete_option: 'Do you want to delete this option?',
  confirm_delete_option_content_many: '{{count}} foods included on this option will be deleted. ',
  handle_delete_meal_error: 'There was an error while deleteing this meal',
  confirm_delete_all_options_title: 'Do you want to delete all the options?',
  confirm_delete_all_options_content: 'All the options on this meal will be deleted.',
  couldnt_do_action: "The action couldn't be done",
  clear_day_content_title: 'Do you want to delete all the content of this day?',
  clear_day_content_content_0: 'Will delete all the content of ',
  clear_day_content_content_1: 'Exchange groups',
  clear_day_content_content_2: 'Extra options',
  clear_day_content_content_3: 'Meal times',
  clear_day_content_content_4: 'Meal comments',
  yes_clear_day: 'Yes, clear day',
  copy: 'Copy',
  day_without_meal: "This day doesn't have assigned this meal",
  hour_for_this_meal: 'Time for this meal',
  comment_for_this_meal: 'Comment for this meal',
  without_exchange_groups: 'No exchange groups',
  groupName_mayus: "GROUP'S NAME",
  // FoodOptionDisplayMP
  delete: 'Delete',
  // FoodInformation
  food_information_quantity_v: 'Enter a quantity greater than 0',
  food_information: 'Details',
  see_more_details: 'See more details',
  nutricional_details: 'Nutritional details',
  recipe: 'Recipe',
  recipe_other: 'Recipes',
  categories: 'Categories',
  source: 'Source',
  createdFood: 'Created food',
  createdFood_other: 'Created foods',
  software_recipe: 'Recipe from Almendra',
  createdRecipe: 'Own recipe',
  recipe_from: 'Recipe from {{name}}',
  brands_peru: 'Supermaket foods Perú (2021)',
  cooking: 'Cooking',
  time_minutes: '{{count}} minute',
  time_minutes_other: '{{count}} minutes',
  preparation: 'Preparation',
  portion: 'Portion',
  persons: '{{count}} person',
  persons_other: '{{count}} persons',
  ingredients: 'Ingredients',
  optional_ingredients: 'Optional ingredients',
  instructions: 'Instructions',
  image: 'Reference image',
  nutrients: 'Nutrients',
  total_weight: 'Total weight',
  'Alimentos infantiles': "Children's food ",
  'Alimentos preparados (Entradas)': 'Prepared foods (starter/entree) ',
  'Alimentos preparados (Segundos)': 'Prepared foods (main course) ',
  Aperitivos: 'Appetizers',
  Bebidas: 'Drinks',
  'Bebidas (alcohólicas y analcohólicas)': 'Drinks (alcoholic and analcoholic) ',
  'Carnes y derivados': 'Meats and derivatives',
  'Cereales de desayuno': 'Breakfast cereals',
  'Cereales y derivados': 'Cereals and derivatives',
  'Comida de restaurante': 'Restaurant food ',
  'Comida para bebés': 'Baby food ',
  'Comida rápida': 'Fast food',
  'Comidas, entradas y acompañamientos': 'Meals, entrees and accompaniments',
  'Cordero, ternero y productos de caza': 'Lamb, calf and hunting products',
  Dulces: 'Sweets',
  'Embutidos y fiambres': 'Sausages and crowds',
  'Especies y hierbas': 'Species and herbs',
  'Frutas y derivados': 'Fruits and derivatives',
  'Frutas y jugos': 'Fruits and juices',
  'Granos de cereales y pasta': 'Grains of cereals and pasta',
  'Grasas y aceites': 'Fats and oils',
  'Grasas, aceites y oleaginosas': 'Fats, oils and oleaginous oils',
  'Huevos y derivados': 'Eggs and derivatives',
  'Leche y derivados': 'Milk and derivatives',
  Legumbres: 'Legumes',
  'Leguminosas y derivados': 'Legumes and derivatives',
  'Lácteos y huevos': 'Dairy and eggs',
  Misceláneos: 'Miscellaneous',
  'Nueces y semillas': 'Nuts and seeds',
  'Pescados y mariscos': 'Fish and seafood ',
  'Productos avícolas': 'Poultry products',
  'Productos azucarados': 'Sugary products',
  'Productos de cerdo': 'Pork products',
  'Productos horneados': 'Baked goods',
  'Productos porcinos': 'Porcine products',
  'Sopas y salsas': 'Soups and sauces',
  Suplementos: 'Supplements',
  'Tubérculos, raíces y derivados': 'Tubers, roots and derivatives',
  Vegetales: 'Vegetables',
  'Verduras, hortalizas y derivados': 'Vegetables, fresh vegetables and derivatives',
  // GroceriesListModal
  groceriesList_reset_title: 'Do you want to reset the groceries list?',
  groceriesList_reset_content:
    'Upon confirming, the groceries list will be reseted with the foods from the meal plan. Every comment or group made on this list will be lost. This action cannot be undone.',
  yes_reset: 'Yes, reset',
  groceriesList_reset_success: 'Groceries list reseted successfully',
  groceriesList_reset_error: 'There was a problem while reseting the groceries list',
  groceriesList_delete_title: 'Do you want to disable the groceries list?',
  groceriesList_delete_content:
    "Upon confirming, your patient won't be able to visualiza the groceries list neither on his App nor the PDF. Every comment, group or change made on this list will be lost.",
  yes_disable: 'Yes, disable',
  groceriesList_delete_error: 'There was a problem while saving the groceries list. Please, if the problem presists, contact the support team.',
  groceries_list: 'Groceries list',
  groceriesList_pdf_error_title: 'There was a problem while generating the groceries list.',
  reload_and_try_again: 'Please, reload the page and try again later.',
  groceriesList_visibility_title: 'Do you want to change the visibility of the groceries list?',
  groceriesList_visibility_content:
    'The visibility will change from <1>{{from}}</1> to <3>{{to}}</3>. The groceries list will be generated again using the current foods of the meal plan. Every comment, group or change made on this list will be lost.',
  groceriesList_dave_error: 'There was a problem saving the groceries list.',
  groceriesList_unavailable:
    "Currently we don't have enabled the groceries list feature for the {{mealPlanType}} meal plan. If you want this feature to be developed, please, submit it through the Feedback Inbox.",
  mealPlanType_exchange: 'exchanges',
  mealPlanType_flexible: 'flexible',
  mealPlanType_blank: 'text',
  saving_changes: 'Saving changes...',
  downloading: 'Downloading...',
  download: 'Download',
  groceriesList_view_weekly_tooltip: 'Weekly groceries list for the meal plan. It will generate {{count}} list for the week of the meal plan.',
  groceriesList_view_weekly_tooltip_other:
    'Weekly groceries list for the meal plan. It will generate {{count}} lists for the {{count}} weeks of the meal plan.',
  weekly: 'Weekly',
  groceriesList_view_total_tooltip: 'All what needs to be bought for the meal plan in one single list',
  groceriesList_notes: 'Notes',
  groceriesList_notes_quantity: 'Food quantities were rounded up in multiples of 0.5 when the list was generated.',
  groceriesList_notes_recipes: 'The ingredients of the following recipes were included when the list was generated:',
  // IndicationsContainer
  add_indication: 'Add indication',
  indication_delete: 'Do you want to delete this indication?',
  indication_delete_success: 'Indication successfully deleted',
  indication_add_success: 'Indication successfully added',
  // MealPlanMailModal
  attachments_upload_limit: 'The maximum limit of attachments is 10MB',
  sending_meal_plan_to_patient: 'We are sending the Meal Plan to {{email}}',
  meal_plan: 'Meal plan',
  send_meal_plan_pdf: 'Send the Meal Plan',
  meal_plan_included: 'Meal plan included',
  attach_files: 'Attach files',
  // MealPlanPDF
  Comida: 'Meal',
  Lunes: 'Monday',
  Martes: 'Tuesday',
  Miércoles: 'Wednesday',
  Jueves: 'Thursday',
  Viernes: 'Friday',
  Sábado: 'Saturday',
  Domingo: 'Sunday',
  Lu: 'Mo',
  Ma: 'Tu',
  Mi: 'We',
  Ju: 'Th',
  Vi: 'Fr',
  Sá: 'Sa',
  Do: 'Su',
  'Al despertarse': 'Upon awakening',
  Desayuno: 'Breakfast',
  'Media mañana': 'Mid-morning',
  Almuerzo: 'Lunch',
  'Media tarde': 'Afternoon',
  Cena: 'Dinner',
  'Antes de dormir': 'Before sleep',
  'Pre-entreno': 'Pre-workout',
  'Post-entreno': 'Post-workout',
  mealTime: 'Time',
  or: 'or',
  and: 'and',
  exclude: 'Exclude',
  menu: 'Menu',
  professional: 'Professional',
  free_day: 'Day off',
  free_day_other: 'Days off',
  how_to_read_this: 'How to read this section? ',
  meal_plan_pdf_exchange_demo_read:
    'If on your breakfast meal appears “2 {{name}}”, you could pick up 2 foods with their respective servings to eat.',
  recipes: 'Recipes',
  minutes_short: 'min',
  recipe_portions: 'serving',
  recipe_portions_other: 'servings',
  optionals: 'Options',
  // PendingMealPlans
  empty_pending_meal_plans: "You don't have pending meal plans to finish!",
  creation_date: 'Creation date',
  progress: 'Progress',
  // MealPlansTable
  search_by_name: 'Search by name',
  empty_search_meal_plans: 'No meal plans found',
  empty_search_meal_plans_description_0: 'Start filtering the meal plans according to your search on the left panel.',
  empty_search_meal_plans_description_1: "In order to search, by the patient's name or by the meal plan's name, click on the <1 /> above this table.",
  empty_search_meal_plans_description_2: 'Finally, click on the row of the the meal plan you want to copy from.',
  show_meal_plans: 'Show meal plans',
  of_this_patient: 'From this patient',
  of_everyone: 'From everyone',
  format: 'Format',
  finished: 'Finished',
  unfinished: 'Pending',
  mealPlan_exchange: 'Exchange',
  mealPlan_blank: 'Text',
  mealPlan_flexible: 'Flexible',
  mealPlan_strict: 'Strict',
  'Otros objetivos': 'Other target',
  'Sin objetivo': 'Without target',
  caloric_target: 'Caloric target',
  show_all: 'Show all',
  this_week: 'This week',
  this_month: 'This month',
  this_year: 'This year',
  patient_sex: "Patient's sex",
  patient_tags: "Patient's tags",
  no_tag: 'Without tags',
  meal_plans: 'Meal Plans',
  loading: 'Loading...',
  meet_filters: 'Meet filter criteria',
  showing_recent_meal_plans: 'Showing {{count}} recent meal plans',
  show_x_more: 'Show {{count}} more',
  copy_other_meal_plans_title: 'Do you want to copy this meal plan to the current?',
  yes_copy: 'Yes, copy',
  cant_preview_plan: "Can't preview the plan",
  selected_meal_plan_details: 'Selected meal plan details',
  days: 'Days',
  meal_plan_summary: 'Meal plan summary',
  meal_plan_without_meals: "This meal plan doesn't have meals.",
  average_daily_calories: 'Average daily calories',
  meal_plan_consist_days: 'This plan consists on {{count}} day',
  meal_plan_consist_days_other: 'This plan consists on {{count}} days',
  meal_plan_consist_meals: 'Days have approximately {{count}} meal',
  meal_plan_consist_meals_other: 'Days have approximately {{count}} meals',
  meal_plan_summary_analysis_fat_high: 'High fat plan',
  meal_plan_summary_analysis_protein_high: 'High protein plan',
  meal_plan_summary_analysis_carbs_high: 'High carbs plan',
  meal_plan_summary_analysis_carbs_low: 'Low carbs plan',
  meal_plan_summary_analysis_balanced: 'Balanced plan',
  according_to_amdr_tooltip: 'According to AMDR (Acceptable Macronutrient Distribution Range) for adult people.',
  meal_plan_with_indications: 'This plan has the following indications:',
  meal_plan_without_indications: 'This plan has not indications',
  clear_filters: 'Clear filters',
  average_calories_target: 'Average calories target',
  // MealPlanSummary
  free_text_feature_disabled: 'This feature is disabled on the free-text meal plan',
  no_recipes_on_meal_plan: "You haven't added recipes to this meal plan",
  no_workout_on_meal_plan: "You haven't assigned a workout to this meal plan",
  meal_plan_type_feature_disabled: 'This feature is disabled on the {{name}} meal plan',
  groceries_list_empty_meal_plan: 'Add foods to this meal plan in order to enable the groceries list',
  create_template_from_meal_plan_title: 'Do you want to create a template using this meal plan?',
  create_template_from_meal_plan_content:
    "A new template will be created from this meal plan. Each day's target, meals, foods, comments and time will be copied to the new template.",
  enter_template_name: 'Enter the name of the template',
  yes_create_template: 'Yes, create template',
  create_template_from_meal_plan_v_name: 'Enter the name of the new template.',
  template_create_success: 'Template "{{name}}" successfully created',
  downloading_meal_plan: 'Downloading the Meal Plan',
  mealPlan_pdf_error_title: 'There was an error while generating the Meal Plan PDF.',
  empty_meal_plan_message_pdf: 'Add foods to this meal plan in order to {{name}}',
  download_the_pdf: 'download the PDF',
  send_the_pdf: 'send the PDF',
  app: 'App',
  app_always_shows_images: 'Images will always be shown on the App',
  pdf: 'PDF',
  show_calories: 'Show calories',
  show_calories_tooltip: 'Show calories and macronutrients of each day on the App. Show calories next to each food on the PDF.',
  show_groceries_list: 'Show groceries list',
  show_groceries_list_tooltip: 'Show the groceries list',
  show_recipes: 'Show recipes',
  show_recipes_tooltip: 'Show all the included recipes with their respective ingredients and instructions.',
  show_recipes_images: 'Show recipes images',
  show_recipes_images_tooltip: "Show all the available recipes' images.",
  show_workout: 'Show workout',
  show_workout_tooltip: 'Show the assigned workout.',
  meal_plan_workout_groceries_list: 'Meal plan, workout and groceries list',
  meal_plan_groceries_list: 'Meal plan and groceries list',
  meal_plan_workout: 'Meal plan and workout',
  template_just_created: 'Template "{{name}}"',
  meal_plan_just_created: 'Meal plan summary for {{name}}',
  no_meals_message: 'There are no meals on this meal plan',
  no_meals_description: 'To see the summary, add at least one meal. The meal plan will remain pending.',
  mealPlanName: 'Meal plan name',
  mealPlanDuration: 'Meal plan duration',
  mealPlanSummary_days: 'The plan consists in <1>{{count}} day{{name}}</1>',
  mealPlanSummary_days_other: 'The plan consists in <1>{{count}} days{{name}}</1>',
  model: 'model',
  mealPlanSummary_days_meals: 'Days have approximately <1>{{count}} meal</1>',
  mealPlanSummary_days_meals_other: 'Days have approximately <1>{{count}} meals</1>',
  things_to_keep_in_mind_patient: 'What things should {{name}} keep in mind?',
  meal_plan_options: 'Meal plan settings',
  create_template_from_meal_plan: 'Create template from this plan',
  download_pdf: 'Download PDF',
  send_pdf: 'Send PDF',
  // MicronutrientChart
  minimum_recommended: 'Minimum recommended',
  difference: 'Difference',
  surplus: 'Surplus',
  deficit: 'Deficit',
  // NewOrTemplate
  generated_with: 'Generated with',
  save_75p_time: 'Save up to 75% time',
  save_95p_time: 'Save up to 95% time',
  free_text_format: 'Free-text format',
  nutrients_not_calculated: 'No nutrients calculated',
  exchange_format: 'Exchange format',
  flexible_format: 'Flexible format',
  strict_format: 'Strict format',
  start_from_scratch: 'I want to start from scratch',
  select_the_type_of_x: 'Select the {{name}} format',
  start_from_point: 'I got a starting point',
  select_starting_point: 'Select the starting point of the plan',
  a_meal_plan: 'A meal plan',
  a_template: 'A template',
  another_plan: 'Other plan',
  mealPlanBrief_1: 'using the exchange system, based on model days',
  mealPlanBrief_2: 'that allows to choose among multiple food options, based on model days',
  mealPlanBrief_3: 'with meal times and meals that must be followed as told',
  mealPlanBrief_4: 'where nutrients will not be calculates. Write what you want',
  mealPlanBrief_engine: 'A single click. A customized meal plan.',
  select_the_plan_format: 'Select {{name}} you want to create the Meal Plan for {{patient}}.',
  the_template_which: 'the template from which',
  the_meal_plan_which: 'the plan from which',
  thats_how_it_will_look: "This is how your {{name}} would look like in PDF once it's {{verb}}",
  finished_m: 'finished',
  finished_f: 'finished',
  preview: 'Preview',
  model_days: 'Model days',
  meal_plan_visibility: 'How do you want to visualize the {{name}}?',
  byTable: 'As a table',
  byDay: 'Day by day',
  ready_to_create_m: 'Ready to make {{name}}?',
  ready_to_create_f: 'Ready to make {{name}}?',
  fantastic_template: 'an awesome template',
  fantastic_meal_plan: 'an awesome meal plan',
  select_left_options: 'Select one of the options from the left panel to start',
  almendra_ai_required_title: "It seems like we're missing something...",
  almendra_ai_required_content_0: 'To generate the meal plan with Almendra Engine®, we need the patient to be at least 18 years old.',
  almendra_ai_required_content_1: "We recommend that you review this information in the Patient's Profile.",
  almendra_ai_required_confirm: "Go to Patient's Profile",
  // OverheadMacrosPopover
  overhead_macros_title: 'This day contains {{name}}, but you assigned a target of 0 grams',
  actual_distribution: 'Actual distribution',
  // PrefillMealPlanButton
  prefill_meal_plan_success: 'Meal plan successfully generated',
  prefill_meal_plan: 'Fill plan automatically',
  prefill_meal_plan_title: 'Fill the meal plan automatically',
  prefill: 'Fill',
  one_moment_please: 'Wait a moment, please...',
  prefill_phrase_0: 'Sharpening the knife',
  prefill_phrase_1: 'Preparing the table',
  prefill_phrase_2: 'Folding napkins',
  prefill_phrase_3: 'Chopping onions',
  prefill_phrase_4: 'Boiling water',
  prefill_phrase_5: 'Stirring the sauce',
  prefill_phrase_6: 'Cleaning the kitchen',
  prefill_phrase_7: 'Tasting the soup',
  prefill_phrase_8: 'Adding spices',
  prefill_phrase_9: 'Preparing coffee',
  prefill_phrase_10: 'Scenting the environment',
  prefill_phrase_11: 'Placing the tablecloth',
  prefill_phrase_12: 'Serving the dishes',
  prefill_phraseB_0: 'Peeling and cutting fresh vegetables',
  prefill_phraseB_1: 'Washing salad leaves',
  prefill_phraseB_2: 'Grilling chicken on the BBQ',
  prefill_phraseB_3: 'Making homemade dressing',
  prefill_phraseB_4: 'Soaking beans',
  prefill_phraseB_5: 'Making homemade broth',
  prefill_phraseB_6: 'Baking a sugar-free fruit pie',
  prefill_phraseB_7: 'Mixing a fresh fruit salad',
  prefill_phraseB_8: 'Packing a healthy lunchbox',
  prefill_phraseB_9: 'Steaming a fish fillet',
  prefill_phraseB_10: 'Mixing a protein shake',
  prefill_phraseB_11: 'Soaking oats for breakfast',
  prefill_phraseB_12: 'Cooking quinoa in a pot',
  prefill_phraseB_13: 'Grilling sliced eggplant',
  prefill_phraseB_14: 'Preparing a vegetable stew',
  prefill_phraseB_15: 'Marinating tofu for dinner',
  prefill_phraseB_16: 'Julienning fresh carrots',
  prefill_phraseB_17: 'Making homemade tomato sauce',
  prefill_phraseB_18: 'Cooking brown rice',
  prefill_phraseB_19: 'Slow-cooking a meatless chili',
  prefill_phraseB_20: 'Mixing a lentil salad',
  prefill_phraseB_21: 'Baking fresh herb salmon',
  prefill_phraseB_22: 'Grating fresh parmesan cheese',
  prefill_phraseB_23: 'Baking a homemade whole wheat bread',
  prefill_phraseB_24: 'Mixing a chickpea salad',
  prefill_phraseB_25: 'Stir-frying seasonal vegetables in a wok',
  prefill_phraseB_26: 'Mixing a berry smoothie',
  prefill_phraseB_27: 'Slow-cooking a vegetable soup in a crockpot',
  prefill_phraseB_28: 'Roasting a fresh pumpkin',
  prefill_phraseB_29: 'Making a whole wheat pasta salad with fresh vegetables',
  prefill_description: 'Meals will be filled with different recipes according to the target exchange groups assigned.',
  prefill_n_options_label: 'How many options do you want to offer per meal?',
  prefill_n_options_extra: "In case a meal doesn't have exchange groups assigned, we will try to use the best fitting recipe for the plan",
  // PatientSelectOptions
  'No tiene': 'None',
  Leve: 'Mild',
  Moderado: 'Moderate',
  Fuerte: 'Strong',
  Severo: 'Severe',
  'Ganar masa muscular': 'Build muscle mass',
  'Perder peso': 'Weight loss',
  'Bajar de peso': 'Weight loss',
  'Subir de peso': 'Gain weight',
  'Tener más energía': 'More energy',
  'Mejorar hábitos alimenticios': 'Better meal habits',
  'Ganar músculo y perder grasa': 'Build muscle and burn fat',
  Casado: 'Married',
  Soltero: 'Single',
  Viudo: 'Widowed',
  Divorciado: 'Divorced',
  Separado: 'Separated',
  'Unión libre': 'Free union',
  Casada: 'Married',
  Soltera: 'Single',
  Viuda: 'Widowed',
  Divorciada: 'Divorced',
  Separada: 'Separated',
  Ron: 'Rum',
  Cerveza: 'Beer',
  'Vino Blanco': 'White wine',
  'Vino Tinto': 'Red wine',
  Whisky: 'Whisky',
  Pisco: 'Pisco',
  Vodka: 'Vodka',
  Gin: 'Gin',
  Jägermeister: 'Jägermeister',
  'Todos los días': 'Everyday',
  '1 o 2 veces por semana': '1 or 2 days per week',
  '1 vez cada 15 días': '1 day each 15 days',
  '1 vez al mes': '1 day a month',
  'Menos de 1 vez al mes': 'Less than 1 day per month',
  Nunca: 'Never',
  '5 o más tazas al día': '5 or more cups per day',
  '4 tazas al día': '4 cups per day',
  '3 tazas al día': '3 cups per day',
  '2 tazas al día': '2 cups per day',
  '1 taza al día': '1 cup per day',
  Interdiario: 'Interday',
  'Ocasiones puntuales': 'Occasionally',
  'Proteina de suero de leche': 'Whey protein',
  'Proteina de suero de leche aislada': 'Whey protein (isolated)',
  'Vitamina B12': 'Vitamin B12',
  Creatina: 'Creatine',
  Carnitina: 'Carnitine',
  Multivitamínico: 'Multivitamin',
  Glutamina: 'Glutamine',
  'Omega 3': 'Omega 3',
  BCAA: 'BCAA',
  Zinc: 'Zinc',
  Magnesio: 'Magnesium',
  ZMA: 'ZMA',
  HMB: 'HMB',
  Yohimbina: 'Yohimbine',
  'Potenciador de testosterona': 'Testosterone booster',
  'Ganador de peso (Mass gainer)': 'Mass gainer',
  Celiaquía: 'Celiac',
  Hipertensión: 'Hypertension',
  Dislipemias: 'Dyslipidemia',
  'Diabetes Mellitus': 'Mellitus diabetes',
  Obesidad: 'Obesity',
  'Normal - Menos de 1 vez al día': 'Normal - Less than 1 time a day',
  'Normal - Aprox. 1 vez al día': 'Normal - Aprox 1 time a day',
  'Normal - Más de 1 vez al día': 'Normal - More than 1 time a day',
  Diarrea: 'Diarrhea',
  Estreñimiento: 'Constipation',
  Sangrado: 'Bleeding',
  'Sin restricciones': 'Unrestricted',
  Vegana: 'Vegan',
  Vegetariana: 'Vegetarian',
  Mediterránea: 'Mediterranean',
  Ketogénica: 'Ketogenic',
  Orgánica: 'Organic',
  Paleolítica: 'Paleolithic',
  Celíaco: 'Celiac',
  Pescetariana: 'Pescetarian',
  lactose_intolerant: 'Lactose intolerant',
  gluten_intolerant: 'Gluten intolerant',
  'Es un familiar': 'Family',
  Referido: 'Referral',
  Facebook: 'Facebook',
  Instagram: 'Instagram',
  'Otra red social': 'Other social network',
  'Página web': 'Website',
  'Es un amigo': 'Friend',
  'Por mi trabajo': 'Work',
  // PopupBodyFormulas
  used_formulas: 'Used equations',
  bmr_long: 'Basal Metabolic Rate (BMR)',
  bmr_short: 'BMR',
  formula: 'Equation',
  values: 'Values',
  lean_body: 'Lean body mass',
  tdee_long: 'Total Daily Energy Expenditure (TDEE)',
  tdee_short: 'TDEE',
  active_physical_value: 'Physical activity value',
  calculation: 'Calculation',
  selected: 'Selected',
  select: 'Select',
  'Ecuación Mifflin-St Jeor': 'Mifflin-St Jeor Equation',
  'Ecuación Harris Benedict Revisada': 'Harris Benedict Reviewed Equation',
  'Ecuación Harris Benedict Original': 'Harris Benedict Original Equation',
  'Ecuación de la OMS/FAO': 'OMS/FAO Equation',
  'Ecuación Katch-McArdle': 'Katch-McArdle Equation',
  'Ecuación Cunningham': 'Cunningham Equation',
  popup_body_formulas_error_1: 'Please write a number',
  popup_body_formulas_error_2: 'Please write a number between 0 and 10',
  // PopupCopyMeal
  copy_meal_modal_title: 'Copy meal {{mealName}} - {{dayName}}',
  copy_meal_modal_description_0: 'Select the meals that you want to copy the foods from the meal {{mealName}} of {{dayName}}.',
  copy_meal_modal_description_1:
    'If a selected meal has already foods or exchange groups, it will be replaced instead. This action cannot be undone.',
  copying: 'Copying',
  copied: 'Copied!',
  // PopupMealPlanSettings
  confirm_this_action: 'Confirm this action',
  meal_plan_settings_confirm_content:
    'Upon confirming, all the foods on this meal plan will be reseted. Meals will be cleared. This action cannot be undone.',
  meal_plan_settings: 'Meal Plan settings',
  plan_settings: 'Plan settings',
  visibility: 'Visibility',
  table: 'Table',
  meal_plan_settings_table_description: 'A complete overview of the week as you make your plan.',
  by_day: 'By day',
  meal_plan_settings_day_description: 'To obtain accurate information of each day at a glance.',
  placing_foods: 'Foods placing',
  foods_search: 'Search bar',
  meal_plan_settings_search_description: 'Search and select foods from databases. Nutrients are calculated automatically.',
  free_text: 'Free-text',
  meal_plan_settings_freeText_description: "Enter what you want on the meals. Nutrients won't be calculated.",
  // CurrentBillingInfoModal
  why_this_amount: 'Why this amount?',
  current_active_patients: 'Current active patients for this period:',
  current_billing_description: 'In case you have no more active patients until {{- endDate, MM/DD/YYYY}}, your billing will be <1>$ {{amount}}</1>',
  current_billing_description_discount_included: 'Includes the discount of {{discount}} %.',
  current_billing_description_minimum:
    'Remember that the billing of the {{planName}} Plan is at least {{minPatients}} active patients. Therefore, it will still be charged as if you had {{minPatients}} active patients in case you have fewer active patients.',
  // ActivePatientsModal
  activePatients_details: 'Details',
  deleted_patient: 'Deleted patient',
  active_patients_actions_appointment: 'Finish appointment',
  active_patients_actions_create_meal_plan: 'Meal Plan creation',
  active_patients_actions_download_meal_plan: 'Meal Plan PDF download',
  active_patients_actions_date_meal_plan: 'Meal Plan date change',
  active_patients_actions_finish_meal_plan: 'Finish Meal Plan',
  active_patients_actions_period_with_meal_plan: 'Start the billing period with an active Meal Plan',
  active_patients_actions_send_meal_plan: 'Meal Plan PDF send',
  trigger_date: 'Trigger date',
  trigger_action: 'Trigger action',
  view_details: 'View details',
  active_patients_modal_description:
    'Here you can see when did the patients become active on the current billing period. The column "trigger action" tells the specific action that was made in the current billing period that activated the patient.',
  empty_active_patients: "You don't have active patients",
  // PatientPrefillModal
  patient_already_registered: 'This patient is already registered in Almendra',
  patient_prefil_description:
    '<0>{{patientName}} already has an account in Almendra.</0> We will send an email to <2>{{patientMail}}</2> so that {{patientName}} can confirm this action. Once the patient confirms, do you wish to:',
  visualize: 'Visualize',
  patient_prefill_visualize_description: "You will visualize the prexisting information on the patient's profile.",
  dont_visualize: "Don't visualize",
  patient_prefill_dont_visualize_description: "You won't visualize the prexisting information on the patient's profile.",
  // PayPalForm
  paypal_error_connect: 'There was an error while connecting to PayPal',
  try_again_later: 'Please, try again later',
  choose_an_option: 'Choose an option',
  paypal_pay_instructions: `Click on "PayPal" to pay with your PayPal account. In caso you don't have one, you can click the "Debit or credit card" button.`,
  wrong_payment_method: 'Wrong payment method',
  payment_method_error_description:
    "Don't worry, the payment wasn't made. Please, check if you have entered correctly your payment method information.",
  payment_to_do: 'Payment to make',
  paypal_usd_policy: 'Payments will be done in USD.',
  // PopupSpecialConditionInfo
  nursing: 'lactation',
  pregnancy: 'pregnancy',
  patient_on: 'Patient on',
  condition_nursing_info_0:
    'For lactating women who had an adequate increase in gestational weight, a factor of 505 kcal was added to BMR within the first 6 months of lactation.',
  condition_nursing_info_1: "It's left to your discretion how much more should be added after 6 months if breastfeeding continues.",
  nursing_data: 'Lactation data',
  nursing_stage: 'Lactation stage',
  extra_comments: 'Extra notes',
  nursing_source: 'Source: Food and Agriculture Organization (FAO)',
  pregestational_weight_basis: 'The pre-gestational weight is taken as a basis for BMR',
  condition_pregnancy_info_0: 'Depending on the quarter of pregnancy in which the patient is, the changes made in the BMR are:',
  condition_pregnancy_info_1: 'A 5% increase in BMR in the first quarter of pregnancy.',
  condition_pregnancy_info_2: 'A 10% increase in BMR in the second quarter of pregnancy.',
  condition_pregnancy_info_3: 'A 25% increase in BMR in the third quarter of pregnancy.',
  condition_pregnancy_twin: 'It is recommended to add approximately 500 extra kcal in cases of twin gestation.',
  pregnancy_data: 'Pregnancy data',
  pre_pregnancy_weight: 'Pre-gestational weight',
  pregnancy_stage: 'Pregnancy stage',
  pregnancy_type: 'Pregnancy type',
  pregnancy_type_twin: 'Twin Gestation',
  pregnancy_type_unique: 'Unique Gestation',
  pregnancy_source: 'Source: Food and Agriculture Organization (FAO) and Nutrition Care Manual (ADA)',
  pregnancy_requirements: `It is necessary to add the pre-gestational weight to be able to do the calculations correctly, it can be added in the patient's profile, in the "Lifestyle" section under "Special conditions"`,
  q1: 'First Quarter',
  q2: 'Second Quarter',
  q3: 'Third Quarter',
  q4: 'Forth Quarter',
  second_year: 'Second Year',
  third_year: 'Third Year',
  special_condition_date: '{{time}}, Week {{week}}',
  // SelectAvailableHour
  choose_future_time: 'Choose a future time',
  video_call_through: 'Video call through',
  'Consulta Presencial': 'On-site appointment',
  'Consulta a Domicilio': 'Delivery appointment',
  'Consulta Online': 'Online appointment',
  address: 'Address',
  agenda_v_address_enter: 'Enter your address',
  agenda_v_address_select: 'Select an address',
  agenda_v_date_select: 'Select a date',
  agenda_address_empty: 'There are no available addresses.',
  select_other_day: 'Select another day',
  agenda_v_time: 'Select a time',
  agenda_time_empty: 'There are no available appointments for this day.',
  // RecipeView
  edit_recipe_again_title: 'Do you want to edit this recipe?',
  edit_recipe_again_content: "Changes made to this reacite won't alter any meal plan, template or equivalent groups that contains this recipe.",
  editting_recipe: 'You are editting the recipe "{{name}}"',
  duplicated: 'duplicated',
  duplicate_recipe_title: 'You are about to duplicate this recipe',
  duplicate_recipe_content:
    'We recommend doing this only when you want to make a recipe very similar to the one you are duplicating. Remember to change the name of the recipe after clicking "Confirm"',
  duplicate: 'Duplicate',
  created_by: 'Made by',
  system_default: 'System',
  no_ingredients: 'No ingredients',
  no_instructions: 'No instructions',
  no_optional_ingredients: 'No optional ingredients',
  nutrients_per_serving: 'Nutrients per serving',
  // PatientsReport
  patients_per_active_php: 'Patients per active plan',
  export_to_excel: 'Export to Excel',
  used_appointment_date: ' Appointment date',
  patient_creation_date: 'Date of registration',
  active_php: 'Active plan',
  php_creation_date: 'Date of plan assignment',
  php_creation_date_short: 'Date of assignment',
  php_appointments: 'Appointments of plan',
  appointments_used: 'Appointments used',
  data: 'Data',
  patients_report_title: 'Report of patients per active plan as of {{- date, MM/DD/YYYY}} - {{name}}',
  download_success: 'Successfully downloaded',
  go_to_patient_profile: 'Do you want to go to {{name}} profile?',
  php_appointments_used_info: 'Finished or scheduled appointments',
  // OnBoardingMain
  demo_mealPlan_name: 'Meal plan (demo)',
  onboarding_aiChat_title: 'About our AI',
  onboarding_aiChat: 'Use Almendra AI',
  onboarding_ai_chat_modal_title: 'See guide',
  onboarding_ai_chat_guide:
    'At Almendra, we have embraced the wave of artificial intelligence. That\'s why we have created a chatbot that will greatly assist you in your day- to - day activities. Find out by clicking on "Start Tour" in the following tutorial.',
  onboarding_ai_chat_read_guide: 'We recommend reading this guide to get the most out of it.',
  onboarding_ai_chat_ask_bot:
    'After reading the guide, ask our chatbot a question (located in the bottom right corner) to win another 10% discount on the first month! Here are some example questions:',
  onboarding_ai_chat_questions:
    'What types of fish are very nutritious and not very expensive? What should I always keep in mind when treating a patient with diabetes? Give me a variety of fruits that have a high content of vitamin C',
  onboarding_signup: 'Creating your account',
  onboarding_create_meal_plan: 'Creating a meal plan',
  onboarding_create_meal_plan_by_template: 'Creating a meal plan from a template',
  onboaring_create_recipe: 'Creating a recipe',
  onboarding_making_an_appointment: 'Making an appointment',
  onboarding_creating_php: 'Creating health services',
  onboarding_setup_agenda: 'Learning to schedule appointments',
  onboarding_customizing: 'Customizing Almendra',
  onboarding_setup_booking: 'Learning to setup my online booking system',
  onboarding_register_revenue_expenses: 'Registering revenues and expenses',
  onboarding_patient_app: 'All your patients have an App!',
  onboarding_setup_schedule: 'Activating your online booking system!',
  onboarding_account_link: 'Link your account with Google, Zoom, PayPal, MercadoPago',
  onboarding_setup_button: 'Setup',
  onboarding_schedule_meeting: 'Scheduling my online onboarding', // cambiar a 'Schedule your online training',
  onboarding_finished_modal_title: "Awesome! You've just finished the introduction!",
  onboarding_finished_description_0: 'You now have <1>20% discount</1> on your first month for any monthly plan on Almendra.',
  onboarding_finished_description_1: 'If you have any inquiry, contact us through support chat. We will be happy to help you.',
  onboarding_finished_description_2:
    'Your opinion is very important for us. If there is something you would like to see on Almendra, please submit it throught the Suggestions Inbox on the top bar.',
  oboarding_intro_title: 'Introduction Guide',
  oboarding_intro_description: 'For each section completed, you will have <1>5%</1> extra discount on your first month',
  oboarding_intro_discount: 'Total accumulated discount: <1>{{count}}%</1>',
  onboarding_first_step: 'The first step',
  onboarding_first_step_steps: '1 of 1',
  onboarding_about_mealPlans: 'About meal plans, templates and recipes',
  onboarding_about_appointments: 'About appointments, services and calendar',
  of_step: 'of',
  onboarding_about_settings: 'Unleash the power of Almendra',
  onboading_about_meeting: 'Schedule your onboarding to the software with our team',
  // MealPlanCreate
  meal_plan_load_error: 'It seems there was an error while loading the meal plan. Please, reload the page and try again.',
  meal_plan_before_unload: 'There are unsaved changes, do you still want to exit?',
  meal_plan_peding_modal_title: 'This meal plan will be pending',
  meal_plan_peding_modal_content: `It will be visible on the pending meal plans' table of the "Meal Plan" section`,
  meal_plan_of_pending: 'Pending Meal Plan for {{name}}',
  meal_plan_pending: 'Pending Meal Plan',
  meal_plan_reset_title: 'Do you want to reset the meal plan?',
  meal_plan_reset_content: 'This action cannot be undone and will delete its content.',
  yes_start_again: 'Yes, start again',
  meal_plan_reset_success: 'Meal plan successfully reseted',
  meal_plan_format_success: 'Format updated successfully',
  meal_plan_exchange_tour_end_modal_title: 'Exchange Meal Plan Tour has just ended',
  meal_plan_exchange_tour_end_modal_content:
    'You may continue editting the meal plan. You can watch this tour again by clicking at the icon "<1/>" at the left side of the "Next" button.',
  meal_plan_from_template_modal_title: 'Do you want to create a meal plan from this template?',
  yes_create_from_template: 'Yes, create from template',
  meal_plan_from_template_success: 'Meal plan created from template',
  meal_plan_from_plan_success: 'Meal plan created from other plan',
  no_targets_placed: "You haven't set a target for each day.",
  no_targets_placed_content: 'You must set a target for each of the days in order to continue with the meal plan.',
  days_targets_required: 'You must set the target for at least one day in order to continue.',
  freeDay_check_modal_title: "You haven't set a target for the day {{name}}.<1/><2/>Do you want to set that day as a day off?",
  freeDay_check_modal_title_other: "You haven't set a target for the days {{name}}.<1/><2/>Do you want to set those days as days off?",
  select_days_as_free: 'Select days as days off',
  edit_caloric_target_success: 'Targets successfully editted',
  meal_plan_v_mealPlanName_required: 'Meal plan name must not be blank',
  edit_mealPlanName_success: 'Meal plan name successfully editted',
  mealPlan_day_add_success: 'Day successfully added',
  mealPlan_day_add_error: 'There was an error while adding the day',
  mealPlan_day_delete_v_unique: 'To delete the day, make sure that each day has a unique name.',
  delete_day_ask: 'Do you want to delete this day?',
  delete_day_content: 'All the content of this day will be deleted.',
  delete_day_success: 'Day successfully deleted',
  mealPlan_day_delete_error: 'There was an error while deleting the day',
  mealPlanName_edit_success: 'Meal plan name successfully editted',
  mealPlan_meals_order_update_success: 'Meals order successfully editted',
  mealPlan_copy_day_success: 'Day successfully copied',
  mealPlan_exit_save_confirm: 'Do you want to exit the editor?\nAll changes were saved',
  mealPlan_empty_title: "It seems that you haven't started to fill the meal plan...",
  mealPlan_empty_content: 'In order to finish this meal plan, you need to enter at least one food.',
  mealPlan_finish_success: "{{name}}'s meal plan has finished",
  mealPlan_delete_title: 'Do you want to delete this meal plan?',
  mealPlan_delete_success: 'Meal plan successfully deleted',
  mealPlan_exchange_tour_title: 'Do you want to start the Exchange Meal Plan Tour?',
  mealPlan_exchange_tour_content:
    'Upon confirming, we will show you a demo meal plan to explain you better each feature of this format. As soon as the tour finishes, you would be able to see back the current meal plan.',
  yes_start_tour: 'Yes, start tour',
  select_all_days: 'Select all days',
  macro_distribution_required: 'Set a macro distribution',
  last_changes_were_unsaved: "Last changes couldn't be saved. Please, reload the page",
  saving_mealPlan: 'Saving changes... Please wait',
  mealPlan_saving_error: "Couldn't save changes",
  mealPlan_saving: 'Saving changes',
  mealPlan_unsaved: 'Unsaved changes',
  mealPlan_saved: 'All changes saved',
  mealPlan_step_format: 'Format',
  mealPlan_step_targets: 'Targets',
  mealPlan_step_plan: 'Plan',
  mealPlan_step_summary: 'Summary',
  watch_tour: 'Watch tour',
  go_to_appointment: 'Back to appointment',
  delete_plan: 'Delete plan',
  mealPlan_next_tooltip_button: 'Finish setting up the targets for all of the days before going to next step',
  // MealPlanTemplateCreate
  template_old_version_title: 'We found a version of this template that is not saved',
  template_old_version_content_0: 'The last updated date of the unsaved version was',
  template_old_version_content_1: 'The last updated date of the current template is',
  template_old_version_content_2: 'Do you want to recover the unsaved version?',
  recover_version: 'Recover unsaved version',
  discard_version: 'Discard unsaved version',
  recover_version_error: 'There was a poblem while recovering the unsaved version. Please, reload the page and try again',
  is_template_finished_title: 'Did you finish editting the template',
  is_template_finished_content: 'If you select "No" it will be shown on your pending templates table.',
  no_will_finish_later: 'No',
  template_finished_success: "Template '{{name}}' finished",
  template_pending_with_name: "Template '{{name}}' pending",
  template_pending: 'Template pending',
  template_reset_title: 'Do you want to reset the template?',
  template_reset_success: 'Template reseted',
  template_v_mealPlanName_required: "Template's name cannot be blank",
  template_edit_mealPlanName_success: "Template's name successfully editted",
  template_empty_title: "It seems that you haven't started to fill the template...",
  template_empty_content: 'In order to finish this template, you need to enter at least one food.',
  template_delete_title: 'Do you want to delete this template?',
  template_delete_success: 'Template successfully deleted',
  // MealPlanTemplateMain
  edit_template_title: 'Do you want to edit template "{{name}}"?',
  clone_template_title: 'Do you want to duplicate template "{{name}}"?',
  clone_template_content: 'All the days with its respective meals and foods will be also copied.',
  yes_clone_template: 'Yes, duplicate template',
  clone_template_success: 'Template "{{name}}" duplicated successfully',
  delete_template_title: 'Do you want to delete template "{{templateName}}"?',
  yes_delete_template: 'Yes, delete template',
  delete_template_success: 'Template "{{name}}" successfully deleted',
  delete_template: 'Delete template',
  you_have_x_pending_templates: 'You have {{count}} pending template to finish',
  you_have_x_pending_templates_other: 'You have {{count}} pending templates to finish',
  pending_templates: 'Pending templates',
  meal_plan_templates: 'Meal plan templates',
  finished_templates: 'Finished templates',
  per_daily_calories: 'By daily calories',
  per_format: 'By format',
  // PopupTargetMeals
  only_one_meal_no_block: 'Only one meal exists, no need to lock it',
  has_already_been_blocked: 'has also been locked',
  lock_meal_target_description: 'As the rest of the meal times were locked, {{name}} has also been locked, since it could no longer be modified.',
  at_least_one_meal: 'You must have at least one meal',
  all_percentage_to_assign: 'You must allocate 100% of the calories!',
  distribution_per_meal_time: 'Distribution by meal time',
  days_have_same_meal_targets: 'Selected days have the same calorie distribution by meal time',
  delete_x: 'Delete {{name}}?',
  lock_target: 'Lock target',
  unlock_target: 'Unlock target',
  distribute_macros: 'Distribute macros',
  should_have_calories_in_meal: 'To distribute the macros, the food must have calories entered',
  we_found_an_inconsistency: 'We found an inconsistency',
  selectedInconsistencyObjective_title:
    'The daily macronutrient goals (the ones at the top of this window) that you set do not match with the meal time weighted macronutrient goals and their respective calories. You must decide which ones to keep. If you need help, do not hesitate to contact us through the chat!',
  selectedInconsistencyObjective_description: 'Select which goals you want to stay with',
  daily_targets: 'Daily targets',
  weighted_objectives: 'Weighted targets',
  // BillingMain
  with_scheduled_subscription_title: 'You have an upcoming plan scheduled for {{- date, MM/DD/YYYY}}',
  with_scheduled_subscription_content:
    'In order to choose a new subscription plan, first cancel your future subscription in <1> Settings > Subscription </1>.',
  do_you_want_to_resuscribe: 'Do you want to subscribe to the {{planName}} again?',
  regulate_subscription_title: 'Do you want to continue with your subscription to {{name}}?',
  regulate_subscription_description:
    'We will proceed to subscribe you to {{name}} since there was an error in the last payment of your automatic renewal.',
  yes_subscribe: 'Yes, subscribe',
  pay_subscription_upgrade_0: 'You are changing from <1>{{fromPlan}}</1> to <3>{{toPlan}}</3>.',
  pay_subscription_upgrade_1: 'As you still have a plan in effect, <1> we will refund the amount from your current plan that is not used yet. </1>',
  pay_subscription_upgrade_2:
    'For example: if your next billing date is within 15 days and your plan has monthly billing, we will refund 50% (half of the month) of the amount paid for your previous subscription.',
  pay_subscription_upgrade_3: 'Upon confirmation, you can take advantage of all the benefits of your new plan.',
  pay_subscription_downgrade_0:
    'On your next billing date (<1>{{nextDate}}</1>) your subscription will change from <3>{{fromPlan}}</3> to <5>{{toPlan}}</5>.',
  pay_subscription_downgrade_1: 'Until then, you can take advantage of the benefits of your current subscription (<1>{{name}}</1>).',
  pay_subscription_new: 'You are subscribing to {{name}}. As soon as you confirm you can take advantage of all the benefits of your new plan.',
  you_are_subscribing_same_plan: 'You are trying to subscribe to the same plan.',
  pay_subscription_modal_title: 'Do you want to subscribe to {{name}}?',
  yes_schedule_subscription: 'Yes, schedule subscription',
  pay_subscription_modal_error: 'An error occurred. Please refresh the page. In case the error persists, contact us by chat.',
  validating: 'Validating...',
  scheduling_subscription: 'Scheduling change of plan in the next billing',
  certifying_transaction: 'Certifying transaction',
  paying_content: 'This action may take a few seconds. Please do not close or refresh this page',
  next_subscription_due_title: 'You have a next plan that will be applied on {{nextDate}}',
  next_subscription_due_content: 'In order to choose a new subscription plan, first cancel your scheduled subscription',
  click_here: 'Click here',
  plan: 'Plan',
  payment: 'Payment',
  go_back: 'Back',
  checkout: 'Checkout',
  certifying_transaction_wait: 'Certifying transaction. We are back with you shortly ...',
  you_have_discount_referral: 'You have a <1>{{referralBenefit}}% discount</1> for life thanks to the referral program!',
  your_subscription_details: 'Your subscription details',
  first_month: 'first month',
  after_discount: 'Then: US$ {{price}}/month',
  month: 'month',
  normal_price: 'Regular price',
  free_plan_disclaimer_2_dollars: 'To process with the free subscription, you will be charged a symbolic amount of US$ 2 one time only.',
  this_plan_will_be_billed: 'This plan will be billed {{name}}',
  next_billing_date: 'Next billing date',
  pci_disclaimer_processing: 'This payment is processed securely by {{name}}, a PCI Compliance Level 1 payment provider.',
  regulate_subscription_alert:
    'To regularize your subscription due to <1>payment error</1>, subscribe to the current one again. You can also take advantage of choosing other plans that suit your needs.',
  confirm_and_pay: 'Confirm and pay',
  processing_transaction: 'Processing transaction',
  already_subscribed_alert_m: 'You are already subscribed to this plan.',
  already_subscribed_alert_f: 'You are already subscribed to this plan.',
  summary: 'Summary',
  awesome: 'Awesome!',
  subscription_create_success: 'Your subscription has been successful',
  subscription_schedule_success: 'Your subscription has been scheduled successfully',
  start_date: 'Start date',
  go_to_home: 'Go to Home',
  error_occurred: 'An error occurred',
  subscription_create_error_title: 'Your subscription could not be made.',
  subscription_create_error_description:
    "Don't worry, this means that there was no charge from your card. Verify that the information you enter is valid.",
  go_previous: 'Go back',
  'Plan Básico': 'Basic Plan',
  'Plan Avanzado': 'Advanced Plan',
  'Plan Experto': 'Expert Plan',
  'Plan Ilimitado': 'Unlimited Plan',
  Avanzado: 'Advanced',
  Experto: 'Expert',
  Ilimitado: 'Unlimited',
  billing_fetch_error: 'Oops! An error occurred. Please refresh the page and try again',
  // WorkoutPDF
  workout_routine: 'Workout',
  workout_with_equipment: 'This workout requires equipment',
  workout_without_equipment: "This workout doesn't require equipment",
  exercise_days: 'Exercise days',
  minutes_duration: 'Duration (in minutes)',
  routine_goal: 'Workout goal',
  routine_details: 'Workout details',
  // TableMealPlanDays
  custom_notification_tmpd_add: 'to add this food in that meal',
  custom_notification_tmpd_delete: 'to delete this food in that meal',
  custom_notification_tmpd_finish: 'Click on Finish or on the food search bar to add another meal',
  copying_meal: 'You are copying this food!',
  already_copied_meal: 'This food has already been copied!',
  cannot_copy_meal_free_day: "You can't copy a meal on a day off",
  copy_meal_title: 'Do you want to copy this meal?',
  copy_meal_description:
    'In case the meal you just selected contains foods, these will be replaced by the meal "{{mealName}} - {{dayName}}". This action is irreversible.',
  yes_copy_meal: 'Yes, copy meal',
  belongs_to_free_day_v: 'You cannot add a food to a meal that belongs to a day off',
  drop_belongs_to_free_day_v: 'To add a food, first unset the day off from {{dayName}}.',
  meal_belongs_to_free_day_v: 'To add a meal, first unset the day off from {{dayName}}.',
  random_equivalence: 'Random equivalence',
  information: 'Details',
  delete_meal_row_title: 'Do you want to remove the meal {{mealName}} from all the days?',
  delete_meal_row_description: 'If any of the days you have calorie goals per meal, these will be reset. You can reassign them in the previous step.',
  food_name_v_required: 'The name of the food cannot be empty',
  pre_confirm_add_many_foods_v: 'You have not selected foods to add this food',
  delete_meal_button_title: 'Do you want to delete "{{mealName}} - {{dayName}}"?',
  yes_delete_meal: 'Yes, delete meal',
  delete_meal_button_content:
    'Do this if you want the patient to not have {{mealName}} on this day. When confirming, the food, comments and meal time will be deleted and cannot be recovered.',
  delete_meal_button_extra: "Calorie goals for today's meals will be reset. You can reassign them in the previous step.",
  day_is_already_empty: 'This day is already empty',
  reset_day_title: 'Do you want to delete the content from {{name}}?',
  set_free_day_title: 'Do you want to set {{dayName}} as a day off?',
  unset_free_day_title: 'Do you want to unset {{dayName}} as a day off?',
  yes_set_free_day: 'Yes, set as day off',
  yes_unset_free_day: 'Yes, go to targets',
  unset_free_day_content:
    'By confirming, you will go to the step of choosing the calorie and macronutrient goals, so that you can set goals for this day.',
  set_free_day_content_0: 'This day you will not have assigned foods. However, you can place indications in the last step of this editor.',
  set_free_day_content_1: 'If you add a food to this day, it will automatically stop being a day off.',
  add_blank_meal_v: 'You must enter at least one food',
  replace_blank_meal_title: 'Do you want to replace this meal?',
  replace_blank_meal_content:
    'The meal you just selected already contains food. By clicking on "Yes, replace meal", everything written in the selected meal will be replaced by what is written in the field below the table. This action is irreversible.',
  yes_replace_meal: 'Yes, replace meal',
  handle_blank_area_click: 'Click on Finish to be able to add more food',
  food_already_added_meals: 'The food is already included in this meal for every day',
  special_condition_requirements_pregnancy: 'The requirements have been modified specifically for pregnant patients.',
  special_condition_requirements_lactating: 'The requirements have been modified specifically for lactating patients..',
  day_target: 'Target',
  day_with_meal_time_targets: 'Day with meal-time targets',
  copy_with_equivalence: 'Copy with equivalences',
  set_free_day: 'Set as day off',
  unset_free_day: 'Unset day off',
  legend: 'Legend',
  foods: 'Foods',
  place_it: 'Place foods',
  one_food_per_line: 'One food per line',
  meal: 'Meal',
  // MealPlanTemplatesTable
  empty_pending_meal_plan_templates: "You haven't created templates yet",
  template: 'Templates',
  duplicate_template: 'Duplicate template',
  // PopupUploadPatients
  file_loaded_successfully: '{{name}} loaded successfully',
  import_patients: 'Import patients',
  import_patients_list: 'Import patients list',
  import_your_patients: 'Import your existing patients into the software',
  import_patients_step_1: "Download our template and follow the instructions when filling out your patients' information.",
  import_patients_step_2: 'After completing the template, upload it here.',
  import_patients_step_3: 'Click on "Import patients" to upload your patients to the system.',
  import_patients_step_4: 'Done! Refresh the page and you will be able to see the imported patients.',
  import_patients_file_name: 'Import patients template',
  download_template: 'Download template',
  upload_file: 'Upload file',
  uploading_file: 'Uploading file',
  // PreciseObjective
  delete_precise_objective_title: 'Do you want to delete the specific goal?',
  delete_precise_objective_content: 'All the fields associated with this goal will be cleared.',
  patient_with_objective: '{{name}} has a specific goal?',
  precise_objective_ex: 'A specific goal is one that has a specific measurement and a deadline, eg: Reach 65kg in 4 months',
  measuremente_value_target: 'Target value',
  end_date: 'End date',
  initial_value: 'Initial value',
  recent_value: 'Recent value',
  measured_on: 'measured on',
  add_at_least_two_measurements: 'Enter at least two measurements from the start date to see the progress',
  // AddBlogPost
  enter_a_valid_title: 'Enter a valid title',
  create_new_post: 'Create new post',
  new_post: 'New post',
  post_title: 'Title of the post',
  post_link: 'Link of the post',
  blog_post_v_title_required: 'Please, enter the title',
  blog_post_v_title_min: 'Min 12 characters',
  blog_post_v_title_unique: 'You have already created a post with this title. Remember that the title of the publication must be unique.',
  enter_title: 'Enter the title',
  // PatientCustomFields
  custom_fields_title: 'Custom fields (Patient profile)',
  custom_fields_subtitle:
    "Create your own custom fields to save additional information on your patients' profile. You can view them in the <1>Custom fields</1> tab within the <3>Information</3> section in the patient profile.",
  patient_profile: 'Patient profile',
  custom_fields_settings_extra: "Can't find a field in the profile? Create your own custom fields to save more information about your patients",
  setup: 'Setup',
  custom_fields_name: 'Name of field',
  custom_fields_name_validator: 'Please, enter the name of the custom field',
  custom_fields_type: 'Type',
  custom_fields_type_long: 'Type of field',
  custom_fields_type_validator: 'Please, select the type of the custom field',
  custom_fields_options: 'Options',
  custom_fields_options_long: 'Field options',
  custom_fields_options_extra: 'Add the possible options for this custom field',
  custom_fields_short_answer: 'Short answer',
  custom_fields_paragraph: 'Long aswer',
  custom_fields_number: 'Number',
  custom_fields_radio: 'Radio',
  custom_fields_tags: 'List (multiple option)',
  custom_fields_select: 'List (unique option)',
  custom_fields_delete_title: 'Do you want to delete this custom field?',
  custom_fields_delete_content:
    'This field will also be removed from your custom forms where you have included it. We remind you that this action is irreversible.',
  custom_fields_delete_error: 'There was a problem while deleting the field',
  custom_fields_delete_success: 'Custom field successfully removed',
  custom_fields_edit_error: 'There was a problem while editing the field',
  custom_fields_edit_success: 'Custom field successfully edited',
  custom_fields_add_error: 'There was a problem while creating the field',
  custom_fields_add_success: 'Custom field successfully created',
  custom_fields_validator_options_min: 'Add at least 2 options',
  custom_fields_validator_options_empty: 'Options cannot be empty',
  custom_fields_validator_options_unique: 'Options must be unique',
  custom_fields_create: 'Create field',
  custom_fields_create_title: 'Create custom field',
  custom_fields_edit_title: 'Edit custom field',
  add_option: 'Add option',
  custom_fields_default_value: 'Here would go the value of the field',
  this_is_an_answer: 'This is an answer',
  this_is_a_long_answer: 'This is a longer answer, in which the user will be able to enter more details ...',
  example: 'Example',
  // EmptyCustomFields
  empty_custom_fields_title: "You haven't created your own custom fields yet.",
  empty_custom_fields_create_pre: 'Easily create them in',
  empty_custom_fields_path: 'General Settings > Patient profile > Custom fields',
  empty_custom_fields_purpose_title: 'What are custom fields for?',
  empty_custom_fields_purpose_content:
    'Custom fields are used to save patient information when you cannot find a field in the patient profile, you can create your own custom fields with the title and field format.',
  empty_custom_fields_purpose_example:
    'For example, if you want to save the information "<1> {{label}} </1>" you can do so and even add options such as "<3> {{option_1}} </3>", "<5> {{ option_2}} </5> "and" <7> {{option_3}} </7> ".',
  empty_custom_fields_purpose_result: 'The result will be the following:',
  empty_custom_fields_purpose_example_label: 'Who prepares the meals?',
  empty_custom_fields_purpose_example_option_1: 'Himself/Herself',
  empty_custom_fields_purpose_example_option_2: 'Family',
  empty_custom_fields_purpose_example_option_3: 'Other',
  // CustomFormsModal
  custom_form_delete_title: 'Do you want to delete this form?',
  custom_form_delete_content:
    'Any patient who currently has this form will not be able to fill it out. We remind you that this action is irreversible.',
  custom_form_delete_success: 'Form deleted successfully',
  custom_form_delete_error: 'There was an error deleting the form. Try again later.',
  dont_send_form: "Don't send form",
  see_my_forms: 'See my forms',
  my_custom_forms: 'My forms',
  no_custom_forms: 'You currently have no created forms',
  // ImagesUploadingInstructions
  image_upload_instruction_1: 'Upload an image with the button below.',
  image_upload_instruction_2: 'Click <1> Copy link </1> next to the uploaded image.',
  image_upload_instruction_3: 'In the text editor, click <1>Image..</1> (Insert - Image).',
  image_upload_instruction_4: 'Paste the generated link in the field <1>Link</1> and add a description.',
  // EditBlogPost
  delete_image_title: 'Do you want to delete this image?',
  delete_image_success: 'Image deleted successfully',
  delete_image_error: 'There was an error while deleting the image',
  upload_image_success: 'Image uploaded successfully',
  upload_image_error: 'There was an error uploading the image',
  blog_post_edit_success: 'Your post changes have been saved successfully.',
  blog_post_edit_error: 'An error occurred while saving your post content.',
  edit_post: 'Edit post',
  delete_post: 'Delete post',
  how_to_upload_image_blog: 'How to upload an image to your publication?',
  blog_post_tag_extra: 'Tags are keywords that best describe the content of your post. Separate them by entering a comma.',
  blog_post_tag_extra_ex: 'Example: health, nutrition, lifestyle',
  add_tags: 'Add tags',
  content: 'Content',
  blog_post_editor_initial_0: 'Hello {{firstName}}!',
  blog_post_editor_initial_1: 'In this section you can:',
  blog_post_editor_initial_2: 'Write your articles.',
  blog_post_editor_initial_3: 'Challenge your creativity.',
  blog_post_editor_initial_4: 'Share your knowledge with the world.',
  blog_post_editor_initial_5: 'Wish you the best,',
  blog_post_editor_initial_6: 'Almendra Team :)',
  follow_steps_upload_image: 'Follow the steps below to upload an image to your {{name}}',
  blog_post_images: 'Post images',
  uploading_image: 'Uploading image',
  blog_post_upload_image: 'Upload an image to your post',
  // EditPersonalPageContent
  personal_page_edit_success: 'Personal page changes have been saved successfully.',
  personal_page_edit_error: 'An error occurred while saving the content of your personal page.',
  how_to_upload_image_personal_page: 'How to upload an image to your personal page?',
  images_folder: 'Images folder',
  upload_image: 'Upload image',
  delete_image: 'Delete image',
  // GeneralSettings
  change_currency_title: 'You are about to proceed to change the currency type in all the software, do you want to continue?',
  change_currency_content_0:
    'By clicking on "Yes" <1> all your accounting will change currency and all payments that you have historically received in this new currency will be edited. </1>. The exchange rate of the day will be used to make the conversions of your payments.',
  change_currency_content_1: 'We only recommend doing this in case you have permanently changed the type of currency you receive for your services.',
  change_currency_error: 'The currency exchange could not be made. Please try again later.',
  change_measurement_title: 'Do you want to change the waist measurement method?',
  you_have_selected_at_least_one_of: 'You have selected at least one of the following measures',
  change_measurement_many_waist:
    'It seems that at this point you want to change the measurement method of the waist circumference. Therefore, when you click on Accept, any of those measurements you have will be deselected, and it will be selected',
  you_have_selected: 'You have selected the measurement',
  change_measurement_many_waist_2:
    'It seems that at this point you want to change the measurement method of the waist circumference. Therefore, when you click OK, that measurement will be deselected, and the following will be selected',
  field_must_not_be_empty: 'The field cannot be empty',
  your_current_method_is_already_name: 'Your current method is already the {{name}}',
  body_fat_method_v: 'You have not selected all the necessary measurements to use this formula',
  general_setting_error: 'An error occurred while making changes. Please try again.',
  general_disclaimer_foodSource: 'Some food names in the databases have been slightly modified in order to have a clearer meaning for their reading.',
  data_bases: 'Databases',
  spanish: 'Spanish',
  english: 'English',
  units: 'Units',
  length: 'Length',
  meter: 'Meter',
  foot_and_inch: 'Foot and Inch',
  kilogram: 'Kilogram',
  pound: 'Pound',
  energy: 'Energy',
  kilocalorie: 'Kilocalorie',
  kilojoule: 'Kilojoule',
  appointments: 'Appointments',
  appointments_settings_label: 'Show scheduled appointments that start within',
  appointments_settings_extra: 'It will be displayed in Home and in the Start Appointment button',
  average_appointment_duration: 'Average appointment duration',
  make_online_appointments: 'Do you make online appointments?',
  make_delivery_appointments: 'Do you carry out appointments at home?',
  send_schedule_email_appointment: 'Do you want to send an automatic email to the patient when scheduling an appointment?',
  request_custom_form_fill: 'Request the filling of the form prior to the appointment',
  request_custom_form_fill_v_empty: 'Start by creating your forms to use this functionality.',
  request_custom_form_fill_v_enabled: 'Your patients will be asked to fill out the form when scheduling appointments.',
  request_custom_form_fill_v_disabled: 'Your patients will not be asked to fill out the form.',
  office_address: 'Office address',
  business_hours: 'Business hours',
  business_hours_empty: 'You have not set your business hours',
  predictive_formulas: 'Predictive formulas',
  current_method: 'Current method',
  save_method: 'Save method',
  required_measurements_on_method: 'Required measurements on the',
  show_DCText: 'Body density is obtained. The {{- name}} will be applied to calculate the body fat percentage.',
  density_to_body_fat: 'Body density to fat percentage',
  'Método de Siri': 'Siri Method',
  'Método de Brozek': 'Brozek Method',
  body_density_disclaimer:
    'The body density will be found from the method chosen in "Fat percentage", in case the method returns the body density as a result',
  consumption_frequency_form: 'Consumption frequency form',
  consumption_frequency_form_extra_settings: `Add and edit the food groups you want. You will be able to find the form within the "Food Record" section in each patient's profile.`,
  meal_plan_error_range: 'Caloric target error range (progress)',
  meal_plan_error_range_extra:
    'Ex: When the margin of error is 10% and the day of the meal plan has a 2000kcal target, if the sum of the calories for the day is between 1900-2100 kcal then you will be notified that you have completed the day.',
  meal_plan_error_range_placeholder: 'Ex: 4%',
  finance: 'Finance',
  currency: 'Currency',
  card_comission: 'Average card commission (POS)',
  card_comission_extra: 'In case you own a POS for you to receive debit or credit card payments.',
  card_comission_placeholder: 'Ex: 8%',
  timezone: 'Timezone',
  'Método 7 Calipers de Jackson/Pollock': 'Jackson/Pollock 7-Site Caliper Method',
  'Método 4 Calipers de Jackson/Pollock': 'Jackson/Pollock 4-Site Caliper Method',
  'Método 3 Calipers de Jackson/Pollock': 'Jackson/Pollock 3-Site Caliper Method',
  'Método de Durnin/Womersley': 'Durnin/Womersley Method',
  'Método de Petroski': 'Petroski Method',
  'Método de Sloan': 'Sloan Method',
  'Método de Wilmore-Behnke': 'Wilmore-Behnke Method',
  'Método de Katch-McArdle': 'Katch-McArdle Method',
  'Método de Lean': 'Lean Method',
  'Método de Faulkner': 'Faulkner Method',
  'Método de Carter': 'Carter Method',
  'Método de Ramirez-Torun': 'Ramirez-Torun Method',
  'Método de Deurenberg': 'Deurenberg Method',
  'Método de Yuhasz': 'Yuhasz Method',
  Básico: 'Basic',
  Diámetro: 'Diameter',
  Perímetro: 'Circumference',
  'Pliegue Cutáneo': 'Skin fold',
  Analítico: 'Analytic',
  'Índices corporales': 'Body indices',
  'Longitud/Altura': 'Length/Height',
  mealPlan_preview_front: 'Preview cover',
  default_image: 'Default image',
  default_mealPlan_pdf_image: 'Image for the meal plan PDF (header)',
  default_mealPlan_pdf_image_extra: 'Make sure that the image has dimensions of 23:7 ratio (2300 x 700) to avoid being distorted',
  language: 'Language',
  language_change_title: 'Do you want to change the language of the software?',
  language_change_description: 'We inform you that the language of the notifications that we send you by email will also be changed.',
  language_des: 'The language of the software. The language of the email notifications that we send you will also be changed.',
  language_es: 'Spanish',
  language_en: 'English',
  delete_mp_cover_title: 'Do you want to delete this image?',
  delete_mp_cover_description: 'The header image for the meal plan PDF will be deleted. The default header image will be restored.',
  yes_delete_image: 'Yes, delete image',
  deleted_image_success: 'Image successfully deleted',
  deleted_image_error: 'There was an error while deleting the image',
  // BlockServiceHours
  available: 'Available',
  unavailable: 'Unavailable',
  blocked_future_hours: 'You have {{count}} blocked future hours',
  blocked_future_hours_other: 'You have {{count}} blocked future hours',
  drag_to_block: 'Drag the hours you want to block',
  block_hours: 'Block hours',
  block_service_hours_description:
    'Your patients will not be able to schedule appointments during the selected times. Ideal in case you have something to do and you will not be able to treat to anyone. In case you want to remove a blockage, simply click on the blocked area.',
  block_selected_hours: 'Block selected hours',
  delete_selected_block: 'Delete blocked hours',
  delete_selected_block_des:
    'By removing, you will re-enable the availability of the selected time, allowing patients to book appointments in that range.',
  // PopupAddPastAppointment
  height_v_min: 'The Height value seems to be very small, remember that it must be entered in cm',
  add_past_appointment_v_min: 'Please select at least one measurement to continue',
  add_more_past_appointments_title: 'Do you want to add more past measurements for this patient?',
  yes_add_more_past_measurements: 'Yes, add more past measurements',
  predictive_equation: 'Predictive equation',
  change_measurement_arguments: 'You can edit this measurement directly or by changing its arguments.',
  this_measurement_has_formula: 'This measurement has as formula {{- name}}.',
  the_following_equation: 'the following equation',
  the_equation_x: 'the {{- name}}',
  add_past_measurements: 'Add past measurements',
  add_measurements: 'Add measurements',
  do_it_here: 'do it here',
  registration_date: 'Registration date',
  past_appointment_v_unique: 'Records already exist on this date',
  measurements_taken: 'Measurements taken',
  enter_a_measurement: 'Enter a measurement',
  add_more_measurements: 'Add more measurements',
  // AppointmentsMeasurements
  today: 'Today',
  not_found_with_search: 'No results found for your search',
  no_measurements_found: 'No available measurements found',
  description: 'Description',
  bmi_classification: 'Body Mass Index (BMI) Classification',
  bmi_alert_description: 'You need to enter the weight and height of the patient to get the BMI.',
  last_two_appointment_measurements: 'These are the measurements that were registered during the last two appointments',
  appointment_measurements_select_placeholder: 'Select a measurement to enter its value',
  last_measurements: 'Last measurements',
  all_measurements: 'All measurements',
  measurements_of: 'Measurements of',
  all: 'All',
  last_3_appointments: 'Last 3 appointments',
  last_6_appointments: 'Last 6 appointments',
  last_9_appointments: 'Last 9 appointments',
  all_appointments: 'All the appointments',
  normal_values: 'Normal values',
  as_maximum: 'as maximum',
  as_minimum: 'as minimum',
  patient_inside_normal_range: 'The patient is within the normal range',
  patient_outside_normal_range: 'The patient is not within the normal range',
  normal_values_source: 'Source: MedlinePlus (U.S. National Library of Medicine) and Blufstein Labs',
  search: 'Search',
  clear: 'Clear',
  search_measurement: 'Search measurement',
  predictive_measurement: 'Predictive equation',
  predictive_measurement_is_output:
    'This measurement was calculated with the following equation. If you change the equation inputs it will automatically update.',
  calculated_value_is: 'Calculated value is',
  actual_value_cannot_backup: "Actual value won't be recovered",
  height_minimum_change: 'This values seems to be anormal, be sure to enter the height in cm',
  can_use_fat_percentage_equation: 'You can use the {{name}} to obtain the body fat percentage, which takes into account the following measurements:',
  can_use_next_equation: 'You can use the following equation to calculate this measurement:',
  predictive_measurement_could_update: 'This measurement can be updated automatically when you enter the measurements <1>{{name}}<1/>:', // needs fix
  of: 'of',
  of_the_following_equation: 'of the following equation',
  measurement_basic: 'Basic',
  measurement_basic_desc: 'e.g. Weight',
  measurement_skinFold: 'Skinfold',
  measurement_skinFold_desc: 'e.g. Abdominal skinfold',
  measurement_diameter: 'Width',
  measurement_diameter_desc: 'e.g. Elbow width',
  measurement_girth: 'Circumference',
  measurement_girth_desc: 'e.g. Hip circumference',
  measurement_analytical: 'Analytic',
  measurement_analytical_desc: 'e.g. HDL Cholesterol',
  measurement_bodyIndex: 'Body Index',
  measurement_bodyIndex_desc: 'e.g. Body fay percentage',
  measurement_lengths: 'Length/height',
  measurement_lengths_desc: 'e.g. Acromion-Radial',
  apply: 'Apply',
  // UpgradeSubscription
  upgrade_subscription_hoc_title: 'This feature is not available for the {{planName}}',
  upgrade_subscription_hoc_description: 'Upgrade your plan to enjoy it :)',
  upgrade_my_plan: 'Upgrade my plan',
  //
  // SMT
  //
  // AppointmentMealPlan
  mealPlan_for_firstName_lastName: 'Meal plan for <1>{{firstName}}</1> <2>{{lastName}}</2>',
  mealPlan_how_to_complete: 'You will be able to finish meal plans during the appointment or later. Find them at "Meal plans" on the sidebar.',
  // ErrorNotification
  error_notification_description: 'It looks like there was an issue. Please try again in few moments',
  // ExpertVerificationAlert
  resend_email_confirmation: 'We just resent you the confirmation email',
  resend_email_confirmation_error: 'Oops! Looks like there was an issue sending the email, try again in a few moments.',
  expert_verification_alert_message:
    "Check your email! We send you a confirmation email to <strong>{{email}}</strong>. You will be able to log into your account until <strong>{{-date, DD/MM/YYYY h:mmA}}</strong> with an unverified email. Remember to check your spam and promotions folders in case you can't find it.",
  expert_verification_alert_text: 'day left',
  expert_verification_alert_text_other: 'days left',
  // ExpertPasswordChange
  // PatientMain
  redirecting_to_app_store: 'Redirecting to the App Store',
  redirecting_to_google_play: 'Redirecting to the Google Play',
  download_the_app_patient_main: 'Download the App',
  download_the_app_patient_main_subtitle: 'You will be able to schedule appointments, see your meals, progress, recipes and much more',
  // PatientAuthToken
  okText_verifying: 'Verifying...',
  patient_auth_token_modal_title: 'Please wait a few seconds',
  patient_auth_token_modal_content: "We're checking the link",
  patient_auth_token_modal_success_title: 'Great!',
  patient_auth_token_modal_success_subtitle: 'Your account has been successfully verified. With Almendra you will be able to:',
  patient_auth_token_modal_success_li_1: 'Visualize your progress',
  patient_auth_token_modal_success_li_2: 'Check your record',
  patient_auth_token_modal_success_li_3: 'Follow your meal plans on real time',
  patient_auth_token_modal_success_li_4: 'Schedule appointments with your professional',
  patient_auth_token_modal_success_li_5: 'Achieve your goals way easier',
  patient_auth_token_create_account_success: 'Your account was successfully created!',
  patient_auth_token_password_change_success: 'Your password was successfully changed!',
  patient_auth_token_expert_change_success: 'Your professional was successfully changed!',
  patient_auth_token_password_onBoarding_title: 'On Almendra you will be able to:',
  patient_auth_token_content_title: 'Welcome {{ patientName }}!',
  patient_auth_token_content_subtitle: 'Start by creating your password',
  patient_auth_token_type_password: 'Enter your new password',
  patient_auth_token_type_password_again: 'Enter your password again',
  patient_auth_token_validation_lowercase: 'At least one lower case letter',
  patient_auth_token_validation_uppercase: 'At least one upper case letter',
  patient_auth_token_validation_characters: 'At least 8 characters',
  patient_auth_token_validation_number: 'At least a number',
  patient_auth_token_validation_passwordMatch: 'Passwords match',
  patient_auth_token_cta: 'Create password',
  // PaymentForm
  payment_form_show_error_message: 'Invalid payment method',
  payment_form_show_error_description: "Don't worry, no payment was done. Please ensure you typed the correct information",
  // PasswordReset
  password_reset_success_title: 'Done!',
  password_reset_success_content: 'We just sent you an email with the steps to follow to reset your password',
  password_reset_container_title: 'Reset your password',
  password_reset_container_subheader: 'Enter your email to reset your password',
  password_reset_email_label: 'Email',
  password_reset_email_helperText: 'Enter a valid email',
  password_reset_button_text: 'Send email',
  password_reset_backToLogin: 'Go back to Login',
  // RecipeCard
  recipe_card_servings_text: '{{count}} serving',
  recipe_card_servings_text_other: '{{count}} servings',
  // ActivityLoader
  activity_loader_first_line: 'This is taking longer than usual',
  activity_loader_second_line: 'Please ensure you have a good internet connection.',
  activity_loader_cta: 'Refresh page',
  // DesktopRequiredModal
  desktop_required_modal_title: 'Enhance your experience',
  desktop_required_modal_content_1:
    'To ensure you have the best experience with Almendra, <1>we would prefer you to create your meal plans with a laptop or tablet.</1>',
  desktop_required_modal_content_2: 'You can find this meal plan in the pending meal plans section <1> (/plans) </1>once you log in from your laptop',
  desktop_required_modal_backToHome: 'Go back to /home',
  // PatientsByAge
  patients_by_age_bar_label: 'Patients by age',
  // PatientsBySource
  patients_by_source_bar_label: 'Patients by source',
  // PatientsByTags
  patients_by_tags_bar_label: 'Patients by tag',
  // RevenueGraph
  revenue_graph_data_label: 'Revenues',
  // TextAreaEditor
  text_area_editor_loading: 'Loading...',
  text_area_editor_modal_title: "Check the email's content",
  text_area_editor_conflicts: "We found {{count}} conflict with the email's variables.",
  text_area_editor_conflicts_other: "We found {{count}} conflicts with the email's variables.",
  text_area_editor_conflicts_content_1:
    "Ensure you typed correctly the variable's name. This usually happens when you put between curly braces <1>'{ }'</1> only a part of the variable's name.",
  text_area_editor_conflicts_content_2: "Fix these issues so your patients can properly visualize the email's content.",
  // Editor
  editor_heading_label: 'Heading',
  editor_paragraph_label: 'Paragraph',
  editor_undo_label: 'Undo',
  editor_redo_label: 'Redo',
  editor_styles_label: 'Styles',
  editor_bold_label: 'Bold',
  editor_italic_label: 'Italic',
  editor_strike_label: 'Strikethrough',
  editor_highlight_label: 'Highlight',
  editor_bullet_label: 'Bullets list',
  editor_order_label: 'Ordered list',
  editor_horizontal_label: 'Horizontal line',
  editor_color_label: 'Color',
  editor_insert_image: 'Insert image',
  editor_insert_image_src_label: 'Link',
  editor_insert_image_src_message: 'Enter the image link.',
  editor_insert_image_height_label: 'Height',
  editor_insert_image_height_message: 'Enter the height of the image (number, px or percentage).',
  editor_insert_image_width_label: 'Width',
  editor_insert_image_width_message: 'Enter the width of the image (number, px or percentage).',
  editor_insert_image_close: 'Close',
  editor_insert_image_submit: 'Insert',
  // AppointmentPDF
  appointment_pdf_title: 'Appointment report',
  // ApointmentReport
  appointment_report_image_error: "We couldn't load the image",
  appointment_report_filename: 'Appointment report - {{firstName}} {{lastName}}.pdf',
  appointment_report_download_modal_title: 'You need at least one measurement to download the PDF',
  appointment_report_download_modal_okText: 'Ok',
  appointment_report_download_modal_loading: 'Downloading...',
  appointment_report_download_modal_loading_title: 'Downloading appointment report',
  appointment_report_download_modal_loading_content: 'This could take a few seconds.',
  appointment_report_download_modal_error_title: 'There was an error generating the report',
  appointment_report_download_modal_error_content: 'Please refresh the page and try again',
  appointment_report_options_1: 'PDF options',
  appointment_report_options_2: 'Download PDF',
  appointment_report_options_2_title: 'You need at least one measurement to download the PDF',
  appointment_report_options_3: 'Send via email',
  appointment_report_button: 'Appointment report (PDF)',
  appointment_report_settings_title: 'Customize the report your way',
  appointment_report_settings_okText: 'Save',
  appointment_report_settings_cancelText: 'Cancel',
  appointment_report_settings_comments: 'Appointment comments',
  appointment_report_settings_imcChart: 'IMC Chart',
  appointment_report_settings_watermark: 'Watermark',
  appointment_report_settings_checked: 'Yes',
  appointment_report_settings_unchecked: 'No',
  // IndicationsTemplateCard
  indications_template_card_modalDelete_title: 'Do you want to delete this template?',
  indications_template_card_modalDelete_content: 'Remember that this action is irreversible',
  indications_template_card_modalDelete_cancelText: 'No',
  indications_template_card_modalDelete_okText: 'Yes, delete',
  indications_template_card_modalDelete_success: 'Indications template successfully deleted',
  indications_template_card_delete: 'Delete',
  indications_template_card_indications: 'Indications:',
  // MealPlanChart
  meal_plan_chart_target: 'Target: ',
  meal_plan_chart_actual: 'Current: ',
  meal_plan_chart_difference: 'Difference',
  meal_plan_chart_surplus: 'Surplus',
  meal_plan_chart_deficit: 'Deficit',
  // IndicationsTemplatesMain
  indications_templates_main_title: 'Indications templates',
  indications_templates_main_system: 'System templates',
  // DeletePastAppointment
  delete_past_appointment_modal_title: 'Do you want to delete this appointment?',
  delete_past_appointment_modal_content: 'This appointment has been scheduled but has not been started yet. Would you like to delete it anyways?',
  delete_past_appointment_okText: 'Yes, delete appointment',
  // RecipesHome
  recipes_home_category: 'Category: ',
  recipes_home_preparation: 'Preparation: ',
  recipes_home_cookingTime: 'Cooking time: ',
  recipes_home_difficulty: 'Difficulty: ',
  recipes_home_noRecipes: "We couldn't find any recipe",
  recipes_home_public_card_desc: "You will soon be able to see {{ firstName }}'s recipes",
  recipes_home_showing_recipes: 'Showing {{count}} recipe',
  recipes_home_showing_recipes_other: 'Showing {{count}} recipes',
  recipes_home_school: 'Registration number: ',
  about_me: 'About me',
  recipes_home_Fácil: 'Easy',
  recipes_home_Medio: 'Medium',
  recipes_home_Difícil: 'Hard',
  // MetsSelectInput
  mets_select_input_error: 'Please enter a value or remove the activity',
  every_day: 'Every day',
  mets_select_input_select_days: 'Select days',
  // VerifyAccount
  check_your_email: 'Check your email',
  verify_account_content_1: 'Follow the instructions sent to <1>{{mail}}</1> to start using Almendra.',
  verify_account_close: 'You can now close this page',
  verify_account_did_not_receive: "You haven't received the email? Check your Spam and Promotions inboxes",
  // ExpertWorkAddress
  delete_address_from_business_hour:
    'This address will be removed from your business hours settings (in <1> Settings > General Settings > Business hours </1>)',
  expert_work_address_warning: 'Please ensure the address has at least 10 characters',
  expert_work_address_error: 'An error ocurred when performing that action. Please try again.',
  expert_work_address_newAddress_warning: 'Please fill the address before adding a new one',
  expert_work_address_deleteModal_title: "You're about to delete a work address that has been used on your working hours",
  expert_work_address_deleteModal_okText: 'Yes, delete',
  expert_work_address_deleteModal_cancelText: 'Cancel',
  expert_work_address_deleteModal_confirm: 'Do you want to delete this work address?',
  expert_work_address_main_address: 'Main address',
  expert_work_address_placeholder: 'E.g: Almendra Avenue 123, San Francisco, CA',
  expert_work_address_alt_address: 'Secondary address',
  expert_work_address_add_address: 'Add new address',
  // PopupAddMeal
  popup_add_meal_confirmError: 'Select the meal you would like to add',
  popup_add_meal_addButton: 'Add meal',
  popup_add_meal_addButton2: 'Meal',
  popup_add_meal_modal_title: 'What meal would you like to add?',
  popup_add_meal_addToDay: 'The meal will be added on {{currentDayName}}',
  popup_add_meal_addToEveryDay: 'The meal will be added on every day',
  popup_add_meal_time: 'At what time will it be?',
  popup_add_meal_warning_1: 'The days that have caloric targets by meal time will now have only daily targets',
  popup_add_meal_warning_2: 'You can return to the previous step to set caloric targets by meal time on those days.',
  // PopupEditBanner
  edit_banner_load_error: 'There was an error uploading the image',
  edit_banner_button: 'Change image',
  edit_banner_modal_title: 'Configure your personal page',
  edit_banner_okText: 'Confirm changes',
  edit_banner_cta: 'Change background image',
  edit_banner_modal_subtitle: 'Current banner',
  // PopupSubscriptionEnded
  popup_subscription_ended_modal_title_1: 'Your free trial has ended',
  popup_subscription_ended_modal_title_2: 'Your subscription period has ended',
  popup_subscription_ended_modal_content: 'Your subscription to the {{planName}} plan has come to an end',
  popup_subscription_ended_modal_li_1: 'There was an issue on your recurring payment on',
  popup_subscription_ended_modal_li_2: 'Your subscription period has ended',
  popup_subscription_ended_modal_li_3: "You canceled your subscription's auto renewal",
  popup_subscription_ended_preCTA: 'Renew your subscription to keep using Almendra',
  popup_subscription_ended_cta: 'Choose my plan',
  // ExchangeTableMealPlan
  exchange_table_mealplan_warning: "Enter the meal plan's name",
  exchange_place_food: 'Place food',
  exchange_place_group: 'Place group',
  exchange_view_food: 'View foods',
  exchange_view_group: 'View groups',
  // DownloadPatientDataXLSX
  extraComment: 'General comment',
  arrivalSource: 'Arrival source',
  intestinalConditionComments: 'Intestinal condition comments',
  mail: 'Email',
  download_patient_data_xlsx_filename: 'Data - {{firstName}} {{lastName}}.xlsx',
  field: 'Field',
  location_only: 'Location',
  download_patient_data_xlsx_noAppointments: 'No finished appointments were found for this patient',
  successful_download: 'Downloaded successfully',
  download_patient_data_xlsx_cta: 'Download data in Excel',
  // PatientFoodFrequency
  patient_food_frequency_modal_title: 'Add any food group you wish',
  patient_food_frequency_placeholder: 'E.g: Protein supplements',
  add: 'Add',
  patient_food_frequency_error_1: "Please enter the group's name",
  patient_food_frequency_error_2: 'A group with this name already exists',
  patient_food_frequency_modal_title_2: 'Food frequency form',
  patient_food_frequency_button: 'Food groups',
  action: 'Action',
  frequency: 'Frequency',
  patient_food_frequency_popconfirm: 'Do you want to delete this category?',
  times_per_day: 'time per day',
  times_per_day_other: 'times per day',
  times_per_week: 'time per week',
  times_per_week_other: 'times per week',
  times_per_month: 'time per month',
  times_per_month_other: 'times per month',
  patient_food_frequency_emptyText: 'Start adding food groups by clicking the (+) button above',
  patient_food_frequency_main_button: 'Food frequency form',
  // PatientInformationProperties
  maritalStatus: 'Marital status',
  lifeStyle: 'Lifestyle',
  healthConditions: 'Health conditions',
  actualDietType: 'Diet type',
  dietSpecialConditions: 'Special diet conditions',
  actualMealsPerDay: 'Meals per day',
  actualWaterConsumption: 'Water consumption (oz)',
  previousMealPlan: 'Previous meal plan',
  previousMealPlanCreator: 'Previous meal plan made by',
  pastDietType: 'Previous meal plan type',
  physicalActivity: 'Physical activity',
  dailyPhysicalActivity: 'Physical activity level',
  physicalActivityDiscipline: 'Disciplines - Physical activity',
  sleepTime: 'Bed time',
  wakeUpTime: 'Wake up time',
  sleepDuration: 'Sleep duration',
  sleepDuration_question: 'How many hours of sleep?',
  sleepDuration_placeholder: 'Ex: 7',
  dream: 'Sleep',
  sleepQuality: 'Sleep quality',
  alcoholConsumptionFrequency: 'Alcohol consumption frequency',
  alcoholTypes: 'Alcohol types',
  smokingFrequency: 'Smoking frequency',
  coffeeConsumptionFrequency: 'Coffee consumption frequency',
  supplements: 'Supplements',
  stressLevel: 'Stress level',
  stressCause: 'Stress cause',
  actualMotivation: 'Current motivation',
  generalObjective: 'General goal',
  intestinalCondition: 'Intestinal condition',
  intestinalConditionPlaceholder: 'Select the intestinal condition',
  actualMedicineConsumption: 'Current medicine consumption',
  drugAllergies: 'Drug allergies',
  currentDiseases: 'Current diseases',
  personalDiseaseBackground: 'Personal disease background',
  personalDiseaseBackgroundComments: 'Personal disease background comments',
  familyDiseaseBackground: 'Family disease background',
  familyDiseaseBackgroundComments: 'Family disease background comments',
  specialConditions: 'Special conditions',
  dislikedFoods: 'Disliked foods',
  allergies: 'Allergies',
  dietRecord: 'Dietary record',
  // General Objective
  general_objective_placeholder: 'E.g: Run a marathon, have a better relationship with food, etc.',
  general_objective_button: 'Add another goal',
  // PopupNewMealPlanTemplate
  popup_new_meal_plan_template_buttonQuick: 'Create template',
  popup_new_meal_plan_template_button: 'Create meal plan template',
  popup_new_meal_plan_template_modal_title: 'Create meal plan template',
  popup_new_meal_plan_template_modal_divider: 'Meal plan template name',
  popup_new_meal_plan_template_modal_message: 'Enter the name of the template',
  // HealthPlanCard
  public_view: 'Public',
  private_view: 'Private',
  created_on: 'created on {{-date, MM/DD/YYYY}}',
  health_plan_card_default_single:
    'This is a "regular" appointment. To edit it click on the three dots in the upper right hand corner. When you schedule appointments, you will have the option to asign a health plan or just a regular appointment. Any doubts? Send us an email to soporte@almendra.io and we will help you.',
  health_plan_card_modal_title: 'Do you want to delete this health plan?',
  health_plan_card_modal_content: 'This action is irreversible. If you click delete the health plan will be permanently deleted',
  ready: 'Done!',
  health_plan_card_message_description: 'Health plan deleted',
  health_plan_card_delete_action: 'Delete health plan',
  health_plan_card_div_1: 'Included appointments: ',
  health_plan_card_div_2: 'Total price: ',
  health_plan_card_div_3: 'Visibility: ',
  health_plan_card_desc: 'Health plan description:',
  health_plan_card_current_patients: 'Currently subscribed patients',
  health_plan_card_times_purchased: 'Times purchased',
  // PopupNewExpenses
  popup_new_expenses_msg_success: 'Expense was entered successfully',
  popup_new_expenses_msg_error: 'There was an error while saving the expense',
  popup_new_expenses_button: 'Add expense',
  popup_new_expenses_modal_title: 'Add extra expense',
  popup_new_expenses_label_1: 'Expense name',
  popup_new_expenses_message_1: 'Enter the name of the expense',
  popup_new_expenses_placeholder_1: 'E.g: Rent',
  popup_new_expenses_label_2: 'Expense amount',
  popup_new_expenses_message_2: 'Enter the amount of the expense',
  popup_new_expenses_placeholder_2: 'E.g: 100',
  popup_new_expenses_label_3: 'Expense category',
  popup_new_expenses_message_3: 'Enter the category of the expense',
  popup_new_expenses_placeholder_3: 'E.g: Work accessories',
  popup_new_expenses_initial_date_recurrent: "Expense's initial date",
  popup_new_expenses_initial_date: "Expense's date",
  popup_new_expenses_initial_date_placeholder: "Select the expense's date",
  popup_new_expenses_label_4: 'Is it a recurring expense?',
  popup_new_expenses_message_4: 'Enter the recurrency of the expense',
  popup_new_expenses_label_5: 'Recurrency',
  popup_new_expenses_message_5: 'Enter a number',
  popup_new_expenses_message_6: 'Select a period',
  popup_new_expenses_label_7: 'The expense has an end date?',
  popup_new_expenses_message_7: "Select the expense's end date",
  // PopupEquivalentsGroupSettings
  popup_equivalents_group_settings_tooltip: 'Group options',
  popup_equivalents_group_modal_title: 'Exchange meal plan options',
  popup_equivalents_group_label_1: 'Show as default',
  popup_equivalents_group_extra_1: "When you create exchange meal plans this group will be added as default so it's faster for you",
  popup_equivalents_group_extra_2: 'Select the category which resembles the most to this equivalents group',
  popup_equivalents_group_extra_3:
    'You can assign a color to this group so your patients can easily locate it in their meal plan. If you do not want to choose a color, <1>click here</1> and we will assign it a random color.',
  selected_color: 'Selected color:',
  no_color_assigned: 'You have not assigned a color',
  // AddExchangeRecipe
  add_exchange_recipe_button: 'Place recipes',
  add_exchange_recipe_showing: 'Showing {{count}} recipes',
  add_exchange_recipe_load_more: 'Load more recipes',
  add_exchange_recipe_no_recipes: 'No recipes to show',
  add_exchange_recipe_info: 'Click on a recipe to see more info',
  add_exchange_recipe_no_load: "The recipe's information couldn't be loaded",
  no_content: 'No content',
  // PopupUploadMealPlanPDF
  popup_upload_meal_plan_pdf_emptyMsg: 'Attach a PDF to send it',
  popup_upload_meal_plan_pdf_upload: 'PDF file attached',
  popup_upload_meal_plan_pdf_upload_error: 'There was an error when uploading the PDF',
  popup_upload_meal_plan_pdf_upload_warning: "There's no PDF file attached",
  popup_upload_meal_plan_pdf_button: 'Attach your meal plan as PDF',
  popup_upload_meal_plan_pdf_tooltip: 'If you already have a PDF and do not want to create the meal plan in Almendra, you can attach it',
  popup_upload_meal_plan_pdf_modal_title: 'Meal plan attached as PDF',
  meal_plan_name: 'Meal plan name',
  popup_upload_meal_plan_pdf_button_2: 'Attach your meal plan',
  // PopupAppInvitation
  cool: 'Cool!',
  popup_app_invitation_success_title: 'This patient already has access to Almendra App',
  popup_app_invitation_error_1: "There's already a patient with that email.",
  popup_app_invitation_error_2: "The email couldn't be saved, please try again",
  save_email: 'Save email',
  popup_app_invitation_modal_title: 'Send the access email to the app?',
  popup_app_invitation_modal_content_1:
    "The access email serves as the way to activate the patient's Almendra account, where he will be able to check his meal plan, recipes, schedule appointments, and many other things",
  popup_app_invitation_modal_content_2: 'An email will be sent to <1>{{mail}}</1> so <3>{{patientName}}</3> enters his Almendra account.',
  save_and_send_confirmation_email: 'Save email and send confirmation',
  send_access_email: 'Send App access email',
  popup_app_invitation_div_1: 'Recommended option',
  popup_app_invitation_div_2: 'In case you only want to save the email, you can click the "Save email" button.',
  // FinancialsSummary
  show: 'Show',
  expenses_cap: 'Expenses',
  income_cap: 'Revenue',
  in_this_month: 'during this month',
  in_this_year: 'during this year',
  in: 'in',
  last_month: ' last month', //ojo al espacio, debe estar
  last_year: ' last year', //ojo al espacio, debe estar
  financials_summary_h3: "You haven't entered any revenues yet! They will appear as soon as you do an appointment or manually register one",
  since_you_joined_Almendra: 'since you joined Almendra',
  financials_summary_tooltip: 'Net revenue refers to the sum of your revenues minus the expenses of the selected period',
  net_income: 'Net revenue',
  net_loss: 'Net loss',
  financials_summary_tooltip_2: 'Net loss refers to the sum of your revenues minus the expenses of the selected period',
  financials_summary_statistic_title: '',
  last_week: 'last week',
  financials_summary_tooltip_3: 'The increase means in what percentage you improved your net revenue vs the last period',
  financials_summary_tooltip_4: 'The decrease means in what percentage you improved your net revenue vs the last period',
  financials_summary_statistic_title_2: '',
  // RecipesMain
  pending_recipe_load_failed: 'An error ocurred while loading your pending recipes',
  recipe_load_failed: 'An error ocurred while loading your recipes',
  recipes_main_pending_div: 'You have {{count}} {{countText}} to complete',
  recipes_main_pending_div_countText: 'pending recipe',
  recipes_main_pending_div_countText_other: 'pending recipes',
  reset_filters: 'Reset filters',
  searching_recipes: 'Searching recipes...',
  no_recipes_found: 'No recipes were found',
  recipes_main_loading_more: 'Loading more recipes...',
  recipes_main_showing: 'Showing {{count}} recipe',
  recipes_main_showing_other: 'Showing {{count}} recipes',
  // ExpertPasswordChange
  wrong_password: 'Wrong password',
  expert_password_change_success_message: 'Your password has been successfully modified',
  expert_password_change_success_desc: 'We remind you not to share your passwords with anybody :)',
  expert_password_change_error_message: "Your password couldn't be modified",
  expert_password_change_error_desc: 'Please try again later',
  expert_password_change_compare: 'The entered passwords do not match',
  current_password: 'Current password',
  enter_current_password: 'Enter your current password',
  change_password: 'Change password',
  // RecipesFilters
  make_search_query: 'Search',
  'Mis recetas': 'My recipes',
  'Todas las recetas': 'All the recipes',
  search_filters: 'Query filters',
  recipes_filters_title: 'Search recipes by criteria',
  recipes_filter_placeholder_1: 'E.g: Tuna salad',
  minimum: 'Minimum',
  maximum: 'Maximum',
  my_recipes: 'My recipes',
  all_recipes: 'All recipes',
  // PopupCopyDay
  popup_copy_day_toggle_day: 'If you want to select {{dayName}}, first unset the day off',
  popup_copy_day_modal_title_1: 'Copy day',
  popup_copy_day_modal_title_2: 'with equivalences',
  popup_copy_day_text:
    'Select the days to which you want to copy the foods from {{originCopyDayName}} and click on Confirm. If a day to where you want to copy the foods already have foods, these will be replaced. This action is irreversible.',
  popup_copy_day_alert: "Equivalent foods will be placed on the target days. If a food doesn't have any equivalents, the same food will be placed.",
  // PopupCopyExchangeDay
  popup_copy_exchange_day_warning: 'Select at least one day to replace the content',
  popup_copy_exchange_day_content_1: 'Select the days where you want to copy the content from <1>{{currentDayName}}</1>.',
  popup_copy_exchange_day_content_2: 'The following information will be replaced:',
  popup_copy_exchange_day_content_3: "Meals' exchange groups",
  popup_copy_exchange_day_content_4: "Meals' options",
  popup_copy_exchange_day_content_5: "Meals' hours",
  popup_copy_exchange_day_content_6: "Meals' comments",
  // OnBoardingGoToTour
  creating_meal_plan: 'Creating a meal plan',
  start_tour: 'Start tour',
  tour_completed: 'Tour completed',
  continue_tour: 'Continue with the tour',
  // DisciplineCardsMain
  disciplinaCards_examples: 'e.j Yoga, Hiking, Swimming etc.',
  already_exists: 'An activity with that name already exists',
  new_discipline_error: 'Add a valid activity',
  delete_discipline: 'Would you like to delete this discipline?',
  as_a_discipline: 'as a discipline',
  sport: 'Sport',
  sport_other: 'Sports',
  // PatientProfile
  personal_data: 'Personal data',
  actual: 'current',
  custom_fields_profile: 'Custom fields',
  custom_fields_setup: 'Manage fields',
  message_error_save: 'There was an error while saving the changes',
  message_error_save_schema: "We couldn't save this field: the entered format is invalid",
  patient_profile_no_delete_title: "You can't delete this patient yet",
  patient_profile_no_delete_content: 'To delete this patient you need to finish all the onboarding tours',
  patient_profile_no_delete_title_2: 'This patient is on an ongoing appointment',
  patient_profile_no_delete_content_2: 'To delete this patient there must not be an ongoing appointment',
  patient_profile_delete_title: 'Do you want you delete this patient?',
  patient_profile_delete_div_1:
    'This action is irreversible. If you click on delete, the patient will be permanently deleted, as well as all of his data (appointments, meal plans, measurments, etc)',
  patient_profile_delete_div_2:
    'We suggest not to delete a patient because all of his data will be deleted. For example, you willnot be able to count your revenue generated by that patient.',
  patient_profile_delete_desc_1: 'You deleted',
  patient_profile_delete_desc_2: 'We hope he comes back soon!',
  error_deleting_patient: 'There was an error deleting this patient, please try again in a few minutes',
  firstName_example: 'E.g: Alex',
  lastName_example: 'E.g: Smith',
  idNumber_example: 'E.g: 71234567',
  occupation_example: 'E.g: Plumber',
  phone_example: 'E.g: (305) 981-5529',
  stats: 'Stats',
  time_as_patient: 'Time as patient',
  assigned_mealPlans: 'Assigned meal plans',
  income_generated: 'Revenue generated\nby this patient',
  appointments_made: 'Appointment performed',
  appointments_made_other: 'Appointments made',
  assistance_ratio: 'Appointment assistance\nratio',
  average_appointment_time: 'Average time\nof appointment',
  goal: 'Goal',
  goal_other: 'Goals',
  patient_wants_to: '{{name}} wants to',
  current_goal: 'Current goal',
  stress: 'Stress',
  reason: 'Cause',
  reason_other: 'Causes',
  hour_placeholder_am: 'E.g: 6:30am',
  hour_placeholder_pm: 'E.g: 10:00pm',
  other_consumptions: 'Other consumptions',
  alcohol: 'Alcohol',
  alcohol_types: 'Alcohol types',
  alcohol_placeholder: 'E.g: Beer',
  consumption_frequency: 'Consumption frequency',
  tobacco: 'Tobacco',
  coffee: 'Coffee',
  supplement_types: 'Supplement types',
  supplement_placeholder: 'E.g: Creatine',
  food_registry: 'Food registry',
  nutrition_patient_info: 'Nutrition information',
  section_not_viewed_by_patient: 'This section will not be viewed by your patient',
  actualMealsPerDay_placeholder: 'E.g: 3',
  has_done_previous_mealplan: 'Has he/she has a diet previously?',
  previous_mealplan_type: 'Type of previous meal plan',
  previous_mealplan_type_placeholder: 'E.g: Keto, carb free, paleo...',
  previous_mealplan_author: 'Who made it?',
  previous_mealplan_author_placeholder: 'E.g: A dietitan, saw it on Instagram, a friend gave it to me',
  consumption_frequency_form_extra: 'Food groups can be modified in the Settings section',
  currentDiseases_can_add: 'You can add diseases that are not on the list',
  currentDiseases_placeholder: 'E.g: Hypothyroidism',
  actualMedicineConsumption_placeholder: 'E.g: Paracetamol',
  drugAllergies_placeholder: 'E.g: Zanax',
  personalDiseaseBackground_placeholder: 'E.g: Gastritis',
  intestinalCondition_select: 'Select the intestinal condition',
  patient_food_liked_enter: 'Enter the favorite foods',
  patient_food_unliked_enter: 'Enter the unliked foods',
  patient_food_allergies_enter: 'Enter the foods to which the patient is allergic to',
  comments_and_others: 'Comments and others',
  patient_address: "Patient's address",
  patient_source: 'How did the patient get to you?',
  patient_images: "Patient's pictures",
  modifying_measurements: 'Modifying your measurements',
  // PopupBookingSettings
  popup_booking_settings_modal_title: 'You are about to deactivate an appointment mode that has been displayed on your work hours.',
  popup_booking_settings_modal_content:
    'This appointment mode will be deleted from your business hours (on <1>{"Settings > General Settings > Business Hours"}</1>).',
  popup_booking_settings_success_desc: 'The online booking system was activated',
  popup_booking_settings_error: 'There was an error activating the online booking system',
  popup_booking_settings_warning: "You haven't finished setting up your booking system",
  popup_booking_settings_content_warning_1: 'Having configured your business hours<1/><2>(step 2)</2>',
  popup_booking_settings_content_warning_2: 'Having at least 1 active service<1/><2>(step 1)</2>',
  online_booking_system: 'Online booking system',
  activate_online_booking_system: 'Activate online booking system',
  customize_your_booking_system: 'Customize your booking system',
  allow_anyone_to_book: 'Allow anyone to book appointments',
  anyone_can_book: 'Current patients and non patients can book appointments with you',
  only_patients_can_book: 'Only current patients can book appointments with you',
  services_to_show: 'Which services would you like to show?',
  popup_booking_settings_where_services: 'You can create or edit your services in the section ',
  popup_booking_settings_activate_one: 'Activate at least 1 service to use the online booking system',
  configure_business_hours: 'Configure your business hours',
  popup_booking_settings_create_one: 'Create at least 1 service to use the online booking system',
  // RecoverMealPlanVersionModal
  mealPlan_old_version_title: 'We found an unsaved version of this meal plan',
  mealPlan_old_version_content_0:
    'An unsaved version was found whose last update date was <1> {{- unsavedDate, MM/DD/YYYY, h:mm:ss a}}</1>. Whereas the last saved version of the meal plan was the <3>{{- lastUpdatedAt, MM/DD/YYYY, h:mm:ss a}}</3>.',
  mealPlan_old_version_content_1: 'Which version do you want to keep?',
  last_saved_version: 'Last saved version',
  unsaved_version: 'Unsaved version',
  use_this_version: 'Use this version',
  most_used_option: 'Most used option',
  without_meals: 'Without meals',
  without_foods: 'Without foods',
  without_groups: 'Without groups',
  without_menu: 'Without menu',
  without_options: 'Without options',
  // NutrientAnalysis
  analysis_per_nutrient: 'Nutrient analysis',
  select_nutrient: 'Select nutrient',
  total_of_day: 'Total of day',
  of_the_day: 'of day',
  options_average: 'Options average',
  options_average_tooltip: 'It is calculated as the sum of all the options between the number of options',
  options_total_tooltip: 'It is calculated as the sum of all option averages',
  total_of_meal: 'Total of meal',
  options_total_meal_exchange_tooltip: 'It is calculated as the sum of the average of all groups',
  // MealFoodInformation
  view_micros: 'Micros',
  view_micronutrients: 'View micronutrients',
  meal_food_information_modal_title: 'Micronutrients analysis',
  meal_food_information_modal_source_warning: 'By using foods that do not belong to the USDA, results might not be completely accurate',
  recommendations_institute_of_medicine: 'Recomendations by the Institute of Medicine, USA.',
  meal_food_information_view_recommendations: "To get the recommendations enter the patient's birth date.",
  // OnBoardingMainSection
  the_first_step: 'The first step',
  discount_obtained: 'You earned this discount!',
  complete_every_item: 'Complete every item on this section to earn the discount',
  discount_percent: '5% discount',
  onboarding_main_section_tooltip: 'Every single user that made the training cleared his doubts and could start with Almendra faster and easier',
  go_to_article: 'Go to article',
  // PreciseObjectiveProgress
  precise_objective_progress_modal_title: 'Specific goal progress',
  // EditSocialNetworks
  set_social_networks: 'Set social networks',
  edit_social_networks_modal_title: 'Set up your social networks',
  show_whatsapp: 'Show Whatsapp',
  enabled: 'Enabled',
  disabled: 'Disabled',
  contact_message_whatsapp: 'Initial message',
  edit_social_networks_placeholder: 'E.g: ¡Hi {{firstName}}! I am interested on your services. I would like to book an appointment please.',
  facebook_valid_link: 'Enter a valid Facebook link',
  twitter_valid_link: 'Enter a valid Twitter link',
  instagram_valid_link: 'Enter a valid Instagram link',
  linkedin_valid_link: 'Enter a valid Linkedin link',
  youtube_valid_link: 'Enter a valid Youtube link',
  // PopupAllocatePurchasedHealthPlan
  healthplan_successfully_assigned: 'Health plan successfully assigned',
  assign_healthplan_appointment_in_progress: 'To assign the health plan to a patient first finish the current appointment',
  healthplan_selection_message: 'The prices includes {{count}} appointment',
  healthplan_selection_message_other: 'The prices includes {{count}} appointments',
  assign_healthplan_modal_title: 'Assign new service',
  assign_healthplan: 'Assign service',
  no_healthplans_yet: "You haven't created any service yet",
  create_your_first_healthplan: 'Create your first healthplan on <1>Settings > Services</1>',
  go_to_healthplans: 'Go to services',
  booking_no_charge: 'People that book through online booking will not be asked to pay at the moment of the booking',
  booking_charge: 'People that book through online booking will be asked to pay at the moment of the booking',
  request_payment: 'Request payment',
  not_request_payment: "Don't request payment",
  // MercadoPagoLinkAccount
  mercado_pago_link_account_modal_title: 'Do you want to link your MercadoPago account to Almendra?',
  mercado_pago_link_account_modal_content_1: 'People will be able to pay for your services when booking them through "/booking"',
  mercado_pago_link_account_modal_content_2:
    'Before linking your account with MercadoPago and starting to receive payments, please ensure your MercadoPago account has the same currency that your Almendra account:',
  mercado_pago_link_account_modal_content_3: 'We will redirect you to MercadoPago so you can perform this action.',
  mercado_pago_link_account_modal_content_4:
    '<0>Note: </0> MercadoPago charges a fee for their servivce. Click <2>here</2> for more information. Almendra does not charge any additional fee.',
  mercado_pago_link_account_error: 'There was an error trying to reach the MercadoPago server, please try again later.',
  mercado_pago_link_account_modal_unlink_title: 'Do you want to unlink your MercadoPago account to Almendra?',
  mercado_pago_link_account_modal_unlink_content:
    'We remind you that every payment option on your account will be disabled. No one will be able to pay you online after you confirm this action',
  // PatientsPicturesWall
  fill_every_field: 'Please fill every field',
  image_delete_title: 'Do you want to delete this image?',
  edit_image: 'Edit image',
  new_image: 'New image',
  change_image: 'Change image',
  date_format_string: 'MM/DD/YYYY', //usado para placeholders y props
  // PopupEditHealthPlan
  new_healthplan: 'New service',
  edit_healthplan: 'Edit service',
  create_healthplan: 'Create service',
  edit_single_appointment: 'Edit single appointment',
  popup_edit_healthplan_name_message: 'Enter the name of the service',
  popup_edit_healthplan_price_message: 'Enter the price of the service',
  includes: 'Includes',
  php_included_appointment: '{{count}} appointment',
  php_included_appointment_other: '{{count}} appointments',
  php_included_appointments_duration: 'Duration of included appointments for the service',
  php_includedAppointments_v: 'Enter the duration of Appointment #{{ count }}',
  popup_edit_healthplan_totalAppointments_message: 'Choose how many appointments it will include',
  php_add_appointment: 'Add appointment',
  popup_edit_healthplan_description_message: 'Enter the service description. Your patients will be able to read it',
  popup_edit_healthplan_privacy_extra_public: 'This health plan will be visible to everyone on your personal page',
  popup_edit_healthplan_privacy_extra_private: 'This health plan will not be displayed on your personal page',
  privacy: 'Privacy',
  popup_edit_healthplan_info: 'Every patient that currently have this service <1/>will not be affected by changes.',
  // PreciseObjectiveModal
  add_precise_objective: 'Add specific goal',
  add_objective: 'Add goal',
  edit_objective: 'Edit goal',
  precise_objecitve_modal_measurement_message: 'Enter the measurement',
  precise_objecitve_modal_target_message: 'Enter the target value',
  precise_objecitve_modal_target_placeholder: 'E.g: 145',
  enter_start_date: 'Enter the start date',
  precise_objecitve_modal_date_conflict: 'Start date must be before the target date',
  day_with_appointment: 'Day with appointment',
  target_date: 'Target date',
  // CustomMeasurements
  no_custom_for_recipes: "You can't create custom measurements for recipes",
  custom_measurement_create_error_1: 'Please enter the name of the measurment you want to create',
  custom_measurement_create_error_2: 'Please enter another unit',
  custom_measurement_create_error_3: 'The weight in grams must me greater than 0',
  custom_measurement_create_error_4: "There's already a measurement with the name",
  measurement_created_successfully: 'Measurement successfully created',
  custom_measurement_modal_title: 'Do you want to create the unit "{{unit}}" ({{gramWeight}} g.) for this food?',
  custom_measurement_modal_content: 'You will be able to edit or delete this measurement in the Foods section at any time.',
  yes_create_measurement: 'Yes, create measurement',
  create_new_measurement: 'Create new measurement',
  custom_measurment_div_1: 'Enter your own measurment for this food',
  unit_name: 'Name of the unit (singular)',
  custom_measurment_remember_unit: 'Remember to only include the name of the unit and not the quantity',
  custom_measurment_div_2: 'You typed a number in the name field. Quantities must not be placed there.',
  custom_measurment_div_3:
    'For example: if you want to create the custom measurement "1/2 cup", you will have to write "cup" as the name of the unit. After creating it you will be able to make the quantity "1/2".',
  custom_measurement_label: 'Weight in grams of the newly created unit',
  new_measurement_details: 'Measurement details',
  // PopupPatientSettings
  popup_patient_settings_warning: "Enter the patient's email to invite him to the app",
  popup_patient_settings_modal_title: 'Would you like to send the app access email again?',
  popup_patient_settings_modal_content: 'You have already sent an access email, but the patient has not entered the app yet',
  patient_has_entered_app: 'The patient has already entered the app',
  patient_has_not_entered_app: "The patient hasn't entered the app yet",
  delete_patient: 'Delete patient',
  show_daily_calories: "Show daily calories on the patient's meal plans",
  show_recipes_in_mealplan: "Show recipes with their ingredients and instructions on this patient's meal plans.",
  autogenerate_groceries_list: 'Autogenerate the groceries list for each meal plan',
  show_workout_in_mealplan: "Show the assigned workout in the patient's meal plan",
  access_to_app: "Access to Almendra's patients app",
  access_mail_sent: 'Access email sent',
  access_mail_not_sent: "You haven't sent the access email yet",
  patient_has_verified_email: 'The patient has verified his email',
  patient_has_not_verified_email: 'The patient has not verified his email',
  // OnboardingTours
  welcome_to_mealplan_tour: 'Welcome to the meal plan tour!',
  onboarding_tours_div_1:
    'You will learn to create a meal plan step by step and you will love it. You will see how fast you forget about Excel or other softwares.',
  onboarding_tours_div_2: 'The screen on the background shows the first of the four steps of a meal plan, which is to choose the format.',
  onboarding_tours_div_3: 'Now we will see the different ways to create a meal plan.',
  create_meal_plan: 'Create meal plan',
  onboarding_tours_step_1_content:
    'You create a strict meal plan by clicking on the button. You will be redirected to the section to set its nutrients and calories.',
  select_the_days: 'Select the days',
  onboarding_tours_step_2_content_1: 'On this section you will be able to select where you want to establish caloric and nutrient targets.',
  required_action: 'Required action',
  onboarding_tours_step_2_content_2: 'Select every day to continue',
  add_caloric_targets: 'Add caloric targets',
  onboarding_tours_step_3_content: "Here you will be able to enter the patient's caloric targets, either with formulas or manually",
  add_macro_targets: 'Add macronutrient targets',
  onboarding_tours_step_4_content_1: 'In this section you will be able to enter macronutrient goals for the selected days.',
  onboarding_tours_step_4_content_2: 'Pick a macronutrient distribution',
  add_targets_by_meal: 'Add targets by meal',
  onboarding_tours_step_5_content: 'If you want to establish caloric and macro targets by meal, you can also do it with this button:',
  view_patient_information: 'View patient information',
  onboarding_tours_step_6_content_1: 'On the right side of your screen you will find this icon:',
  onboarding_tours_step_6_content_2: "By clicking on it, you will have a quick look at the patient's most relevant information",
  save_caloric_targets: 'Save caloric targets',
  onboarding_tours_step_7_content_1: 'After having established the macro distribution you must save the targets for the selected days.',
  click_on_save_targets: 'Click on "Save caloric targets"',
  go_to_next_step: "Let's go to the next step",
  onboarding_tours_step_8_content: "All right! Let's go to add foods, meals and recipes to the meal plan.",
  search_foods: 'Search and add foods and recipes',
  onboarding_tours_step_9_content_1: 'Here you can do the following:',
  onboarding_tours_step_9_content_2: 'Search for foods and recipes',
  onboarding_tours_step_9_content_3: 'Modify search filters',
  onboarding_tours_step_9_content_4: 'Edit the quantity and unit of the food or recipe',
  onboarding_tours_step_9_content_5: 'Add the food or recipe to any meal you want',
  edit_meal: 'Edit meal',
  onboarding_tours_step_10_content_1:
    'When your mouse is over a meal, you will have the option to edit it by clicking the pencil icon, as you can see here:',
  onboarding_tours_step_10_content_2: 'Here you can edit the meal or add comments to it. You will be able to do it on the next steps.',
  day_actions: 'Day actions',
  onboarding_tours_step_11_content: 'You can also perform different actions on each day by clicking the three dots next to its name',
  onboarding_tours_step_12_content_1: 'So far so good!',
  onboarding_tours_step_12_content_2: 'Edit this plan the way you want! You can add foods, recipes, meals, and apply everything you just learnt.',
  onboarding_tours_step_12_content_3:
    'Once you finish click on "Next" on the top right hand corner, and you will be able to send the plan to the patient.',
  onboarding_tours_step_13_content:
    'Here you can see a summary of the meal plan and edit the name (main title of the PDF) and the period of the meal plan',
  add_indications: 'Add indications and recommendations',
  onboarding_tours_step_14_content:
    'You can add indications or recommendations for your patient. You can also create your own indications templates.',
  viewing_options: 'Check view options',
  onboarding_tours_step_15_content:
    'Here you can choose what will your patient see, such as calories next to every food, recipes or the groceries list.',
  onboarding_tours_step_16_content:
    'Here you can check and edit the automatically generated groceries list. We always recommend to check it before sending it.',
  download_or_send_mealplan: 'Download or send the meal plan',
  onboarding_tours_step_17_content: 'You can download the PDF or send it via email, without exiting Almendra.',
  onboarding_tours_step_18_content:
    'Once you finish the meal plan your patient will receive a notification and he will be able to see it on his Almendra app',
  tour_end: "We've reached the end of the tour",
  onboarding_tours_step_19_content_1: 'We hope this guide helped you to start adapting to Almendra',
  onboarding_tours_step_19_content_2:
    'If you have any suggestion now or at any time please leave us a message on the Suggestions inbox on the top bar.',
  onboarding_tours_step_19_content_3: 'We wish you all the best,',
  almendra_team: 'Almendra Team',
  onboarding_tour_appointment_step_0_content_1: 'Welcome to the appointment tour!',
  onboarding_tour_appointment_step_0_content_2: 'Here you will learn how to perform an appointment with Almendra. You will love it!',
  onboarding_tour_appointment_step_0_content_3: 'On this page, the appointment begins, which consists of 4 steps:',
  onboarding_tour_appointment_step_0_content_4: "patient's information, patient's measurements, meal plan and appointment summary.",
  onboarding_tour_appointment_step_0_content_5:
    "On this step you can see and edit all of the patient's information, appointment after appointment. Let's continue!",
  patient_fields: "Patient's fields",
  onboarding_tour_appointment_step_1_content: 'There are many fields, as you can see',
  anthropometric_measurements: 'Anthropometric and chemical measurements',
  onboarding_tour_appointment_step_2_content: 'Here you will be able to see and edit previous measurements',
  onboarding_tour_appointment_step_3_content: "Let's register this appointment's measurements",
  measurements_entry: 'Measurements entry',
  onboarding_tour_appointment_step_4_content: 'Here we can add the measurements that correspond to this appointment.',
  onboarding_tour_appointment_step_5_content: "Let's see the assigned meal plans",
  add_mealplans: 'Add meal plans',
  onboarding_tour_appointment_step_6_content_1: 'Here you can add meal plans linked to this appointment',
  onboarding_tour_appointment_step_6_content_2: 'Click "Add meal plans"',
  onboarding_tour_appointment_step_7_content:
    'You can start the meal plan right now by clicking the green button. Otherwise it will remain as pending and you will be able to do it later.',
  onboarding_tour_appointment_step_8_content:
    'You can download the report of the appointment on PDF format. You can also send it to your patients via email.',
  appointment_summary: 'Appointment summary',
  onboarding_tour_appointment_step_9_content:
    "You can add payments, comments and even schedule the next appointment. You can also see the patient's progress.",
  // PopupNewWorkout

  'Aumentar masa muscular': 'Build muscle mass',
  'Quemar grasa': 'Burn fat',
  'Mejorar resistencia': 'Increase endurance',
  'Aumentar flexibilidad': 'Increase flexibility',
  Otros: 'Other',

  workout_template_successfully_created: 'Workout template successfully created!',
  workout_template_successfully_edited: 'Workout template successfully edited!',
  workout_successfully_edited: 'Workout successfully edited!',
  workout_successfully_deleted: 'Workout successfully deleted!',
  create_workout_template: 'Create workout template',
  assign: 'Assign',
  edit_workout: 'Edit workout',
  exercise_routine: 'Workout',
  edit_workout_template: 'Edit workout template',
  assign_exercise_routine: 'Assign a workout',
  assign_routine: 'Assign workout',
  save_workout: 'Save workout',
  create_workout: 'Create workout',
  unassign_routine: 'Desasignar rutina',
  popup_new_workout_modal_prompt: 'You can customize this workout for this patient',
  popup_new_workout_name_message: 'Enter the name of the workout',
  popup_new_workout_name_placeholder: 'E.g: Muscle mass workout',
  at_least_one_day: 'Select at least one day',
  popup_new_workout_days_placeholder: 'E.g: Tuesday, Thursday, Saturday',
  popup_new_workout_goal_message: 'Select a goal',
  popup_new_workout_goal_placeholder: 'E.g: Build muscle',
  popup_new_workout_duration_message: 'Enter the duration in minutes',
  popup_new_workout_duration_placeholder: 'E.g: 60',
  equipment: 'Equipment',
  with_equipment: 'With equipment',
  without_equipment: 'Without equipment',
  popup_new_workout_body_message: 'Enter the workout content',
  popup_new_workout_textarea_placeholder:
    'Here you write the content of the workout with all of its specifications. E.g:\n-20 reps of jump squats, 3 sets\n-10 push ups, 3 sets\n-30 jumping jacks, 3 sets',
  // FinancialsMain
  financials_general: 'Overview',
  last_period: 'last period',
  financials_income: 'Revenue',
  financials_expenses: 'Expenses',
  disabled_feature: 'Disabled feature',
  in_this_week: 'during this week',
  in_the_last_days: 'during the last {{graphTimestamp}} days',
  previous_period_with_l: 'l past period',
  last_7_days: 'last 7 days',
  last_30_days: 'last 30 days',
  last_365_days: 'last 365 days',
  loading_financials: 'Loading financials...',
  historic: 'historic',
  // TourSteps
  tourSteps_0_title: 'We present you the exchange format',
  tourSteps_0_content_1:
    'This format consists of a system that enables patients to choose what to eat according to what they want at the moment thanks to a set of options.',
  tourSteps_0_content_2: 'This short tour will teach you to easily create this kind of meal plans.',
  start_the_tour: '¡Comencemos el tour!',
  tourSteps_1_content_1: 'The exchange groups that you have selected as ',
  default: 'default',
  tourSteps_1_content_2: 'on',
  equivalents_lowercase: 'equivalents',
  tourSteps_1_content_3: 'will be shown by default',
  tourSteps_2_content_1: 'In this sections you will be able to',
  tourSteps_2_content_2: 'Add the exchange groups to the day',
  tourSteps_2_content_3: 'Add the meals to the day',
  tourSteps_3_content_1: 'You will be able to change the amount of servings of each meal in this cell. These visual cues will show up:',
  quantity_over_0: 'quantity is greater than 0',
  quantity_is_0: 'quantity is equal to 0',
  tourSteps_4_content: 'Here will be the total amount of servings that you have inserted in this day for this exchange group',
  tourSteps_5_title: 'Daily analysis for an exchange group',
  tourSteps_5_content_1: 'You can see the caloric and macros contribution for this exchange group.',
  tourSteps_6_title: 'Analysis by meal',
  tourSteps_6_content_1: 'Here you can see the analysis for every exchange group in this meal',
  tourSteps_7_title: 'Analysis by day',
  tourSteps_7_content_1: 'Here you can see the analysis for every exchange group in this day.',
  tourSteps_8_title: 'Daily analysis vs targets',
  tourSteps_8_content: 'You can see the nutrient adequacy for each of them',
  tourSteps_9_content: 'To add menu options and not only exchange lists, click this button',
  place_foods: 'Place foods',
  tourSteps_10_content: 'Here you will see the exchange groups assigned for this meal in the previous section',
  tourSteps_11_title: 'You can add more foods to that meal',
  tourSteps_11_content_1: 'For example, if the servings you assigned to the breakfast were: 1 Vegetable, 2 Fruits, 1 Dairy y 1 Cereal',
  tourSteps_11_content_2: 'A menu that you could suggest would be:',
  tourSteps_11_content_3: 'Oatmeal with milk',
  tourSteps_11_content_4: '(1 Cereal and 1 Dairy)',
  tourSteps_11_content_5: 'Spinach and papaya smoothie',
  tourSteps_11_content_6: '(1 Vegetable and 1 Fruit)',
  tourSteps_11_content_7: 'Avocado',
  tourSteps_11_content_8: '(1 Fruit)',
  tourSteps_12_content_1: 'You can check the nutritional information of every option added to this meal',
  tourSteps_13_title: "That's it! Once you had entered the exchange groups and their servings for each meal, the meal plan would look like this:",
  // QuotesLoadingPage
  quotes_1: 'Falling in love with yourself is the first secret to happiness.',
  quotes_2: 'Accepting ourselves as we are means valuing our imperfections as much as our perfections.',
  quotes_3: 'The worst loneliness is not feeling comfortable with oneself',
  quotes_4: "Loving yourself is not vanity. It's sanity",
  quotes_5: 'To many people overvalue what they are not and undervalue what they are.',
  quotes_6: 'Never look down. Look at the world directly in the face.',
  quotes_7: 'Be loyal to what exists within you',
  quotes_8: 'You must believe in yourself when no one else does it.',
  quotes_9: 'If you have the capacity to love, love yourself first.',
  quotes_10: 'He who looks outside, dreams; who looks inside, wakes up.',
  quotes_11: 'No one can make you feel less without your consent',
  quotes_12: 'Trust yourself, you know more than you think.',
  quotes_13: 'Growth starts when you start to accept your own weakness.',
  quotes_14: 'I am my own experiment. I am my own artwork.',
  quotes_15: "It's never too late to be what you could have been.",
  quotes_16: 'Let yourself enjoy every happen moment of your life.',
  quotes_17: 'Beauty starts when you decide to be yourself.',
  quotes_18: "Don't doubt yourself, that's why enemies exist.",
  quotes_19: 'Be loyal to yourself. An original is worth more than a copy.',
  quotes_20: 'Whenever possible, choose peace',
  quotes_21: 'Wishes are possibilities. Dare to make a wish.',
  quotes_22: "You're a natural phenomenon, not a natural disaster.",
  // PatientStats
  hour: 'hour',
  hour_other: 'hours',
  // AddRecipeToMealPlan
  no_changes_in_recipe:
    'You have not made any changes to the composition of the recipe. Unedited fields: Ingredients, Optional Ingredients, Instructions, Portions, Category.',
  new_feature: 'New feature!',
  error_editing_recipe: 'There was a problem editing the recipe',
  error_adding_ingredient: 'There was a problem adding the ingredient',
  add_recipe_to_meal_plan_help_content_1: 'Now you will be able to edit an existing recipe directly from the meal plan editor',
  add_recipe_to_meal_plan_help_content_2: 'You will have the option to save the changes made to the recipe as a new one.',
  add_recipe_to_meal_plan_help_content_3:
    'You can also save the changes made to the recipe only for the current meal plan. This is really useful when adjusting one recipe to one specific patient.',
  add_recipe_to_meal_plan_help_content_4: 'Where can I edit the recipe with this feature?',
  add_recipe_to_meal_plan_help_content_5: 'When adding a recipe to the meal plan (table or exchange format).',
  add_recipe_to_meal_plan_help_content_6: 'On any recipe previously added to a meal plan.',
  add_recipe_to_meal_plan_placeholder_1: 'Enter an optional ingredient',
  step: 'Step',
  at_least_one_ingredient: 'Add at least one ingredient to the recipe',
  at_least_one_instruction: 'Add at least one instruction to the recipe',
  at_least_one_serving: 'The minimum amount of servings is 1',
  enter_recipe_name: 'Enter the name of the recipe',
  add_recipe_to_meal_plan_warning_1: 'To continue save the recipe with another name',
  edit_recipe: 'Edit recipe',
  no_image: 'Sin imagen',
  how_to_save_changes: 'How would you like to save these changes?',
  keep_editing: 'Keep editing',
  only_this_meal: 'Only for this meal',
  only_this_meal_desc: 'Changes will only be saved for this meal. You will not be able to use it elsewhere.',
  as_new_recipe: 'As a new recipe',
  as_new_recipe_desc: 'A new recipe will be created and it will have all the changes you made. You will be able to use it later on any meal plan.',
  add_new_ingredient: 'Add new ingredient',
  // PersonalPageSettings
  disable_online_booking_error: 'There was an error while disabling the online booking system',
  disabled_personal_page: 'Your personal page is disabled',
  disabled_personal_page_content: 'Enable it by clicking on the switch in the "Show Personal Page" section',
  disabled_customFormFill_content: 'Enable your custom forms in the General Settings > Appointments section',
  view_personal_page: 'View personal page',
  personal_page: 'Personal page',
  personal_page_url: 'Personal page URL',
  show_personal_page: 'Show personal page',
  show_recipe_section: 'Show recipe section',
  show_blog_section: 'Show blog section',
  show_work_addresses: 'Show my work addresses',
  online_booking_link: 'Online booking link',
  enable_online_booking: 'Enable online booking',
  what_form_to_send_first_appointment: 'Which form to send for the first appointment?',
  what_form_to_send_first_appointment_extra: 'Only applies to appointments booked through the online booking system.',
  what_form_to_send_second_appointment: 'Which form to send as of the second appointment?',
  request_payment_long: 'Request to pay in order to book the appointment',
  request_payment_long_extra_1: 'To enable this option, link your account with Paypal',
  request_payment_long_extra_2: 'or MercadoPago',
  request_payment_long_extra_3: ' in the Security section',
  mandatory_payment: 'Mandatory payment',
  optional_payment: 'Optional payment',
  multiple_links: 'Multiple links',
  multiple_links_URL: 'Multiple links URL',
  multiple_links_settings: 'Multiple links settings',
  multiple_links_desktop: 'Edit on desktop',
  plan_restriction_modal_blog: 'In order to create your own blog you need to be subscibed at least to the Expert Plan',
  currently_subscribed_to: "You're currently subscribed to the",
  currently_not_subscribed_to: "You're currently not subscribed any plan",
  // AppointmentSummary
  appointment_summary_fully_paid: 'This service has been fully paid',
  appointment_summary_fully_paid_2: "You're entering an amount",
  appointment_summary_fully_paid_3: 'higher than the remaining balance',
  appointment_summary_modal_content: 'Would you like to add <1>{{-currencySymbol}} {{amount}}</1> as an extra amount to include on your finances?',
  yes_add_extra_amount: 'Yes, add extra amount',
  fix_amount: 'Fix amount',
  appointment_summary_modal_title: 'There would still be a pending balance, would you like to continue?',
  appointment_summary_modal_2_content_1: 'It looks like you want to enter an amount to pay',
  appointment_summary_modal_2_content_2: 'lower than the pending balance',
  of_the_appointment: 'of the appointment',
  of_the_service: 'of the service',
  appointment_summary_modal_2_content_3: 'We will assume the total amount',
  will_be: 'will be',
  instead_of: 'instead of',
  unfilled_data: 'Data not filled',
  appointment_summary_changed_properties_tooltip_1: 'This field was edited less than ',
  appointment_summary_changed_properties_tooltip_2:
    "hours ago, so it was overridden by the value of the current appointment. Remember that in order to store a new value in the patient's history, there should pass at least ",
  appointment_summary_changed_properties_tooltip_3: 'hours after the previous change.',
  appointment_summary_changed_properties_tooltip_4: "Wasn't modified during the last appointment. Modified on ",
  current_appointment: 'Current appointment',
  property: 'Property',
  previous_appointment: 'Previous appointment',
  cash: 'Cash',
  card: 'Card',
  no_card_commission: "You haven't entered the card commission (Settings > General Settings).",
  card_commission_prompt: 'A commission of {{amount}}% will be considered for your finances',
  appointment_fully_paid: 'This appointment has been fully paid',
  pending_balance: 'Pending balance',
  amount_paid_today: 'Amount paid today',
  no_scheduled_appointments: "doesn't have scheduled appointments yet",
  previous_appointment_comparison: 'Previous appointment comparison',
  no_changes_previous_appointment: 'There are no changes vs. the previous appointment',
  any_other_comments: 'Any other comment about this appointment that you would like to keep?',
  any_other_comments_tooltip:
    "The record of every comment about appointments is stored on the patient's profile, in the 'Comments and other info' section",
  // PopupEditScheduledAppointment
  delete_appointment_title: 'Do you want to delete this appointment?',
  delete_scheduled_appointment_success: 'You deleted the appointment with {{name}}',
  appointment_successfully_deleted: 'Appointment deleted from schedule',
  edit_appointment: 'Edit appointment',
  appointment_never_started: 'This appointment was scheduled on {{-date_1}} from {{-date_2}} to {{-date_3}} but it was never started.',
  appointment_did_start: 'This appointmnt was done on {{-date_1}} from {{-date_2}} to {{-date_3}}',
  appointment_in_process: 'This appointment is in process',
  other_scheduled_appointments: 'You have another appointment scheduled for this date',
  other_scheduled_appointments_other: 'You have other appointments scheduled for this date',
  select_modality: 'Select the method',
  // ExpertProfile
  change_subdomain_content_1: 'Your personal page will be changed from "<1>{{lastSubdomain}}</1>" to "<3>{{newSubdomain}}</3>".',
  change_subdomain_content_2: 'The new access to your dashboard will be done through <1>{{-subdomainLink}}</1>.',
  change_subdomain_content_3: 'By confirming this action, we will redirect you to your corresponding subdomain.',
  subdomain_change_error: 'There was an error trying to change your subdomain. Please try again',
  mail_edit_success: 'Your email has been successfully edited',
  expired_code: 'That code has expired',
  mail_edit_error: 'There was an error when editing the email. Try again later',
  wrong_code_entered: 'The entered code is incorrect',
  change_email_title: 'Do you want to change your email?',
  change_email_content_1:
    'Remember that you access your account with your email. By confirming we will unlink the email "<1>{{mail}}</1>" from this account.',
  change_email_content_2: 'Next time you login you will use the email "<1>{{mail}}</1>".',
  change_email_content_3: 'After you accept we will send you an email to <1>{{mail}}</1> so you can confirm this change.',
  change_email_content_4: 'This action is irreversible. Remember to never share this information with other people.',
  change_email_success: 'We sent you an email to <1>{{mail}}</1> to confirm this change',
  change_email_error: 'There was an error trying to edit the email. Please try again.',
  change_subdomain_title: 'Do you want to change your subdomain address?',
  enter_the_code: 'Enter the code',
  enter_the_code_tooltip: 'A code will be sent to your inbox as soon as you request an email change',
  verify: 'Verify',
  enter_the_code_long: 'Enter the code to change your email',
  invalid_email: 'The email you entered is not valid',
  personal_subdomain: 'Personal subdomain',
  personal_subdomain_valid: 'Enter a valid subdomain.',
  only_lowercase: 'Enter only lowercase letters',
  personal_subdomain_length_min: 'Enter at least 3 characters',
  personal_subdomain_length_max: 'Enter no more than 25 characters',
  personal_subdomain_unavailable: 'This subdomain is not available, try with another one',
  nationality_message: 'Please select your nationality',
  birthDate_message: 'Please enter your birth date',
  gender_message: 'Please enter your sex',
  idNumber_message: 'Please enter your id number',
  customerName_label: 'Business name',
  customerName_message: 'Enter your business name (max 40 characters)',
  customerName_ex: 'ex: Napsis Health LLC',
  customerName_extra: 'In case this field is filled, invoices will be issued for this name',
  verificationPhone_extra: 'This account was verified with phone {{phone}}.',
  // MeasurementsHistory
  delete_appointment_error: 'There was an error while deleting the appointment. Please try again later.',
  feet_and_inches: 'feet and inches',
  max_value: 'Max\nValue',
  min_value: 'Min\nValue',
  total_variation: 'Total\nVariation',
  last_measurement: 'Last\nMeasurement',
  normal_measurements_values: 'Normal values',
  patient_on_normal_range: 'The patient is in the normal range',
  patient_not_on_normal_range: 'The patient is in the normal range',
  measurements_normal_range_source: 'Normal values source: MedlinePlus (USA National Medicine Library) and Blufstein Lab',
  at_least: 'como mínimo',
  at_most: 'at most',
  child_data_source: 'Child growth standards - WHO | World Health Organization',
  bmi_with_pre_gestational_weight: 'BMI is calculated with the pre-gestational weight',
  one_week_ago: 'One week ago',
  two_weeks_ago: 'Two weeks ago',
  one_month_ago: 'One month ago',
  three_months_ago: 'Three months ago',
  // FlexibleMealPlanDays
  no_other_food_groups: 'No other groups with foods were found for this day.',
  delete_food_group_question: 'Do you want to delete this group?',
  yes_delete_group: 'Yes, delete group',
  delete_dynamic: 'Do you want to delete',
  copy_from_day_dynamic: 'Copy from day',
  copy_day_confirm_body:
    'The current content of {{dayName1}} will be replaced by the current content of {{dayName2}}. This action is not reversible.',
  copy_to_every_day_question: 'Copy this day on every day?',
  copy_to_every_day_body:
    'The content of ({{dayName}}) will be copied to every other days. If any other day has information in it, it will be completely overridden. This action is not reversible.',
  clear_day_confirmation_body: 'The whole current content of {{name}} will be deleted. This action is not reversible.',
  remaining: 'Remaining',
  switch_to_chart: 'Switch to graph',
  switch_to_table: 'Switch to table',
  view_weekly_summary: 'View weekly summary',
  stick: 'Pin',
  unstick: 'Unpin',
  chart: 'Chart',
  clear_day: 'Clear day',
  meal_plan_only_has_one_day: 'The meal plan only has one day',
  copy_to_every_day: 'Copy to every day',
  copy_from_day: 'Copy from day',
  no_other_day_with_foods: 'Currently there are no other days with foods',
  of_target: 'of target',
  nutrients_per_meal: 'Nutrients per meal',
  nutrients_per_day: 'Nutrients per day',
  move_position: 'Move position',
  where_to_move_meal: 'To what position would you like to move this meal?',
  at_first: 'At first',
  before_of: 'Before',
  at_the_end: 'At the end',
  delete_meal: 'Delete meal',
  mealplan_group_text_help: 'Add a food group to this meal. Your patient will have to pick one food from the group.',
  flexible_mealplan_tooltip_1: 'An average of the calories, macronutrients and micronutrients of each option group is shown as a reference',
  copying_meal_state: 'Copying meal',
  macronutrients: 'Macronutrients',
  micronutrients: 'Micronutrients',
  // SearchBar
  no_results_for_search_searchBar: 'No results found for the search <1>"</1><2>{{text}}</2><3>"</3>',
  allergic_food_modal_title: 'We have found a match with allergenic foods for the patient',
  allergic_food_modal_content_0: 'You are about to add the food',
  allergic_food_modal_content_1: 'The foods to which the patient is allergic are:',
  allergic_food_modal_content_2: 'Do you want to add this food to the plan?',
  allergic_food_modal_okText: 'Yes, add anyway',
  search_food_error: 'There was an error while searching the foods. Please try again.',
  add_favorite_food_modal_title: 'You just added your first favorite food',
  add_favorite_food_modal_content_1: 'Select your favorite foods to add them faster to your meal plans.',
  add_favorite_food_modal_content_2: 'To view all of your favorite foods select the filter button (<1/>) and follow these steps:',
  add_favorite_food_modal_content_3: 'You can view and edit all of your favorite foods in the <1>Foods</1> section.',
  remove_favorite_food: 'Do you want to remove this food from your Favorite Foods?',
  yes_remove: 'Yes, remove',
  copy_food: 'Copy food',
  searchbar_min_length: 'Enter at least 3 characters',
  no_results: 'No results',
  result: 'result',
  result_other: 'results',
  showing: 'Showing',
  my_food: 'My food',
  almendra_recipe: 'Recipe by Almendra',
  my_recipe: 'My recipe',
  peru_branded_foods: 'Branded foods Peru (2021)',
  accept: 'Accept',
  restaurate: 'Restart',
  searching: 'Searching',
  favorite_foods_not_added: "You haven't added your favorite foods yet",
  recipes_not_found: "We couldn't find any recipe",
  foods_not_found: "We couldn't find any food",
  foods_not_found_text: "You didn't find the food you were looking for? Add a food manually and it will be saved for your next searches",
  select_favorite_foods_text: 'Select the foods you always use as favorites by clicking the heart icon, so your next searches are easier',
  in_every_database: 'in every database',
  create_your_own_recipes: 'Start by creating you own recipes and use them whenever you create meal plans.',
  go_to_recipes: 'Go to Recipes',
  add_food_manually: 'Add a food manually and we will add it to your next searches.',
  // PayPalLinkAccount
  paypal_link_account_title: 'Do you confirm that {{mail}} is correct?',
  paypal_verify_mail:
    'Verify that you have a PayPal account linked with that email. We remind you that if you do not have access to that email, you may not claim the funds.',
  account_successfully_linked: 'Account successfully linked',
  paypal_unlink_title: 'Do you want to unlink you Paypal account with Almendra',
  paypal_unlink_content:
    'We remind you that every payment option on your account will be disabled. Your patients will not be able to pay for your services online after this action.',
  paypal_link_account: 'Link account with Paypal',
  paypal_link_account_modal_content_1: 'Your patients will be able to pay for your services when they book through the online booking system.',
  paypal_link_account_modal_content_2: 'Since your currency is {{-currency}}',
  paypal_link_account_modal_content_3: 'Your patients will pay in {{currentCurrencyCode}}',
  paypal_link_account_modal_content_4:
    'Your patients will pay in USD. We will use a reference exchange rate USD/{{currentCurrencyCode}} that refreshes every day to convert the amount to USD.',
  note: 'Note',
  note_other: 'Notes',
  paypal_link_account_modal_content_5:
    '- PayPal charges two kind of fees for their services, according to your country: transaction fee (go <1>here</1> for more info) and a fee to send the funds to your account (go <3>here</3> for more information).',
  paypal_link_account_modal_content_6: 'Almendra does not charge any additional fee',
  paypal_link_account_modal_content_7: "Be sure to check <1>Paypal's buyer protection policy</1>.",
  paypal_email: 'Paypal email',
  enter_paypal_email: 'Enter your Paypal email',
  paypal_link_account_modal_content_8: "If you don't have a Paypal account you can create one for free <1>here</1>.",
  // MealPlanView
  view_summary: 'View summary',
  view_day: 'View {{dayName}}',
  meal_plan_view_alert_1: 'You are seeing the assigned meal plan for',
  meal_plan_view_alert_2: 'This meal plan was scheduled from {{-date_1}} to {{-date_2}}.',
  meal_plan_view_alert_3: 'Remember you are on a "<1>view only</1>" mode, so you will not be able to edit the meal plan.',
  go_to_day: 'Go to {{dayName}}',
  // PopupHealthPlanPayments
  php_appointment_status_finished: 'This appointment ended on {{-date, MM/DD/YYYY}}.',
  php_appointment_status_delayed: "This appointment wasn't started.",
  php_status_finished: 'This service ended on {{-date, MM/DD/YYYY}}.',
  enrolled_since: 'Enrolled since',
  no_scheduled_appointments_yet: 'No appointments have been scheduled for this service yet',
  appointmented_delayed: 'Appointment delayed',
  payment_done: 'Payment done',
  payments_done: 'Payments done',
  no_payments_yet: 'No payments have been entered yet',
  payment_with_paypal: 'Made with Paypal',
  payment_with_mercadopago: 'Made with MercadoPago',
  service_completely_paid: 'This service has been completely paid',
  additional_amount_paid: 'An additional amount was paid: {{currencySymbol}} {{pendingAmount}}',
  unassign_service: 'Unassign service',
  unassign_service_alert: 'To unassign the service to this patient, first finish the current appointment.',
  assign_service_alert: 'To assign the service to this patient, first finish the current appointment.',
  load_service_error: "This service's information couldn't be loaded. Try again later",
  unassign_service_title: 'Do you want to unassign this service?',
  unassign_service_title_content_1:
    "All of the information about the appointments related to this service will be kept in the patient's history, but scheduled appointments for this service will be deleted",
  unassign_service_title_content_2:
    "There's a payment made via {{processor}} for this service. By unassigning this service, the information about this payment will be lost",
  yes_unassign: 'Yes, unassign',
  unassign_service_success: 'Service succesfully unassigned',
  hide_appointment_details: 'Hide appointment details',
  hide_service_details: 'Hide service details',
  view_appointment_details: 'View appointment details',
  view_service_details: 'View service details',
  //
  // SBM
  //
  //AppointmentCommentsTimeline
  pre_edit_pastAppointmentComment_title: 'You are about to edit a comment that belongs to a past appointment',
  pre_edit_pastAppointmentComment_content: 'We recommend doing this just if you think there was a mistake in a past appointment comment',
  comments_before_firstAppointment: 'Comments before the first appointment',
  comments_per_appointment: 'Comments per appointment',
  appointment_of_date: 'Appointment date: ',
  // BillingPlanSelection
  regulate_plan: 'Regularize',
  select_plan: 'Select',
  current_plan: 'Current plan',
  choose_higher_billingPlan: 'Choose a plan with a higher active patients threshold',
  no_surprise_billingPlans: 'Flexible plans without surprises',
  choose_your_right_plan: 'Choose the plan that fit your needs',
  most_popular_plan: 'Chosen by over 1189 professionals',
  early_adopters_promotion: 'Promotion applied by launch',
  first_month_discount_percentage: '% discount on the first month!',
  then: 'luego',
  billing_per_month: 'per month, billed ',
  for_12_months: 'for 12 months',
  anual_billing_per_month: 'per month, billed annually',
  plan_initial_discount_message: 'You got <1>{{onBoardingDiscount}}% discount</1> on your first month!',
  plan_ondemand_initial_discount_message: 'You got <1>{{firstMonthDiscount}}% discount</1> on your first month!',
  billing_plan_text_first: 'first',
  billing_plan_text_month: 'month',
  billing_plan_text_per: 'per',
  freemium: 'Free',
  plan_forever: 'forever',
  plan_per_month: 'per month',
  features_active_patients_limit: 'Monthly active patients',
  features_active_patients_limit_des:
    'A patient is active is that patient for whom you have undergone an appointment or have had a meal plan in use during the month of the billing period.',
  features_active_patients_limit_free: 'up to 3 active patients',
  features_active_patients_limit_free_des: 'Free Plan allows you to see up to a maximum of 3 different active patients per month.',
  features_active_patients_limit_pro: 'First {{minPatients}} patients for free',
  features_active_patients_limit_pro_des:
    'There is no limit to how many patients you can attend per month. Starting from the {{minPatients}}th patient attended during the period, each patient you attend has a value of USD 1.5.',
  Free: 'Free',
  Standard: 'Standard',
  Pro: 'Pro',
  'Plan Free': 'Free Plan',
  'Standard Plan': 'Standard Plan',
  'Plan Pro': 'Pro Plan',
  features_unlimited_integrated_ai: 'Almendra AI Assistant®',
  features_unlimited_integrated_ai_des:
    "Harness the full power of AI 24/7/365. Just like ChatGPT, but with your patients' information. $10 in free credits with your first subscription.",
  features_unlimited_integrated_ai_extra: '+$10 in credits',
  features_active_max_stored_patients_free: 'Limited storage',
  features_active_max_stored_patients_free_des: 'Register up to 20 patients or create up to 20 meal plans.',
  features_active_max_pdf_free: 'Limited PDF generation',
  features_active_max_pdf_free_des: 'Download or send up to 20 PDFs.',
  features_active_max_stored_patients_pro: 'Unlimited storage',
  features_active_max_stored_patients_pro_des: 'You will not be restricted by the number of patients and meal plans that you store in your account.',
  features_active_max_pdf_pro: 'Unlimited PDF generation',
  features_active_max_pdf_pro_des: 'No limits on sending or downloading PDFs.',
  features_active_patients_limit_unlimited: 'Unlimited',
  features_active_patients_limit_unlimited_tooltip: 'Minimum {{minPatients}} active patients billed',
  features_basic_records: 'Patient records',
  features_basic_records_des: "Easily register your patients' information and access at any time, anywhere.",
  features_basic_plans: 'Personalized Meal Plan Creator',
  features_basic_plans_des: 'Elaborate extremely fast and highly detailed meal plans with incredible nutrient analytics.',
  features_basic_groceries: 'Automatic groceries list',
  features_basic_groceries_des: 'Generate the groceries list of each plan with a single click. Save time and effort to all your patients.',
  features_basic_dashboard: 'Customized Dashboard',
  features_basic_dashboard_des: 'Your own control panel totally tailored to your preferences',
  features_basic_agenda: 'Integrated Calejdar',
  features_basic_agenda_des:
    'Manage your calendar from within Almendra and reduce absenteeism to appointments. Integration with Google Calendar available.',
  features_basic_database: 'Official Food Databases',
  features_basic_database_des:
    'More than 20000 Official Database Foods in the palm of your hand. Stop wasting time looking for the nutrients in books or other pages.',
  features_basic_app: 'Almendra App for patients',
  features_basic_app_des:
    'All of your patients will have immediate access to their meal plans and their progress, with an extraordinary interface and free of charge for them.',
  features_basic_equivalents: 'Lists of equivalences',
  features_basic_equivalents_des: 'Incorporate your own equivalences to assign them in the meal plans.',
  features_basic_templates: 'Meal plan templates',
  features_basic_templates_des: 'Generate meal plans almost instantly by using templates.',
  features_basic_services: 'Offer your health plans',
  features_basic_services_des: 'Take a better control of the types of services you offer and view their analytics',
  features_basic_recipes: 'Creation and analysis of recipes',
  features_basic_recipes_des: 'Add all recipes you want and include them in the meal plans you create. Nutrients are calculated automatically.',
  features_basic_links: 'Instagram link generator',
  features_basic_links_des: 'Tool that allows you to share multiple links using a single link.',
  features_basic_booking: 'Online Booking System',
  features_basic_booking_des:
    'Forget about coordinating appointments by text or by phone. Take advantage of your time, appointment come by themselves.',
  features_basic_workouts: 'Workouts',
  features_basic_workouts_des: 'Accompany your meal plan with a training workout for your patients.',
  features_basic_patients: 'Up to 8 patients a month',
  features_basic_patients_des: 'The basic plan allows you to treat a maximum of 8 different patients per month.',
  features_advanced_name: 'Everything from the Basic plus:',
  features_advanced_stats: 'Business Statistics',
  features_advanced_stats_des:
    'Real-time analytics of all your business. Forget about taking weekly or monthly calculations of how many patients you treated.',
  features_advanced_mailing: 'Automatic Mail Delivery',
  features_advanced_mailing_des: 'Send reminders of appointments and other emails without moving a finger',
  features_advanced_finance: 'Business Finances',
  features_advanced_finance_des:
    'Get to know the monetary aspect of your business in an automated way, thanks to an entire section dedicated to the registration, control and analytics of your numbers.',
  features_advanced_web: 'Personalized website',
  features_advanced_web_des: 'Your own website without additional costs.',
  features_advanced_patients: 'Up to 25 patients per month',
  features_advanced_patients_des: 'The Advanced Plan allows you to treat a maximum of 25 different patients per month.',
  features_expert_pre: 'Everything from the Advanced Plan plus:',
  features_expert_blog: 'Almendra Blog',
  features_expert_blog_des: 'Disclose your knowledge with your own blog and get your ideas a place in the web.',
  features_expert_stats: 'Statistics per patient',
  features_expert_stats_des: 'Deep analytics of every patient so you know evertyhing about them',
  features_expert_patients: 'Up to 50 patients a month',
  features_expert_patients_des: 'The Expert Plan allows you to treat a maximum of 50 different patients per month.',
  features_unlimited_pre: 'Everything from the Expert Plan plus:',
  features_unlimited_support: 'Premium Support',
  features_unlimited_support_des: 'A dedicated team so that response times are almost immediate, and your questions are served with high priority.',
  features_unlimited_patients: 'Unlimited monthly patients',
  features_unlimited_patients_des: 'Any number of patients you need. Simple.',
  features_unlimited_mealplangen: 'Meal plan generator',
  features_unlimited_mealplangen_des:
    "Personalized meal planning algorithm that helps you create effective meal plans tailored to your patients' needs.",
  currency_description: '*Expressed in US Dollars (USD)',
  access_to: 'Access to',
  everything_in_free_plus: 'Everything in the Free Plan plus',
  free_plan_description: 'The essentials to get started',
  pro_plan_description: 'Take your business to the next level',
  starts_from: 'starts from',
  upsell_almendra_assistant_promo_with_discount: 'Plus, <strong>$10 credits</strong> on <strong>Almendra AI Assistant</strong>',
  upsell_almendra_assistant_promo_without_discount: '¡You have <strong>$10 credits</strong> on <strong>Almendra AI Assistant</strong> in your first month!',
  // HandleSubscription
  cancel_ondemand_content_pre_0:
    '{{firstName}}, the current period of your subscription to the <1>{{planName}} Plan</1> is from <3>{{-startDate, DD/MM/YYYY}} to {{-endDate, MM/DD/YYYY}}</3>.',
  cancel_ondemand_content_pre_1: 'Until today, the billing for this period is:',
  cancel_ondemand_subscription_title: 'Do you want to cancel your subscription renewal?',
  cancel_grace_ondemand_subscription_content_0: 'Since you currently have <1>0 active patients</1>, we want to help you keep growing.',
  cancel_grace_ondemand_subscription_content_1:
    'For this time, we will cancel your subscription to the <1>{{planName}} Plan</1> without incurring any payment and you will go to the <3>Free Plan</3>, where you can have up to 3 active patients per month without need to pay, totally free!',
  cancel_ondemand_subscription_content_0:
    'On <1>{{- endDate, MM/DD/YYYY}}</1>, the last day of your current period, pending billing will be charged up to that moment and the renewal to <3>{{planName} Plan will be canceled</3>. From there, we will change your subscription to the <5>Free Plan</5>, where you will be able to have up to 3 active patients per month without having to pay, totally free!',
  cancel_ondemand_subscription_content_1: 'If you continue activating patients until that date, it will be included in your billing.',
  do_you_want_to_continue: 'Do you want to continue?',
  done_discount_obtained: 'Awesome! Discount obtained',
  canceled_subscription_to: 'You canceled your subscription to',
  subscription_cancel_failed: 'Your subscription could not be canceled',
  resumed_subscription_to: 'You resumed your subscription to',
  subscription_resume_failed: 'Your subscription could not be resumed',
  of_succes: 'of success',
  look_faq: 'Check our FAQ',
  look_tutorials: 'Check our tutorials',
  dont_unsuscribe_copywrite:
    'Remember that 9 out of 10 patients prefer Almendra. Probably one of the reasons they chose you was because the app you offer. If Almendra helps you get one patient, it pays for itself.',
  what_would_you_add: 'Please, tell us what do you want us to add',
  cancel_susbcription_min_char: 'Please write at least 10 characters',
  send_and_get_discount: 'Send and get a {{name}} % discount',
  cancel_subscription_badSupport:
    'We have experienced a rise of demand in the last weeks, and we are very sorry if you experienced a bad support during this time. We offer to suscribe you to our premium support channel to prevent this from ever happen again.',
  activate_premium_support: 'Activate Premium Support',
  premium_support_activated: 'Done! Premium Support activated',
  change: 'Change',
  change_subscription_question: 'Do you want to change your current plan？',
  yes_cancel: 'Yes, cancel',
  cancel_recurring_subscription: 'Do you want to cancel your recurring subscription?',
  resume: 'Resume',
  yes_resume: 'Yes, resume',
  resume_recurring_subscription: 'Do you want to resume your subscription?',
  resume_recurring_subscription_details: 'You requested the cancellation of your plan. This will be scheduled for {{-date, MM/DD/YYYY}}.',
  cancel_subscription: 'Cancel subscription',
  why_cancel_subscription: 'Why do you want to cancel your subscription?',
  reason_hard_to_use: 'Hard to use',
  reason_too_expensive: 'Too expensive',
  reason_not_much_info: 'It needs more nutrition information',
  reason_bad_support: 'Bad support',
  reason_not_attending_patients: "I'm not currently treating patients",
  we_could_fix_that: 'We can fix that',
  how_can_we_improve: 'How can we improve?',
  leave_your_opinion: 'Leave your comments here',
  give_us_time:
    'Give us time to improve, your comment has been received by the team and they will start working on it ASAP. Meanwhile, we give you a {{discount}}% discount in your next month if you stay with us',
  churn_discount_accepted: 'Done! Discount accepted',
  obtain_churn_discount_and_stay: 'Obtain a {{discount}} % and stay in Almendra',
  cancel_subscription_warning:
    "Remember that once your subscription is canceled you won't be able to use Almendra's features. After consecutive 6 months of not renewing your subscription all your data will be deleted from our servers",
  cancel_subscription_apologize: "We are sorry if we couldn't satisfy your needs..",
  cancel_subscription_last_words: 'We will get better and we hope we could work together in the future',
  want_to_keep_subscription: 'I want to keep my subscription',
  // PopupEditAndDeleteIncome
  revenue_register_success: 'Revenue entered succesfully!',
  revenue_register_error: 'There was a problem registering this revenue..',
  revenue_elimination_question: 'Do you want to delete this revenue?',
  revenue_elimination_success: 'Revenue deleted succesfully!',
  revenue_elimination_error: 'There was a problem deleting this revenue..',
  edit_extra_revenue: 'Edit extra revenue',
  delete_revenue: 'Delete revenue',
  revenue_name: 'Revenue name',
  add_revenue_name: "Enter de revenue's name",
  obesity_webinar_example: 'e.g. Obesity webinar',
  revenue_amount: 'Revenue ammount',
  add_revenue_ammount: "Enter the revenue's ammount",
  revenue_category: 'Revenue category',
  select_revenue_category: 'Select the revenue category',
  webinars_example: 'e.g. Webinars',
  webinars: 'Webinars',
  talks: 'Seminars',
  product_sales: 'Product sales',
  salary: 'Salary',
  bonifications: 'Bonifications',
  comissions: 'Comisions',
  other: 'Other',
  revenue_date: 'Revenue date',
  select_revenue_date: 'Please select the revenue date',
  revenue_description: 'Revenue description',
  add_revenue_description: '(Optional) Enter the revenue description',
  'DD/MM/YYYY': 'MM/DD/YYYY',
  // PopupEditAndDeleteExpense
  each: 'Each',
  expense_update_success: 'Expense updated succesfully',
  expense_update_error: 'There was a problem updating this expense',
  expense_elimination_question: 'Do you want to delete this expense?',
  expense_elimination_success: 'Expense deleted succesfully',
  expense_elimination_error: 'There was a problem deleting this expense',
  edit_one_expense: 'Edit only this expense',
  edit_more_expense: 'Edit all expenses',
  delete_expense: 'Delete expense',
  expense_name: 'Expense name',
  add_expense_name: 'Enter the expense name',
  rent_example: 'e.g. Rent',
  expense_amount: 'Expense amount',
  add_expense_ammount: 'Enter the expense amount',
  expense_category: 'Expense category',
  select_expense_category: 'Select the expense category',
  work_tools_example: 'e.g. Work accesories',
  rent: 'Rent',
  secretary: 'Assistant',
  marketing: 'Marketing',
  software: 'Software',
  work_tools: 'Work accesories',
  expense_date: 'Expense date',
  initial_expense_date: 'Expense start date',
  select_expense_date: 'Select the expense start date',
  is_expense_recurrent: 'Is it a recurrent expense?',
  recurrrency: 'Recurrency',
  select_expense_recurrency: 'Select the expense recurrency',
  expense_description: 'Expense description',
  expense_end_date: 'The expense has an end date?',
  select_expense_end_date: 'Select the expense end date',
  select_a_period: 'Select a period',
  type_a_number: 'Enter a number',
  edit_expense: 'Edit expense',
  edit_expenses: 'Edit expenses',
  // PopupNewIncome
  add_extra_revenue: 'Add extra revenue',
  // AddEquivalentsGroup
  select_at_least_one_equivalent_group: 'Please, choose at least one food from the equivalents group.',
  select_at_least_one_equivalent_group_proportion: 'Please, enter the proportion to add the equivalents group.',
  add_equivalents_group_action: 'Add equivalents group',
  add_equivalents_group: 'Add equivalents group',
  select_or_add_equivalents_group: 'Select or enter the equivalents group',
  calories_and_macros_average: 'Calories and macronutrients average',
  no_description: 'No description',
  change_proportion: 'Change proportion',
  have_not_created_equivalents: "You haven't created equivalents yet",
  creating_equivalents_group_first_indication: 'To create your first equivalents group, click in <1>Foods</1> in the lateral bar.',
  creating_equivalents_group_second_indication: 'Next, click the <1>Create Equivalents</1> button',
  creating_equivalents_group_third_indication: 'Then, chose the name of your first equivalence and add the foods you find convenient.',
  creating_equivalents_group_fourth_indication:
    'Right after you finish, click again in this button and you will be able to add it in this meal plan.',
  gram_w_count: 'gram',
  gram_w_count_other: 'grams',
  ounce_w_count: 'ounce',
  ounce_w_count_other: 'ounces',
  // EquivalentsGroups
  my_equivalents: 'My equivalents',
  cenan_equivalents: 'CENAN equivalents',
  smae_equivalents: 'SMAE equivalents',
  // BMIChart
  type_3_obesity: 'Obesity Class 1',
  type_2_obesity: 'Obesity Class 2',
  type_1_obesity: 'Obesity Class 3',
  // ExchangeGroupDisplay
  select_at_least_one_meal: 'Select at least one meal to save changes',
  at_least_one_food: 'Select at least one food to save changes',
  meal_cant_be_unmarked: "This food can't be unmarked",
  select_consumables_food: 'Select what foods the patient can consume in this meal',
  meal_from_group_selected_for_food: ' These are the foods from this group that were selected for this meal.',
  hide_all: 'Hide all',
  apply_changes_in: 'Apply changes in',
  selected_w_count: 'selected',
  selected_w_count_other: 'selected',
  // ExchangeMealPlanTargets
  delete_equivalents_group_error: "The equivalents group couldn't be deleted",
  delete_equivalents_group_question: 'Do you want to delete this equivalents group?',
  delete_equivalents_group_from_day: 'This equivalents group from "<1>{{dayName}}</1>" will be deleted',
  delete_meal_question: 'Do you want to delete this meal?',
  delete_meal_from_day: 'All the meal content from "<1>{{dayName}} will be deleted</1>"',
  equivalents_group_not_assigned: "You haven't assigned equivalents group for this day",
  // GroceriesListPDF
  mark_the_groceries_list_0: 'Mark with a',
  mark_the_groceries_list_1: "the foods you've already bought",
  recipe_ingredients_included: '*The ingredients of the following recipe are included in the groceries list',
  recipe_ingredients_included_other: '*The ingredients of the following recipes are included in the groceries list',
  week_mayus: 'Week',
  selected_weeks: 'Week 1 to {{weeks}}',
  // GroceriesListTable
  was_grouped: 'Was grouped',
  with_comments: 'with comments',
  not_visible: 'Not visible',
  edit_item_warning: 'To edit another item, first finish editing the current item or cancel the action',
  save_item_warning: 'Enter the name, quantity, and unit from the item',
  select_at_least_two_items: 'Select at least two items to group them',
  select_new_items_to_group: 'To group, select the items that have not been previously grouped.',
  delete_all_groceries_list_comments: 'Do you want to delete all the comments from this groceries list?',
  delete_all_comments: 'Delete all comments',
  reset_groceries_list: 'Reset groceries list',
  delete_groceries_list: 'Delete groceries list',
  complete_groceries_list_group_info: ' Complete the information for this group',
  item_was_grouped: 'This item was grouped',
  item_has_comments: 'This item has comments',
  item_hidden: 'This item will not be displayed',
  groceries_comment_example: 'e.g. You can buy it at your local store',
  item_composed_by: 'Ítem composed of',
  original_item: 'Original item',
  ungroup: 'Ungroup',
  hide_selected_items: 'Hide the selected items from the groceries list',
  show_selected_items: 'Show the selected items from the groceries list',
  group_selected_items: 'Group the selected items from the groceries list',
  hide: 'Hide',
  group_together: 'Group',
  items_total: 'Total items',
  // MealPlanDays
  no_foods_found_this_day: 'There are no other meals with foods for this day.',
  go_to: 'Go to',
  // ToggleHeaderNutrientAnalysis
  hide_extra_nutrient: 'Hide extra nutrient',
  show_extra_nutrient: 'Show extra nutrient',
  // MealPlanFoodsTable
  copy_food_or_group: 'Do you want to copy this food or all the group?',
  copy_all_group: 'All the group',
  copy_just_one_food: 'Just this food',
  food_quantity_empty: "The food's quantity can't be empty",
  food_name_empty: "The food's name can't be empty",
  // MealPlanTargets
  how_many_meals: 'How many meals will you have?',
  meals_number_card_title_3: '3 meals',
  meals_number_card_description_3: 'Breakfast, lunch, dinner',
  meals_number_card_title_4: '4 meals',
  meals_number_card_description_4: 'Breakfast, lunch, dinner, snack',
  meals_number_card_title_5: '5 meals',
  meals_number_card_description_5: 'Breakfast, mid-morning, lunch, mid-afternoon, dinner',
  all_weekdays: 'Monday to Friday',
  delete_specific_meal_question: 'Do you want to delete {{mealName}} ?',
  delete_specific_meal_confirmation:
    "Confirm this action if you don't want the patient to have {{mealName}} this day. The foods, comments and meal time will be deleted and can't be recovered",
  copy_from_specific_day_question: 'Copy from {{dayName}} ?',
  copy_from_specific_day_confirmation: 'All the content from {{dayName1}} will be replaced with {{dayName2}} content. This action is irreversible.',
  add_free_days_question: 'Do you want to add days off?',
  copy_in_all_days_question: 'Copy this day into every day?',
  micronutrients_recommendations_warning:
    "If you are using foods that don't belong to the USDA, there may be some distortion in the results. Recomendations from the Institute of Medicine de EEUU.",
  birthday_to_see_recommendations: "To be able to view the patient's recomendations, enter their birth date",
  copying_meal_1: 'Copying meal',
  re_add_to_free_day_0: 'In case you wish to add meals to this day again,',
  re_add_to_free_day_1: 'click in <1>Previous</1> an place a caloric target to the day',
  this_food_has_different_macros: 'This meal has {{macros}}, but you added a target of 0 grams',
  day_without_objectives_per_meal: 'This day has no meal targets. To assign them go to the previous step.',
  values_have_been_rounded: 'Values have been rounded for a better visibility.',
  meal_without_foods: 'This meal has no foods',
  meal_comment_example: 'e.g. Mix the oats with the yogurt',
  to_the_last: 'To the bottom',
  position_to_move_meal_question: 'To what position do you wish to move this meal?',
  move_to_other_position: 'Change position',
  at_the_beggining: 'At the begining',
  prior_to: 'Prior to',
  so_far_any_other_day_has_foods: 'For now no other day has foods',
  copy_to_all_days: 'Copy to all days',
  pin: 'Pin',
  unpin: 'Unpin',
  graph: 'Chart',
  go_back_to_step_2: 'Return to the caloric targets step by clicking "Previous" and reset the caloric target of the day you want to make free',
  day_copy_to_other_day:
    "{{dayName}}'s content will be copied in the rest of the days. If there is information in the other days, it will be replaced. If you have days off, they will not be affected. This action is irreversible.",
  delete_day_content_confirmation: 'All the content from {{dayName}} will be deleted. This action is irreversible.',
  change_to_graph: 'Switch to chart',
  change_to_table: 'Switch to table',
  see_weekly_summary: 'View weekly summary',
  see_day: 'View {{dayName}}',
  seven_model_days_tops: 'This type of plan accepts up to 7 model days', // cambiar
  model_day: 'Model day', // cambiar
  loading_mealPlan: 'Loading meal plan...',
  reset_day_objectives: 'Clear targets for {{dayName}}?',
  everyday: 'Every day',
  tuesday_thursday_short: 'Tue-Thur',
  monday_wednesday_friday_short: 'Mon-Wed-Fri',
  select_boxes_for_objectives: "Select the checkboxes to establish the day's target",
  new_day: 'Add day',
  save_objective: 'Save target',
  save_objective_for_a_day: 'Save target for ',
  save_objective_for_some_days: 'Save target for',
  save_objective_for_all_days: 'Save target for all days',
  meal_plan_targets_pending_title: 'Select the desired days from the table to set a caloric target',
  meal_plan_targets_pending_description:
    'You can go to the next step once you complete this section. The days you leave unfilled will be considered days off.',
  type_caloric_objective: 'Enter the caloric target',
  bmr_formula: 'BMR Formula',
  measurements_tied_to_an_appointment: 'Measurements linked to the appointment from',
  leanBodyMass_formula_restriction: "You need the patient's Lean Muscle Mass to apply this formula",
  activity_level: 'Activity level',
  percentage_to_vary_tdee: 'In what percentage do you want to increase or decrease the TDEE',
  mealPlan_not_tied_to_country_0: 'This meal plan is not linked to an appointment.',
  mealPlan_not_tied_to_country_1: 'For us to estimate an appropiate BMR, you have to enter this data before creating the meal plan',
  mealPlan_not_tied_to_country_2: 'For us to estimate an appropiate BMR, you need at least this data',
  daily_activity: 'Daily activity',
  macronutrients_distribution: 'Macronutrients distribution',
  amdr_recommended_protein: 'The percentage of protein recommended by the AMDR for adults (18 y/o) is 10-35%',
  target_result: 'Result',
  amdr_recommended_carbs: 'The percentage of carbohidrates recommended by the AMDR for adults (18 y/o) is 45-65%',
  amdr_recommended_fats: 'The percentage of fat recommended by the AMDR for adults (18 y/o) is 20-35%',
  distribute_by_mealTime: 'Allocate by meal time',
  put_daily_macronutrients_first: 'Enter the daily macronutrient targets first',
  children_growth_oms_source: 'Source: WHO (World Health Organization)',
  additional_information: 'Additional information',
  bmi_graph: 'BMI Chart',
  bmi_with_prePregnancy_weight: 'BMI is calculated with pre-pregnancy weight',
  somatotype: 'Somatotype',
  somatotype_requirements: 'Various skinfolds and measurements are required in order to visualize {{name}} somatotype',
  measurements_requirements: 'These measurements are required',
  endomorph: 'Endomorph',
  ectomorph: 'Ectomorph',
  mesomorph: 'Mesomorph',
  past_objectives: 'Past targets',
  patient_without_past_objectives: 'This patient has no past targets',
  section_for_objectives_history: "In this section you will be able to view {{name}}'s meal plan targets history",
  dietetic_register: 'Dietary record',
  dietetic_register_shown_because_of_no_mealPlan: 'The patient does not have a past meal plan, so their dietary record will be shown',
  past_mealPlan_without_objectives: 'The past meal plan has no targets completed',
  comments_or_conclusions: 'Summary or comments',
  patient_without_mealPlan_and_register: "doesn't has a meal plan nor a dietary record",
  blocked_nutrients_notification:
    'Since you have two nutrients locked ({{stateName}}), the third nutrient ({{lockedMacro}}) has also been locked, since it could no longer be modified. You can click on any lock to unlock the nutrient.',
  prots_and_carbs: 'Proteins and Carbohydrates',
  prots_and_fats: 'Proteins and Fats',
  carbs_and_fats: 'Carbohydrates and Fats',
  macro_by_kg: 'by {{weightSystem}} of weight. ({{weight}} {{weightSystem_abv}})',
  starts_past: 'started',
  view_bmi_chart_requirements: 'You need to add weight and height in the appointment linked with this meal plan {{-date}} to view the BMI',
  specific_mealplan_date: 'Meal plan data that {{starts}} on {{-date}}',
  // PatientInformationDrawer
  not_registered: 'Not registered',
  see_patient_history: 'View patient history',
  patient_history: 'Patient history',
  patient_basic_data: 'Basic data',
  last_appointment: 'Last appointment',
  patient_diseases_recommendations: 'We recommend this foods for patients with',
  appointment_objective: 'Appointment goals',
  physical_activity_level: 'Physical activity level',
  wakeup_time: 'Wake up time',
  sleep_time: 'Sleep time',
  diet_type: 'Diet style',
  number_of_foods_consumed_daily: 'Meals consumed daily',
  diet_special_conditions: 'Diet conditions',
  no_comments_found: 'No comments found for this appointment',
  select_a_measurement_to_view: 'Select a measurement to see the data',
  see_patient_info_question: 'Do you want to see all the patient information?',
  //PopupDailyNutrients
  daily: 'Daily',
  nutrients_visualization: 'Nutrients view',
  undo_free_day_to_view_macros: 'To visualize nutrients on this day first unset the day off',
  // PopupAddRecipesToMealPlan
  recipe_already_selected: 'This recipe has already been selected',
  add_recipes: 'Add recipes',
  search_and_add_recipes_to_mealPlan:
    "Search and select the recipe you've already added to the meal plan to show their instructions and ingredients in the PDF and the mobile app.",
  search_1: 'Search',
  recipe_example: 'e.g. Grilled cheese sandwich',
  no_more_results: 'No more results',
  selected_1: 'Selected',
  no_recipe_selected: 'No recipes selected',
  // PopupAssignMultipleHours
  toggle_free_day_to_select: 'If you want to select: {{dayName}}, first unset the day off.',
  add_time_and_select_days: 'Enter the time and select the days',
  asign_hours: 'Assign time',
  asign_hours_to_meal: 'Assign a time to the {{mealName}}',
  select_all: 'Select all',
  clear_selection: 'Clear selected',
  at_what_time_will_it_be: 'At what time will it be?',
  assign_multiple_hours_fromEMPT:
    'Enter the time you wish to assign {{mealPronoun}} <1>{{mealName}}</1> from <3>{{dayIndex}}</3>. If this meal already has a time, it will be replaced. If you do not want this meal to be at a specific time, leave this field empty',
  assign_multiple_hours: 'Enter the time and select the days you want with this time. If any of those days has already a time, it will be replaced.',
  // PopupMeal
  here_will_be_the_foods: 'Here you will se the foods you add to this meal',
  day_without_meal_1: '¡This day does not have this meal!',
  day_without_meal_add_food: 'To make the meal appear, just add any food',
  day_without_meal_objectives: 'This day has no targets per meal. To assign them go back to the previous step.',
  without_meal_objectives: 'Without meal targets',
  percentage_of_day: '% of day',
  move_meal: 'Move meal',
  to_day: 'to this day',
  to_food: 'to this food',
  cannot_move_meal_free_day: "You can't move a meal into a day off",
  meal_foods_will_be_replaced: 'If the selected meal has already foods, they will be replaced.',
  // SearchbarDatabaseFilter
  my_createdFoods: 'My created foods',
  my_favoriteFoods: 'My favorite foods',
  system_recipes: "Almendra's recipes",
  other_recipes: "Other user's recipes",
  loading_filters_error: 'We have a problem loading the filters. Please refresh and try again',
  select_one_database_warning: 'You have to select at least one database',
  select_one_database_error: "You haven't selected any databases",
  showing_only_favorite_foods: 'Showing only your favorite foods',
  show_favorite_foods: 'Show my favorite foods',
  show_all_foods: 'Show all foods',
  search_foods_when_writing: 'Search foods while typing',
  search_all: 'Search all',
  current_filter: 'Current filter',
  database_filters: 'Database filters',
  select_filter_method: 'Select the filter method',
  search_foods_when_writing_description: 'Enter the food you want to search for it in the selected databases',
  show_all_foods_description: 'View all the foods from the selected databases without the need to enter them',
  select_at_least_one_database: "You haven't selected any databases",
  how_does_it_work: 'How does it work?',
  // MealPlanTemplatePendingTable
  of_1: 'of',
  no_pending_templates: 'You have no pending templates',
  download_csv: 'Download CSV',
  print: 'Print',
  show_hide_columns: 'Show/Hide Columns',
  delete_selection: 'Delete selection',
  row_s: 'row(s)',
  // OnboardingScheduleCall
  schedule_training: 'Schedule training',
  schedule_your_virtual_training: 'Schedule your online training',
  // BabyFields
  gestation_and_birth_data: 'Gestation and birth data',
  fields_only_for_babies: 'This fields will only be displayed for patients with 4 years or less',
  birthWeight: 'Weight at birth',
  birthHeight: 'Height at birth',
  gestational_age: 'Gestational age',
  extra_comments_about_babies: 'Additional comments abouts gestation and the patient',
  extra_comments_babies_placeholder: 'e.g She was only breastfed until 6 months',
  //ChangeMailInput
  mail_example: 'e.g. lucienne@gmail.com',
  invalid_mail: 'This email address appears to be invalid, please communicate with your patient',
  pending_mail_verification: 'Email pending for verification',
  patient_mail_verified: 'Verified email',
  pending_mail_change: 'Email change pending confirmation',
  pending_mail_change_description: 'The patient still does not accept the change of email sent to',
  undo_change: 'Undo change',
  patient_already_on_app: 'The patient has already entered the app',
  patient_hasnt_entered_to_app: "The patient hasn't yet already entered the app",
  delete_patient_mail_question: "Do you want to delete this patient's email?",
  delete_patient_mail_in_use:
    'This email is being used by the patient to enter the app. If you delete it, the patient will not have access to the app with that email.',
  change_patient_mail_question: "Do you want to delete this patient's email?",
  change_patient_mail_question_content:
    "This patient has logged into the App with the following email: <1>{{mail}}</1>. <3>For security reasons, we will email a link to <5>{{newMail}}</5> so that the patient can confirm the email change.</3>Until then, the patient will be able to log into the App with the old email. All email will be sent to <7>{{mail}}</7> until the patient accepts the change. An icon <9>{{icon}}</9> will appear next to the patient's email, indicating that the patient hasn't yet confirmed the email change.",
  change_not_verified_patient_mail:
    'You have sent the patient access email. Previously, you sent the access email to the App to the patient but they have not yet entered the App. By accepting, the emails sent to access their account will be invalidated.',
  resend_patient_verification_mail_question: 'Do you want to send the patient access email again?',
  resend_patient_verification_mail_body: 'You previously sent the invitation to the App to this patient but he has not yet entered the App.',
  resend_patient_verification_new_mail_question: 'Do you want to send the patient access email again with this new email?',
  resend_patient_verification_new_mail_body_1: 'You previously sent the patient access email to this email: {{mail1}}',
  resend_patient_verification_new_mail_body_2: ', a new patient access email will be sent to {{mail2}}.',
  // ChartMeasurements
  click_in_add_measurements: "Click on <1>Add measurements</1> to enter this appointment's measurements.",
  select_or_add_measurement: 'Select or enter the measurement',
  all_time_high_n: 'Historic\nMaximum',
  all_time_low_n: 'Historic\nMinimum',
  total_variation_n: 'Total\nChange',
  last_measurement_n: 'Last\nMeasurement',
  empty_measurement: 'No records',
  patient_without_specific_measurement:
    "This patient doesn't yet have records of this measurement. To visualize the chart and the relevant values, remember to measurement it the next appointment.",
  to_register_a_measurement_go_back:
    "To enter a measurement, first go back to the appointment by clicking on the timer in the sidebar. Then click on 'Add measurements'",
  // ChartMeasurements
  limit: 'Limit',
  hours_per_week_detail: 'Hours per week',
  // PatientMealPlans
  about_to_edit_mealPlan_date: 'You are about to edit a meal plan date, and it could have consecuences',
  about_to_edit_mealPlan_date_confirmation:
    'If you edit this meal plan date, this patient will become an active patient, do you want to do it anyway?',
  start_date_successfully_edited: 'Start date updated succesfully',
  delete_mealPlan_question: 'Do you want to delete this meal plan?',
  mealPlan_error_title: 'There was an error with this meal plan',
  plan_name: 'Meal plan name',
  mealPlan_not_tied_to: 'Meal plan not linked to', //cambiar
  mealPlan_ended: 'Meal plan ended',
  appointments_process_mayus: 'In process',
  started_today: 'Started today',
  started: 'Started',
  starts: 'Starts',
  foods_filling: 'Foods filling', // cambiar
  in_process_blank_mealPlan: 'In process - Text meal plan',
  pending_finish: 'Pending to finish', // cambiar
  caloric_objectives: 'Caloric targets',
  recently_created: 'Recently created',
  view_pdf: 'View PDF',
  theres_an_appointment_ongoing: 'There is an appointment in process!',
  i_want_to_start_now: 'I want to start now',
  ill_do_it_later: 'I will do it later',
  start_filling: 'Start now',
  start_mealPlan_after_appointment:
    'You can start this meal plan after the appointment. If you add one meal plan, it instantly becomes a pending meal plan',
  finish_editing: 'Finish editing',
  mealPlan_1: 'meal plan...',
  patient_without_mealPlans_yet: "You haven't assigned a meal plan to this patient yet",
  adding: 'Adding',
  deleting: 'Deleting',
  mealPlan_attached: 'This plan is attached in PDF Format',
  mealPlan_with_workout: 'This plan has a workout included',
  mealPlan_with_no_appointment: 'Meal plan not linked with and appointment',
  mealPlan_linked_with_appointment: 'Meal plan linked to the appointment from',
  // PatientsList
  patients: 'Patients',
  loading_patients: 'Loading patients...',
  active: 'Active',
  inactive: 'Inactive',
  active_patient_short_description:
    'Has had at least one appointment or a meal plan in this subscription period (month). For more information, go to the subscription section in Settings.',
  inactive_patient_short_description: "Hasn't had an appointment or a meal plan in this subscription period (month)",
  // PatientsStatDisplay
  patient_stats_display_description:
    'Appointments scheduled but not executed . If a patient scheduled an appointment in march and april, but they only went to the one on march, this number will be 50%. If they went to both, it will be 100%',
  // PendingPatientModal
  accept_patient_request_question: "Do you want to accept this patient's request?",
  requested_to_be_your_patient: 'has requested to be your patient.',
  decline_pending_patient_request_description: "The patient will dissapear from the pending patient's list",
  accept_pending_patient_request_description: 'This patient and all their information will be added to your database',
  reject: 'Reject',
  // PopupDietRecord
  diet_record_short_description: 'This section is to log the patient food-related activities in the last 24h/48h.',
  diet_record_first_indication: 'Start adding meal by clicking "Add meal"',
  meal_title_example: 'e.g. Breakfast',
  diet_record_comment_example: 'e.g. Avocado toast, he feels full after eating it',
  meal_hour_example: '8:30am',
  add_meal: 'Add meal',
  optional: 'Optional',
  // PopupPatientTags
  put_tags_to_your_patients: 'Add tags to your patients according to certain characteristics',
  patient_tag_example: 'e.g. Severe Acne',
  add_personalized_tags_description:
    'You can add tags that are not included in the list. We remind you that your patient will not be able to see their tags.',
  suggestions: 'Suggestions',
  no_tags_suggestions: "We couldn't find a tag suggestion, but you can add any tag you want",
  // PregnancyOrNursingFields
  example_short: 'e.g.',
  edit_field_warning: 'You are about to edit this field. If you do, the saved information will be lost.',
  special_conditions: 'Special conditions',
  pregnancy_mayus: 'Pregnancy',
  nursing_mayus: 'Lactating',
  none_mayus: 'None',
  none: 'none',
  last_menstruation_date: 'Last menstruation date',
  date_with_more_than_10_months:
    'The date you selected is more than 10 months old, try to make it more recent so that the current condition of the patient is pregnant',
  special_conditions_comment_example: 'e.g. Severe gastritis at the beginning of the third quarter',
  special_conditions_comment_example_1: 'e.g. The first months were just breastfeeding, then infant formulas were added',
  system_data_will_change_with_special_condition:
    'Software data, such as calorie requirements, will change since the patient is pregnant. To calculate the BMR correctly, it will be necessary to add the pre-gestational weight',
  currently_pregnant: 'Currently pregnant',
  currently_nursing: 'Currently lactating',
  nursing_startDate: 'Lactation start date',
  nursing_duration: 'Lactation duration',
  month_other: 'months',
  // TableMeasurements
  'N/A': 'N/A',
  add_measurement_question: 'Do you want to add a measurement?',
  change_measurement_question: 'Do you want to edit this measurement?',
  current_value: 'Current value',
  new_value: 'New value',
  used_to_find_other_measurements: 'it is used to calculate other measurements',
  empty: 'Empty',
  other_measurements_will_be_modified: 'Other measurements will be modified',
  yes_change_measurement: 'Yes, change measurement',
  actual_value_cannot_backup_other: 'Current values will not be able recovered',
  measurements_history: 'Measurements history',
  search_for_a_measurement: 'Search for a measurement',
  last: 'Last',
  patient_with_no_measurements_yet_warning: "You haven't added measurements for this patient",
  register_measurements_during_appointment:
    'Register measurements during an appointment in the <1> Measurements </1> section (step 2). <2 /> The purpose of this table is to correct any possible errors after the patient is not with you. <3 /> < 4 /> Click on this button to go to the measurements section: ',
  change_measurement_confirmation: 'The value of the measurement: <1>{{measurementName}}</1> entered on {{-date}} will change',
  // ExchangeMealPlanDemoPDF
  exchange_mealPlan_demo_title: 'Exchange meal plan - DEMO',
  // CustomForm
  form_with_at_least_one_field_error: 'The form need at least one field.',
  custom_form_edit_error: 'There was an error editing the from. If the problem persist, please contact us.',
  custom_form_edit_success: 'Form succesfully updated',
  custom_form_create_error: 'There was an error creating the from. If the problem persist, please contact us.',
  custom_form_create_success: 'Form created succesfully',
  other_personal_data: 'Other personal data',
  form: 'Form',
  create_form: 'Create form',
  setup_form_description:
    'Set up the form that your patient will receive before their appointment so that they can fill it out. You can choose the fields you want.',
  form_name: 'Form name',
  form_name_validator: 'Enter the form name.',
  form_name_example: 'e.g. Form for ongoing appointments',
  add_field: 'Add field',
  // ExistingPatientForm
  enter: 'Enter',
  forgot_password_add_code: "I don't remember my password, log in with a temporal code",
  // MultipleLinksEdit
  fill_field_before_adding_new_one: 'Please, fill the previous field before adding a new one',
  type_a_valid_link: 'Please, enter a valid link',
  organize_your_links_into_one: 'Organize your multiple links in a single one with Almendra.',
  why_is_it_useful: 'Why is this useful?',
  organize_your_links_into_one_description:
    "In a single link you can have your Facebook page, the link of your online store, your e-book, your home page, etc. When you're done, copy the generated link and paste it wherever you want (for example, in your Instagram bio).",
  multiple_links_example: 'e.g Facebook Page',
  delete_field_question: 'Do you want to delete this field?',
  preview_in_laptop: 'Preview',
  // ShowAgenda
  fill_info_to_enable_feature: 'Fill up the following information to enable this feature:',
  and_update_this_settings: 'and update this settings.',
  enter_to: 'Enter to',
  invalid_credentials: 'Invalid credentials',
  credentials_verify_error: 'Invalid credentials',
  slot_occupied_while_waiting: 'The selected schedule was taken while making the reservation. Choose another time.',
  fill_all_data_for_scheduling: 'Complete the information to make the reservation',
  slot_occupied_while_waiting_error: 'Someone else booked these hours while we confirmed the reservation. Please try again',
  mail_is_already_registered_error: 'The email used is already entered. Please try another email.',
  transaction_failed_message: 'The transaction could not be completed',
  transaction_failed_message_next_steps:
    'Verify that you have entered the card details correctly, that it has an available balance and is enabled for online payment. Contact your bank for more information.',
  booking_failed: 'The reservation could not be made',
  booking_failed_description: 'Looks like there was a problem booking the appointment. Please refresh the page and try again.',
  mail_already_registered_as_patient: 'This email is already registered as a patient',
  mail_not_in_expert_database: "This email doesn't exist in your expert's database",
  verify_your_mail: 'Verify your email',
  wrong_code: 'Wrong code',
  code_verified_success: 'Mail verified succesfully',
  type_the_code_sent_to_mail: 'Enter the code sent to ',
  no_pending_recipes: 'You have no pending recipes',
  select_healthPlan: 'Select the health plan',
  healthPlan_selection: 'Health plan selection',
  fill_in_your_data: 'Fill your data',
  confirm_your_booking: 'Confirm your booking',
  make_the_payment: 'Make the payment',
  check_your_mail_and_confirm_booking: 'Check your email and confirm the appointment',
  booking_scheduled_succesfully: 'Appointment scheduled succesfully',
  aprox_minutes: 'minutes aprox',
  go_to_my_account: 'Go to my account',
  autorization: 'Authorization',
  im_a_patient: "I'm already a patient",
  im_not_a_patient_yet: "I'm not a patient yet",
  booking: 'Booking',
  identification: 'Identification',
  you_have_this_healthPlan_in_progress: 'You currently have the following health plan in process',
  healthPlan_is_all_booked:
    'Every appointment from the current service hase been scheduled. You will be able to schedule a new appointment when the current service ends.',
  booking_one_appointment_from_healthPlan: 'One appointment from the service will be use to continue with the reservation.',
  appointment_time: 'Appointment time',
  fill_your_data_and_book: 'Fill your data and book the appointment',
  next_steps: 'Next steps',
  custom_form_info_for_patient_booking:
    'You will receive an email with a link for you to enter your eating habits in a form. The purpose of this form is to help you achieve your goals. Make sure to complete it before the date of your appointment.',
  booking_politics: 'Booking politics',
  booking_politics_description: 'As a courtesy, in case of canceling the reservation be sure to notify your professional in advance.',
  confirming_your_booking: 'Verifying your booking...',
  appointment_with: 'Appointment with',
  no_service_selected: "You haven't selected any service",
  paypal_total: 'PayPal Total',
  pay: 'Pay',
  pay_later: 'Pay later',
  make_payment: 'Make payment',
  confirm_and_book: 'Confirm and book',
  book_apointment: 'Book appointment',
  patient_wants_to_book: "You're {{name}}'s patient and you want to book an appointment",
  not_patient_wants_to_book: "You're not {{name}}'s patient and you want to book an appointment",
  booking_appointment_in_healthplan: 'Scheduling appointment {{singular}} of {{total}}',
  change_healthPlans_privacy:
    "You haven't set the privacy of your services to <1>public</1>. At <3><5>Settings - Services</5></3> you can do that change, so people can view what services you offer.",
  hours_selection: 'Hours selection',
  no_booking_communicate_with_expert: 'Currently {{firstName}} is not booking appointments by this channel, contact {{firstName}} at {{mail}}.',
  validation_and_confirmation: 'Review and confirmation',
  all_healthPlan_appointments_booked:
    'Every appointment of your current service has been scheduled. You will be able to book a new appointment when your current service has finished.',
  // PopupOnboarding
  congrats_for_choosing_us: 'Congratulations for choosing Almendra!',
  what_are_you_looking_for: 'What are you looking for in Almendra?',
  tell_us_about_you: 'Now, tell us a little about you',
  woman: 'Woman',
  man: 'Man',
  what_are_you_looking_for_example: 'Create meal plans faster? Give your patients a better experience? Have more order in your practice?',
  nationality_1: 'Nationality',
  select_nationality: 'Please, select your nationality.',
  select_birthDate: 'Please, select your birth date.',
  select_phone: 'Please, enter your phone number',
  select_valid_phone: 'Please, enter a valid phone number.',
  select_to_continue: 'Please select one of the options to continue',
  im_an_expert: "I'm an expert in nutrition",
  im_a_student: "I'm a nutrition student",
  i_want_a_diet: 'I want a diet',
  easy_mealPlans: 'Create meal plans easier',
  more_order: 'Have more order in my patients data',
  better_experience: 'Give my patients a better experience',
  save_time: 'Save time in my day to day',
  at_least_5_characters: 'Please enter at least 5 characters',
  identify_yourself_options: 'With which of these options do you identify yourself?',
  enter_university: 'Enter your university',
  enter_university_placeholder: 'e.g. Nutritional Sciences in University of Wisconsin-Stout',
  what_are_you_looking_for_mealPlan: 'Please tell us what you are looking for in your meal plan',
  what_are_you_looking_for_mealPlan_placeholder: 'e.g. That it is not very expensive, flexible meals, recipes, etc.',
  expert_with_default_food_sources: 'Since your country is <1>{{country}}</1>, we will automatically add the following databases:',
  expert_without_default_food_sources:
    "We haven't yet incorporated the food composition database of your country. In the meantime, we put at your disposal the official database of the <3>USDA</3>",
  onboarding_submit_error: 'Oops! There was a problem. Please, reload the site and try again',
  b2c_desired_goal: 'What goal do you have?',
  b2c_goal_gain_muscle_mass: 'Gain muscle mass',
  b2c_goal_lose_fat: 'Lose fat',
  b2c_goal_be_more_healthy: 'Be healthier',
  b2c_usage_interest: 'How much would you be willing to pay for your personalized eating plan, prepared by one of our professionals?',
  b2c_payment_intent_none: 'Nothing',
  b2c_usage_interest_message: "All of our professionals' patients see significant progress in the first 14 days of treatment.",
  b2c_download_superfit: 'Thanks for you answer! You can find your personalized diet with our partner <1>Superfit</1>, download the App here:',
  // PopupCardComission
  why_we_ask_this: 'Why do we ask this?',
  importance_regarding_card_comissions: 'It is very important to consider the commission of credit or debit cards for these reasons',
  importance_regarding_card_comissions_reason_1: "It's money you don't actually receive",
  importance_regarding_card_comissions_reason_2: 'When viewing your finances or statistics, you will see only the money that corresponds to you',
  what_is_average_comission: "What does 'average comission' means?",
  average_comission_example:
    'Imagine that you use two POS, one for each brand of card. The commission of one POS is 3%, while that of the other is 5%. In this case, you will need to enter 4% in the field.',
  // SecuritySettings
  see_more: 'See more',
  associated_accounts: 'Linked accounts',
  linked_account: 'Account linked',
  unlinked_account: 'Account not linked',
  // VisualSettings
  image_succesfully_updated: 'Image succesfully updated',
  image_upload_error: 'There was an error uploading the image',
  lateral_bar: 'Lateral bar',
  upload_logo: 'Upload logo',
  delete_logo: 'Delete logo',
  change_text: 'Change text',
  reduce: 'Reduce',
  enlarge: 'Enlarge',
  topic: 'Topic',
  primary_color: 'Primary color',
  // ZoomLinkAccount
  zoom_unlink_title: 'Do you want to unlink your Zoom account with Almendra?',
  zoom_link_title: 'Do you want to link your Zoom account with Almendra?',
  zoom_link_automation:
    'When scheduling appointments online you can select if you want to create the room with Zoom, the link of the room will automatically be sent to the patient.',
  zoom_redirect: 'We will redirect you to the Zoom page so that you can perform this operation.',
  // IncomeByModality
  Presencial: 'Face-to-face',
  'A Domicilio': 'At home',
  Online: 'Online',
  // ErrorReload
  network_error_happened: 'Network error..',
  // FoodsTable
  user_measurements: 'My units',
  other_user_measurements: 'Other units',
  // LikedFoods
  liked_foods_title: 'Liked foods',
  disliked_foods_title: 'Disliked foods',
  allergic_foods_title: 'Allergic foods',
  // Pal Calculation
  mets_cant_exceed_24_hours: `The activities with METs of each day cannot pass 24 hours`,
  mets_cant_exceed_24_hours_specific_day: `Activities with METs for the day {{dayName}} cannot pass 24 hours`,
  // MetsModal
  add_days_with_activity: 'Add the days on which the activity takes place',
  add_activity_hours: 'Add how many hours and minutes the activity lasts',
  sport_lowercase: 'sport',
  times_per_week_uppercase: 'Times per week',
  mets_per_hour: 'METs/hour',
  dummy_activity_description: 'This activity is a wild card that will help you complete the daily hours, with a MET of 1.3',
  activities: 'Activities',
  mets_week_avg_description:
    'This number is the general average of the week, if you have activities on scattered days, it will not equal the exact sum of the hours',
  delete_activity_question: 'Do you want to delete this activity?',
  activity_list: 'Activities list',
  add_and_edit_activities:
    "Add and edit the activities corresponding to the patient's day-to-day life, in order to calculate a more precise activity level.",
  daily_mets: 'Total METs per day:',
  see_added_hours: 'See added hours detail',
  hide_added_hours: 'Hide added hours detail',
  no_activity_added_yet: "You haven't added any activities for this patient yet",
  add_activity: 'Add activity',
  add_activity_ph: 'Enter any activity...',
  no_activities_found: 'No activities found for the search',
  // DeleteConfirmationModal
  delete_confirmation_modal: 'To confirm this action, type "delete" in the next field',
  delete_keyword: 'delete',
  // NoData
  no_data: 'No data',
  // WaitToResendTimer
  try_again_in: 'Try again in',
  resend_mail: 'Resend email',
  // ServiceHoursDisplay
  modalities: 'Modalities',
  // ServiceHoursEdit
  add_bussiness_hours: 'Add hours',
  drag_and_drop_business_hours: 'Click on any cell and drag down to set your business hours.',
  generalSettings_businessHours: 'Business Hours',
  enter_average_duration: 'You have not entered the average appointment duration',
  at_least_one_modality: 'You must have at least one of the following appointment methods',
  at_least_one_address: 'have at least 1 appointment address',
  no_modalities_available: 'You do not have appointment modalities available',
  find_servicehours_configuration_1:
    'In <1> <3> General Settings </3> </1> you can find these fields in the sections "<5> Appointments </5>" and "<7> Business hours </7>',
  find_servicehours_configuration_2: 'In <1> <3> General Settings </3></1> you can find these field in the section "<5>Appointments</5>',
  // WorkoutSelectModal
  routine_created_successfully: 'Workout created succesfully',
  assign_workout: 'Assign workout',
  assign_workout_routine: 'Assign workout',
  select_template: 'Select a template',
  use_workout_template: 'Use a workout template',
  duplicate_workout: 'Duplicate workout',
  delete_workout: 'Delete workout',
  workout: 'Workout',
  delete_ongoing_appointment_question: 'Do you want to delete this appointment in progress?',
  // AppointmentMain
  back: 'Previous',
  specific_patient_appointment_deleted: '{{patientName}} appointment deleted.',
  measurements: 'Measurements',
  // PopupPatientsLimitGraceEnded
  plan_limit_exceeded: 'You have exceeded your plan limits',
  upgrade_your_subscription: 'Upgrade your subscription to continue using Almendra',
  select_my_billingPlan: 'Select my plan',
  plan_limit_description:
    'Your subscription to {{planName}} has a limit of {{patientsLimit}} active patients. Currently you have {{activePatients}}.',
  // Popupeditcustommeasurements
  edit_custom_measurement_add_unit: 'Please, enter the name of the unit you want to create',
  edit_custom_measurement_add_valid_name: 'Please, enter another name for this unit',
  edit_custom_measurement_add_weight: 'The weight (in grams) has to be bigger than zero',
  custom_measurement_already_exists: "There's already a unit with this name",
  no_changes_made: 'No changes made',
  edit_custom_measurement_question: 'Do you want to edit this unit?',
  edit_custom_measurement_disclaimer:
    'If you have used this unit for a food in a meal plan, template, group of equivalences, recipe or favorite foods, it will be updated with the new changes.',
  edit_custom_measurement_confirmation: 'Yes, edit unit',
  edit_custom_measurement_success: 'Unit succesfully updated',
  edit_custom_measurement_error: 'An error occurred editing the unit. Please try again.',
  edit_custom_measurement: 'Edit unit',
  custom_measurement_for_food: 'Unit for the food',
  unit_name_singular: 'Unit name (in singular)',
  add_unit_name_singular_disclaimer: 'Remember to include only the name of the unit and not the quantity.',
  add_unit_name_error: 'You have entered a number in the unit name. Unit quantities are not put in this place.',
  add_unit_name_error_description:
    'For example: if you want to create the unit "2/3 cups" you will have to write "cup" as the name of the unit. After having created it, you will be able to choose that the amount is "2/3".',
  weight_for_one_unit: 'Weight (in grams) for 1',
  // CreatedFoodsTable
  delete_food_question: 'Do you want to delete this food?',
  food_delete_error: 'An error happened while deleting the the food. Try again',
  created_foods: 'Created foods',
  restore: 'Restore',
  no_created_foods: "You haven't created foods",
  create_custom_food_description:
    'If you cannot find a food in the different databases that we provide, you can create a food manually and we will incorporate it privately for your next searches.',
  // CustomMeasurementsTable
  delete_custom_measurement: 'Do you want to delete this unit?',
  delete_custom_measurement_success: 'Unit succesfully edited',
  delete_custom_measurement_error: 'An error occurred deleting the custom measurement. Please try again.',
  search_custom_measurement: 'Search unit',
  created_custom_measurements: 'Created units',
  no_created_custom_measurements: "You haven't created any units",
  create_custom_measurement_description: "If you can't find a unit for a certain food, you can create your own unit and use it in your plans.",
  //Header
  logout_modal_title: 'Do you want to log out?',
  logout_modal_ok: 'Yes, log out',
  logout_modal_no: 'No',
  chat_deactivated: 'The customer support chat is temporary disabled due to technical problems',
  home: 'Home',
  report: 'Report',
  chat: 'Chat',
  schedule: 'Schedule',
  tours_and_videos: 'Tours and videos',
  custom_fields: 'Custom fields',
  with: 'with',
  new_mealPlan: 'New meal plan',
  edit_mealPlan: 'Edit meal plan',
  view_mealPlan: 'View meal plan',
  view_template: 'View template',
  edit_template: 'Edit template',
  view_recipe: 'View recipe',
  workouts: 'Workouts',
  settings: 'Settings',
  notification: 'Notifications',
  mailbox: 'Mail',
  introduction: 'Get started',
  plans: 'Plans',
  diets_minus: 'diets',
  appointment_minus: 'appointment',
  patients_minus: 'patient',
  report_minus: 'report',
  templates_minus: 'templates',
  diets: 'Meal Plans',
  profile_and_configuration: 'Profile and settings',
  help: 'Help',
  novelties: 'Novelties',
  log_out: 'Log out',
  show_chat: 'Show chat',
  hide_chat: 'Hide chat',
  help_center: 'Help center',
  // BlogsHome
  delete_publication_question: 'Do you want to delete this publication?',
  publications: 'Publications',
  no_publications_found: 'No publications found',
  soon_you_will_see_expert_publications: 'Soon you will be able to see the publications of',
  created_in: 'Created on',
  appointment_locations: 'Appointment locations',
  school_association: 'School association', // cambiar
  publication: 'publication',
  publication_other: 'publications',
  in_total: 'in total',
  // MealPlanMain
  foods_blankText: 'Foods - Text meal plan',
  lose_weight: 'Lose weight',
  gain_weight: 'Gain weight',
  to_be_determined: 'To be determined',
  categorize_by_progress: 'By progress',
  // PersonalPageBlog
  share_blog_link: 'This is the link of my publication',
  empty_article: 'The content of this publication is empty',
  of_reading: 'or reading',
  // ServicesMain
  manage_your_healthPlans: 'Manage your services',
  succesfully: 'succesfully',
  // PersonalPageHome
  description_updated: 'Description updated',
  school_association_updated: 'School association updated',
  business_hours_updated: 'Business hours updated',
  delete_profile_picture: 'Do you want to delete your profile picture?',
  short_description_of_you: 'Short description about you',
  school_association_code: 'School association code',
  appointments_personalPage: 'appointment',
  appointments_personalPage_other: 'appointments',
  // PersonalPageMain
  whatsapp_predetermined: 'Hi! What services do you offer?',
  blog: 'Blog',
  view_all_publications: 'View all publications',
  go_back_to_dashboard: 'Go back to dashboard',
  go_back_1: 'Go back',
  // ReferralNotification
  invite_colleagues: 'Invite your colleagues',
  invite_colleagues_description: 'Invite people who do not yet have an Almendra account to register through your link.',
  offer_them_a_discount: 'Give them a discount',
  offer_them_a_discount_description: 'All your referrals will have a 20% discount in their first month.',
  receive_a_discount: 'Receive a lifetime subscription discount!',
  receive_a_discount_description: 'When each colleague subscribes to any plan at Almendra, you will receive a 20% discount for life.',
  new_1: 'New',
  referal_program: 'Referrals program',
  go_to_referrals: 'Go to referrals',
  // ExpertReferrals
  register_with_my_link_and_win: 'Have you tried Almendra? Sign up with my link and get up to a $29.99 in your first month',
  invitation_sent_success: 'Invitation succesfully sent',
  invitation_sent_to: 'The invitation was sent to',
  invitation_sent_error: "The invitation couldn't be sent to",
  copy_the_link: 'Copy link',
  invite_colleagues_and_win: 'Invite you colleagues and get up to a 100% discount for life (free software forever)',
  invite_colleagues_and_win_description:
    'For each subscribed colleague you invite, you will earn an additional 20% discount off on your billing, <1>forever!</1> <3>Read the terms</3>',
  share_almendra_with_colleagues: 'Share Almendra with your colleagues',
  sent_a_mail_to_you_colleagues: "Sent a email to colleagues that don't use our software and invite them with your link",
  enter_mail: 'Enter the email address',
  enter_one_mail_at_the_time: 'Enter one email address at the time',
  referral_follow_up: 'Follow up for your referrals',
  mail_invitations: 'Invitations sent by email',
  benefits_received: 'Current benefits',
  show_more_info: 'Show more information',
  how_referrals_work: 'How does the referral program work?',
  frequent_questions: 'FAQ',
  search_for_faq: 'Find answers for the most common questions and find more information in',
  referrals_faq_q1: 'What will my colleague receive when I send the invitation?',
  referrals_faq_a1: 'When your colleague registers with your link. He will receive and additional 20% discount on their first month',
  referrals_faq_q2: "A friend invited me to join Almendra, but I haven't received any coupon",
  referrals_faq_a2: "You don't need a coupon or code, just to register with the link your colleague gave you.",
  referrals_faq_q3: "I have invited a colleague, but I don't have my discount yet",
  referrals_faq_a3:
    'You will receive a 20% discount in your monthly subscription as long as your referral has an active subscription. To see your referrals in detail, click on',
  your_benefits: 'Your benefits',
  your_referrals: 'Your referrals',
  your_referrals_discount: 'of discount in your current subscription',
  no_referrals_yet: "You haven't yet referred any colleagues",
  invited: 'Invited',
  registered: 'Registered',
  subscribed: 'Subscribed',
  pending: 'Pending',
  you_sent_a_mail_invitation: 'You sent an invitation by email',
  registered_with_your_link: 'Enter with your link',
  has_an_active_subscription: 'Has an active subscription',
  benefit_was_removed: 'The benefit was removed on {{-date}}',
  benefit_will_be_removed: "The benefit will be removed in {{-date}} if their suscriptions doesn't remain active.",
  // RecipeCreate
  delete_this_recipe_question: 'Do you want to delete this recipe?',
  recipe_was_eliminated: 'The recipe was deleted',
  finished_this_recipe_question: 'Do you finished the recipe?',
  finished_this_recipe_desctiption: 'If you confirm this action, you will be able to add the recipe to your meal plans',
  recipe_Was_finished: 'You finished the recipe ',
  pending_recipe: 'Recipe: {{recipeName}} is not finished yet',
  cant_edit_a_finished_recipe: "You can't edit a finished recipe!",
  recipe_updated_succesfully: 'Recipe updated succesfully',
  edit_only_recipes_you_created: 'You can only edit recipes you have created',
  error_changing_recipe_ingredients: 'Oops!There was an error changing the ingredients order',
  complete_the_instruction: 'Finish the instruction',
  enter_recipe_portions_warning: "You need to enter a number of portoins, en case you don't, the default value will be 1",
  edit_someones_recipe_warning: "You seem to be editing a recipe that doesn't belong to you",
  recipe_name: 'Recipe name',
  finished_recipe: 'Finish recipe',
  delete_recipe: 'Delete recipe',
  ingredients_for_this_recipe: 'Point out the ingredientes for this recipe',
  optional_ingredient_question: 'Do you want to add an optional ingredient? ',
  without_optional_ingredients: 'No optional ingredients',
  optional_ingredient_example: 'Enter an optional ingredient. e.g. 1 dash of salt',
  follow_steps_to_prepare_recipe: 'Show how to make the recipe step by step',
  warning: 'Warning',
  yes_finished: 'Yes, I have finished',
  // Sidebar
  go_to_appointment_1: 'Go to the appointment',
  // PatientAuthForm
  patients_login_title: 'Patients',
  log_in_as_patient: 'Log in as a patient',
  im_dietitian: "I'm a nutrition expert",
  // PlanRestrictionModal
  upgrade_your_plan: 'Upgrade you plan',
  upgrade_for_this_feature: 'This feature is not available for your current plan',
  // PlanRestrictionHOC
  feature_disabled_for_free_plan: 'This feature is disabled for the Free Plan',
  feature_disabled_for_basic_plan: 'This feature is disabled for the Basic Plan',
  feature_disabled_for_advanced_plan: 'This feature is disabled for the Advanced Plan',
  feature_disabled: 'This feature is disabled',
  feature_disabled_free: 'This feature is only available in the Pro Plan',
  // WorkoutsMain

  //WorkoutsTable
  no_workouts_yet: 'You have not created workouts yet',

  duplicate_workout_question: 'Do you want to duplicate the workout:',
  duplicate_workout_content: "All the workout's content will be duplicated",
  workout_duplicated: 'The workout: {{name}} was duplicated.',
  delete_workout_question: 'Do you want to delete the workout: ',
  workout_deleted: 'The workout: {{name}} was deleted.',
  workout_templates: 'Workout Templates',
  no_workouts_available: 'No workouts available',
  by_goal: 'By goal',
  by_type: 'By type',
  // StatsMain
  week_of_the_year: 'Week {{weekNo}} of the year',
  in_the_last_custom_days: 'in the last {{name}} days',
  the_last_custom_days: 'the {{name}} previous days',
  go_to_finance: 'Go to finance',
  no_patients_with_healthPlans_stat: "You currently don't have patients who have purchased health plans.",
  go_to_patients: 'Go to patients',
  patients_provenance: "Patient's arrival source",
  provenance: 'Source',
  no_patients_with_provenance: "You haven't yet added where your patients come from",
  patients_by_healthPlan: 'Patients by health plan',
  signed_up_patients: 'patient subscribed',
  signed_up_patients_other: 'subscribed patients',
  finished_mealPlans: 'Finished meal plans',
  no_mealplans_done: "You haven't yet completed meal plans",
  go_to_diets: 'Go to meal plans',
  income_by_modality: 'Revenue by method', //cambiar palabra revenue
  no_income_gained: "You haven't earned any revenues from appointments yet",
  no_patients_registered: "You haven't yet added patients",
  patients_by_sex: 'Patients by sex', //cambiar palabra sex?
  most_frequent_patients: 'Most frequent patients',
  no_appointments_done: "You haven't yet made appointments",
  mealPlans_by_objective: 'Meal plans by goal',
  reutilized_templates: 'Reused templates',
  no_reutilized_templates_yet: "You haven't yet reused templates",
  appointments_performed: 'Appointments made',
  patients_by_age: 'Patients by age',
  patients_by_tags: 'Patients by tags',
  clasification_by_tags: 'Clasification by tags',
  no_tags_added: "You haven't yet added tags",
  hours: 'Hours',
  appointment_average_duration: 'Appointment average duration',
  appointment_accumulated_duration: 'Appointments accumulated duration',
  mealPlan_average_duration: 'Meal plan creation average duration',
  mealPlan_accumulated_duration: 'Meal plan creation accumulated duration',
  scheduled_appointment_assistance_ratio: 'Appointment assitance ratio ',
  appointments_scheduled: 'scheduled',
  from: 'From',
  to: 'to',
  // ChildrenData
  patient_in_percentile: 'The patient is found in the percentile',
  result_out_of_range: 'Result is not between the expected range of values',
  height_for_age: 'Height for the age',
  height_required: 'Height is required for this analysis',
  measures: 'measures', //is,
  height_and_weight_required: 'Height and weight are required for this analysis',
  weight_for_age: 'Weight for the age',
  weights: 'weights',
  weight_required: 'Weight is required for this analysis',
  weight_for_height: 'Weight for the height',
  imc_for_age: 'BMI for the age',
  imc_required: 'BMI is required for this analysis',
  has_imc: 'has a BMI',
  headCircumference: 'Head Circumference', // cambiar o revisar si está bien
  headGirth_required: 'Head girth is required for this analysis',
  has_a_headGirth_that_measures: 'has a head circumference that is',
  armCircumference: 'Arm Circumference',
  armGirth_required: 'Arm girth is required for this analysis',
  has_a_armGirth_that_measures: 'has an arm circumference that is',
  the_boys: 'the boys',
  the_girls: 'the girls',
  less: 'less',
  smaller: 'smaller',
  more: 'more',
  bigger: 'bigger',
  he: 'he',
  her: 'her',
  childenData_main_text:
    'A {{percentile}}% of {{gender}} of the same age and sex {{measure}} equal to or {{less}} than {{genderPronun}} and a {{otherPercentile}}% {{measure}} {{more}} that {{genderPronun}}',
  childenData_main_text_2:
    'A {{percentile}}% of {{gender}} of the same height weighs equal to or less than {{genderPronun}} and a {{otherPercentile}}% weighs more than {{genderPronun}}',
  // SystemTags
  baby: 'Baby',
  newborn: 'Newborn',
  kid: 'Kid',
  teen: 'Teen',
  with_acne: 'With acne',
  sportsman: 'Sportsman',
  elderly: 'Elderly',
  pregnant: 'Pregnant',
  postpartum: 'Postpartum',
  menopausal: 'Menopausal',
  pre_operative: 'Pre_operative',
  post_operative: 'Post_operative',
  // Upload
  only_upload_certain_excel: 'You can only upload files from Excel 97-2003 (.xls) or Excel 2007+ (.xlsx)',
  less_than_100kb: 'The uploaded file has to weight less than 100kb',
  less_than_10MB: 'The uploaded file has to weight less than 10MB',
  only_pdf_files: 'You can only upload files in PDF format',
  less_than_15MB: 'The uploaded file has to weight less than 15MB',
  only_images_pdf_and_office: 'It is only allowed to upload images, pdf, and Microsoft Office files.',
  less_than_3MB: 'The image has to weight less than 3MB',
  jpg_or_png_image: 'You can just upload an image with a .JPG or .PNG extension',
  // Functions
  appointment_deleted: 'Appointment deleted.',
  delete_scheduled_appointment_question: 'Do you want to delete this scheduled appointment?',
  registered_payment_will_be_deleted:
    'There is a payment entered for this health plan. If you delete the appointment, the payment information will disappear',
  patient_name: '{{name}}',
  finish_appoitment_before_starting_one: 'Terminate the appointment with {{foundPatient}} in order to start another one',
  the_patient: 'the patient',
  start_scheduled_appointment_with: 'You want to start the scheduled appointment with {{patient}}?',
  appointment_with_videocall: 'This appointment has a video call. <1> Click here </1> to open the video call in a new window.',
  logout_currentAppointment: 'You have a current appointment. Do you really want to log out?',
  end_currentAppointment: 'Do you want to finish the appointment in progress?',
  logout_and_endAppointment: 'In case you log out, the appointment will end.',
  info_will_be_saved: "The information will be saved in the patient's history.",
  yes_logout: 'Yes. log out',
  yes_finish: 'Yes, finish',
  appointment_with_patient_finished: 'Appointment with {{patient}} finished.',
  // RecipeCategoriesList
  appetizers: 'Appetizers',
  main_course: 'Main course',
  salad: 'Salad',
  desert: 'Desert',
  side: 'Side',
  beverage: 'Beverage',
  arab: 'Arab',
  indian: 'Indian',
  mexican: 'Mexican',
  chinese: 'Chinese',
  italian: 'Italian',
  american: 'American',
  japanese: 'Japanese',
  spanish_1: 'Spanish',
  argentinian: 'Argentinian',
  french: 'French',
  peruvian: 'Peruvian',
  german: 'German',
  brasilian: 'Brazilian',
  cuban: 'Cuban',
  greek: 'Greek',
  hungarian: 'Hungarian',
  english_1: 'English',
  thai: 'Thai',
  russian: 'Russian',
  no_lactose: 'No lactose',
  no_gluten: 'No gluten',
  no_soy: 'No soy',
  meat_dishes: 'Meat dishes',
  pork_dishes: 'Pork dishes',
  fish_dishes: 'Fish dishes',
  seafood_dishes: 'Seafood dishes',
  chicken_dishes: 'Chicken dishes',
  vegetarian_dishes: 'Vegetarian dishes',
  vegan_dishes: 'Vegan dishes',
  pot_parboiled: 'Pot parboiled', // revisar
  pot_presurized: 'Pot presurized', // revisar
  microwave: 'Microwaved',
  oven: 'Oven',
  fried: 'Fried',
  in_pan: 'Pan', // revisar
  charcoaled: 'Charcoaled', // revisar
  grilled: 'Grilled',
  steamed: 'Steamed',
  blended: 'Blended',
  sweet: 'Sweet',
  salty: 'Salty',
  bitter: 'Bitter',
  acid: 'Acid',
  Snack: 'Snack',
  'Durante el entreno': 'During workout',
  in_the_kitchen: 'In the kitchen',
  by_time_of_the_day: 'By time',
  by_taste: 'By taste',
  by_cooking_type: 'By cooking method',
  by_ingredients: 'By ingredients',
  by_exclution: 'By exclution',
  by_type_plate: 'By type of dish',
  keto_dishes: 'Keto dishes',
  pescetarian_dishes: 'Pescetarian dishes',
  contains_lactose: 'Contains lactose',
  contains_gluten: 'Contains gluten',
  Bebida: 'Beverage',
  Licuadora: 'Blender',
  // MailSettings
  mailing_save_success: 'Changes saved successfully!',
  mailing_save_error: "Changes couldn't be saved. Please try again.",
  custom_mail_v_patientName: '{Patient name}',
  custom_mail_v_firstName: '{Your first name}',
  custom_mail_v_lastName: '{Your last name}',
  custom_mail_v_phone: '{Your phone}',
  custom_mail_v_mail: '{Your email}',
  custom_mail_v_appointmentType: '{Appointment type}',
  custom_mail_v_appointmentDate: '{Appointment date}',
  custom_mail_v_appointmentPrice: '{Appointment price}',
  custom_mail_v_appointmentModality: '{Appointment modality}',
  custom_mail_v_appointmentAddress: '{Appointment address}',
  custom_mail_v_mealPlanStartDate: '{Meal plan start date}',
  custom_mail_v_mealPlanEndDate: '{Meal plan end date}',
  patientWelcomeMailSubject: 'Welcome!',
  patientWelcomeMailTemplate: `
    <h3>Hello {{patientName}}!</h3>
    <p> I am very happy that you have made the decision to care about your body and your health,
      and for giving me the confidence to take care that you reach your goal. Remember that I will always be
      for whatever you need, and do not hesitate to ask me any questions that occur to you. </p>
    <p>We can track your progress through Almendra. There you can see your plan,
      and your progress, see my available times to make appointments </p>
    <p>We are in contact! </p>
    <p>Sincerely, {{firstName}} {{lastName}} </p>
    <p>Phone: {{phone}} </p>
    <p>Email: {{mail}}</p>
  `,
  appointmentReminderSubject: 'Appointment reminder - Professional',
  appointmentReminderMailTemplate: `
  <h3>Hello, {{patientName}}</h3>
    <p>This is a friendly reminder of your visit to the nutrition experto.</p>
    <p>These are the details:</p>
    <ul>
      <li>Type of appointment: {{appointmentType}}</li>
      <li>Date and time: {{appointmentDate}}</li>
      <li>Price: {{appointmentPrice}}</li>
      <li>Method: {{appointmentModality}}</li>
      <li>Address: {{appointmentAddress}}</li>
    </ul>
    <p>Sincerely,</p>
    <p>{{firstName}} {lastName}</p>
    <p>Phone: {{phone}}</p>
    <p>Email: {{mail}}</p>
  `,
  patientBirthdayMailSubject: 'Happy Birthday!',
  patientBirthdayMailTemplate: '<h3> Happy day {{patientName}}!</h3>\n<p>I hope you have a lovely day, best wishes to you.</p>',
  patientMealPlanMailSubject: 'Your plan is ready!',
  patientMealPlanMailTemplate: `
    <h3>Hello, {{patientName}}</h3>
    <p>I enclose your scheduled plan from {{mealPlanStartDate}} to {{mealPlanEndDate}}.</p>
    <p>Any questions do not hesitate to contact me.</p>
    <p>Greetings,</p>
    <p>{{firstName}}</p>
  `,
  patientAppointmentMailSubject: 'Your appointment report is ready!',
  patientAppointmentMailTemplate: `
    <h3>Hello, {{patientName}}</h3>
    <p>The report of your appointment today is attached in this email</p>
    <p>Any questions do not hesitate to contact me.</p>
    <p>Greetings,</p>
    <p>{{firstName}}</p>
  `,
  patient_passwordMail_title: 'Patient app access',
  patient_passwordMail_true:
    'When registering a patient with an email, an option will be shown to send them an email so they can create their account and access the Patient App.',
  patient_passwordMail_false: 'When registering a patient with an email, they will not be invited to access the Patient App.',
  activated: 'Active',
  deactivated: 'Inactive',
  patient_passwordMail_content:
    'This email is sent the instant you register a patient with an email. You will be asked to create a password to enter the Patient App.',
  patient_welcomeMail_true: 'Patient welcome',
  send_mail: 'Send email ',
  automatic_mail_will_be_sent_1: 'Every time you add a patient, an automatic email will be sent to their email, at the indicated time.',
  automatic_mail_wont_be_sent_1: 'An automated email will not be sent every time you add a patient.',
  automatic_mail_will_be_sent_2: 'Every time you schedule an appointment, an automatic email will be sent to that patient, at the indicated time.',
  automatic_mail_wont_be_sent_2: 'A reminder email will not be sent every time you schedule a appointment.',
  automatic_mail_will_be_sent_3: 'An automated email will be sent to your patient on their birthday.',
  automatic_mail_wont_be_sent_3: 'An automated email will not be sent to your patient on their birthday',
  hours_after_registering_the_patient: '{{hour}} after registering the patient. {{instantly}}',
  instantly: 'instantly',
  reset_mail_format: 'Do you want to reset the email template to that of the system？',
  reset_format: 'Reset template',
  patient_welcomeMail_placeholder: 'e.g. We are going to make it together!',
  appointment_reminder_placeholder: 'e.g. Remember about your appointment!',
  birthday_greeting: 'Birthday greeting',
  birthDate_mail_placeholder: 'e.g. ¡Happy birthday, John!',
  mealPlan_mail_placeholder: 'e.g. John, your plan is ready',
  appointment_report_placeholder: 'e.g. Your appointment report is ready',
  body: 'Body',
  if_you_write: 'If you write...',
  if_you_write_with_variable:
    'If you write {Your firstName},{{name}} will appear in the email, but if you write Your firstName, "Your firstName" will appear. Remember to use {}',
  will_result_in: 'it will show up...',
  patients_name_placeholder: 'The name of the patient who receives the email. Ex. John',
  no_phone_number: '[¡You have not filled in your phone number!]',
  appointment_type_details: 'The type of the linked appointment ({{healthPlans}})',
  date_and_hour_details: 'The date and time of the linked appointment. For example, 10/12/2022 at 09:00 am',
  price_details: 'The price of the linked appointment. For example, {{-currency}} 100',
  appointment_modality_details: 'The method of the scheduled appointment. It can be in person, at home or online appointment',
  hours_before_appointment: 'hours before each appointment',
  appointment_address_details: 'The address where the appointment will be made. In case the method is online, the address will be omitted.',
  mealPlan_start_placeholder: 'The start date of the plan. e.g. 12/10/2024',
  mealPlan_end_placeholder: 'The end date of the plan. e.g. 12/14/2024',
  automatic_mails_restriction: 'To be able to send automatic emails to your clients, upgrade your subscription.',
  current_billingPlan: 'You have a current subscription to {{planName}}',
  no_current_billingPlan: 'You do not have a current subscription to any plan',
  // FoodProperties
  baby_foods: 'Baby Foods',
  baked_products: 'Baked Products',
  snacks: 'Snacks',
  beverages: 'Beverages',
  meats_and_others: 'Meats and others',
  breakfast_cereals: ' Breakfast Cereals',
  cereal_grains_pasta: ' Cereal Grains and Pasta',
  restaurant_foods: ' Restaurant foods',
  fast_food: 'Fast Food',
  lamb_deer_and_other: 'Lamb deer and other',
  sweets: 'Sweet',
  sausages: 'Sausages',
  spices_and_herbs: 'Spices and Herbs',
  fruit_and_fruit_juices: 'Fruit and fruit juices',
  pasta_and_cereal_grains: 'Pasta and Cereal grains',
  fats_and_oils: 'Fats and Oils',
  dairy_and_eggs: 'Dairy and Egg Products',
  legumes: 'Legumes',
  legumes_and_legume_products: 'Legumes and Legume Products ',
  miscellaneous: 'Miscellaneous',
  nut_and_seed_products: 'Nut and Seed Products',
  fish_and_seafood: 'Fish and Seafood',
  poultry_products: 'Poultry Products ',
  pork_products: 'Pork Products',
  soup_sauces_and_gravies: 'Soup Sauces And Gravies',
  tubers_roots_and_other: 'Tubers roots and others',
  vegetables: 'Vegetables',
  // Activities and met lists
  metsName: 'english',
  // FoodMacroFunctions

  indications_diabetes_title: 'Indications for Diabetes',
  indications_diabetes_1:
    'Foods to avoid: Confectionery, pastry, pastries and sweets in general. Lard, palm or coconut oils. Fatty meats, sausages, salted and smoked meats. Commercialized soft drinks and juices. Alcoholic drinks',
  indications_diabetes_2: 'Sports and / or physical exercise is recommended',
  recommendations_diabetes_1: 'Whole cereals, legumes, olive oil, vegetables, fresh fruits, fish',
  indications_dislipemia_title: 'Indications for dyslipidemia',
  indications_dislipemia_1:
    'Foods to avoid: French fries or chips. Whole milk, cream, very fatty cheeses. Sausages, hamburgers, duck, organ meats. Sauces made with butter and butter in general. Chocolate and pastry ',
  recommendations_dislipemia_1: 'Cereals (bread, pasta, cereals). Fruits, vegetables and legumes. Meat (chicken and turkey). Nuts',
  indications_hypertension_title: 'Indications for Hypertension',
  recommendations_hypertension_1:
    'Poultry and other low-fat meats. Eggs. Milk, yogurts, pasta and cereals (better if they are whole). Natural fruit. Nuts without salt ',
  indications_hypertension_1:
    'Foods to avoid: Kitchen and table salt. Salted, smoked and cured meats. Sausages in general. Cheeses in general. Sachet soups, instant purees. Sparkling water and carbonated soft drinks, Salty seasonings. Bread and biscuits with salt ',
  indications_celiac_title: 'Indications for Celiac disease',
  recommendations_celiac_1:
    'Poultry and other low-fat meats. Eggs, milk, yogurts. Pasta and cereals (better if they are whole). Natural fruit. Nuts without salt ',
  indications_celiac_1:
    'Foods to avoid (contain gluten): Cereals (wheat, barley, rye, oats, bran). Bread, cakes, cakes, cookies, cupcakes. Pasta. Dried figs. Processed meats, fillings, sauces, broths, or concentrated soups. Beverages distilled or fermented from cereals',
  indications_obesity_title: 'Indications for Obesity',
  Recommendations_obesity_1:
    'Legumes. Vegetables and vegetables. Fruits (fresh if possible). Natural juices. Meat, fish, eggs and derivatives (lean meats, avoid skin and visible fat before cooking) ',
  indications_obesity_1:
    'Foods to avoid: Milk and dairy (whole milk, dairy desserts with whole milk, cream or sweetened, sweetened yogurts). Fatty meats and sausages. Sweets (cookies, pastry). Sugary juices, sodas, alcoholic beverages. Chocolates, honey, conventional jams',
  indications_pregnant_title: 'Indications for Pregnant Women',
  indications_pregnant_1:
    'Avoid alcohol and tobacco use. Avoid fish that are high in mercury. Doing aerobic exercise for at least 30 minutes a day on most days of the week ',
  indications_nursing_title: 'Indications for Infants',
  indications_nursing_1:
    'It is recommended to exclusively breastfeed for the first 6 months, then continue until two years or more accompanied by good age-appropriate nutrition, Do aerobic exercise for at least 30 minutes a day on most days of the week ',
  obesity: 'Obesity',
  celiac_disease: 'Celiac disease',
  hypertension: 'Hypertension',
  dyslipidemia: 'Dyslipidemia',
  diabetes_mellitus: 'Diabetes Mellitus',

  // AddServiceHours
  start_hour_before_end: 'The start time must be less than the end time',
  block_hours_limit: 'The block must be more than {{minutes}} minutes',
  block_hours_conflict: 'There is a conflict with another hour block that you have previously created',
  select_at_leas_one_day: 'You must select at least one day',
  select_at_leas_one_modality: 'You must select at least one method',
  new_block_hour: 'New attention block',
  edit_block_hour: 'Edit attention block',
  selected_days: 'Selected days',
  selected_block: 'Selected schedule',
  appointment_modality: 'Appointment method',

  // FreePlanMaxCapModal
  free_plan_max_cap_title: 'You are growing very fast!',
  free_plan_max_cap_description:
    'You are currently on the Free plan, and it allows you to have up to 20 patients or 20 meal plans. To create this {{capType}}, you must upgrade your plan.',
  free_plan_max_download_send_cap_description:
    'You are currently on the Free plan, and it allows 20 downloads or email sending of meal plans. To have unlimited downloads and email sending, you must upgrade your plan.',

  // GenerateMealPlanModal
  generate_meal_plan: 'Generate meal plan',
  generate_meal_plan_type_validator: 'To use Almendra Engine® you must go to the initial step and change the type of meal plan',
  generate_meal_plan_dailyCalories_validator: 'You have to assign a caloric target in Step 2 (Targets)',
  generate_meal_plan_dailyCaloriesDistribution_validator: 'You have to assign caloric target per meal in Step 2 (Targets)',
  generate_meal_plan_mealsPerDay_validator: 'There must be at least 3 meals',
  generate_meal_plan_regenerate_title: 'Do you want to generate a new meal plan?',
  generate_meal_plan_regenerate_content: 'Any food or comment you have entered will be overwritten',
  generate_meal_plan_success_title: 'Your plan is ready!',
  generate_meal_plan_success_body:
    'Almendra Engine® is still in beta and is constantly being improved. If you have any suggestions, tell us in the "Suggestions" box located in the top bar.',
  generate_meal_plan_success_action: 'Done',
  generate_meal_plan_error_title: 'Oops! Something unexpected happened',
  generate_meal_plan_error_body: "We couldn't generate the meal plan with the data entered",
  generate_meal_plan_error_action: 'Understood',
  generate_meal_plan_error_demand: 'Looks like we have a lot of plan demand. Please try again in a while...',
  generate_meal_plan_error_suggestion_title: 'We suggest you review the following:',
  generate_meal_plan_error_suggestion_content_1: "- The patient's date of birth must be older than 17 years (Patient Profile)",
  generate_meal_plan_error_suggestion_content_2: '- The distribution of macronutrients for the selected days (Step 2 - Goals)',
  generate_meal_plan_error_suggestion_content_3: '- Calorie distribution for meals (Step 2 - Goals)',
  // LoadingGenerator
  generating_meal_plan_title: 'Generating meal plan',
  generating_meal_plan_subtitle: 'We are looking for the best combination out of millions possible, this could take a few minutes...',
  // BuyAICredits
  select_one_option: 'Select an option.',
  purchase_credits: 'Buy credits',
  confirm_purchase: 'Confirm payment',
  credits_radio_label: '{{count}} credit -  {{currency}} {{price}}',
  credits_radio_label_other: '{{count}} credits -  {{currency}} {{price}}',
  credits_radio_modal_label: '{{count}} credit',
  credits_radio_modal_label_other: '{{count}} credits',
  credits_best_deal_only: 'Only',
  credits_best_deal_per_credit: 'per credit',
  credits_best_deal: 'Best deal',
  ai_credits_accept_terms: 'By continuing you agree to our <1>service credit terms</1>. Paid credits are non-refundable.',
  // AiChatUpsell
  no_credits_upsell: "Looks like you've run out of credits... How many do you want to buy?",
  // AiChatWidget
  general: 'General',
  context: 'Context',
  examples: 'Examples',
  ai_chat_example_1: "What is the best carbohydrate option to include in an athlete's dinner?",
  ai_chat_example_2: 'What is the appropriate amount of fiber to include in the breakfast of a patient with digestive problems?',
  ai_chat_example_3: "Should I include quinoa or brown rice in this diabetes patient's lunch?",
  ai_chat_placeholder: 'Type a message and press Enter',
  type_at_least_characters: 'Enter at least {{count}} character',
  type_at_least_characters_other: 'Enter at least {{count}} characters',
  no_context_detected: 'There is no specific context on this page',
  chat_error: 'Oops! The service is not available at this moment.',
  // AiChatModal
  change_context: 'Change context',
  more_information: 'More information',
  change_context_from_to: 'You are changing your context from {{from}} to {{to}}',
  change_context_to_general:
    'This means that the artificial intelligence assistant will be able to help you with any questions related to nutrition or health, but will no longer be aware of the features of {{title}}.',
  change_context_from_general: 'This means that the AI assistant will be aware of the specific characteristics of {{title}}.',
  // VerifyOnboardingOTP
  otp_verification_general_error: 'Oops! It seems that something happened. Please try again.',
  otp_verification_title: 'Secure your account',
  otp_verification_subtitle_dirty_phone: 'Enter the 6-digit code sent by SMS to {{phone}}.',
  otp_verification_subtitle_dirty_email: 'Enter the 6-digit code sent by email to {{email}}.',
  otp_verification_subtitle_clean_phone: 'Click on "Send code" to send the access code by SMS to {{phone}}.',
  otp_verification_subtitle_clean_email: 'Click on "Send code" to send the access code by email to {{email}}.',
  otp_verification_send: 'Send code',
  otp_verification_resend: 'Resend code',
  otp_verification_success: 'Account secured!',
  otp_verification_error: 'The code entered is not correct.',
  otp_verification_resend_loading: 'Sending validation code...',
  otp_verification_resend_phone_success: 'Check your phone. We have just sent you the confirmation code by SMS.',
  otp_verification_resend_email_success: 'Check your email. We just sent you the confirmation code by email.',
  otp_verification_resend_error: 'Oops! It seems there was an error trying to resend the confirmation code. Try it in a few moments.',
  otp_verification_already_used_title: "We're sorry",
  otp_verification_phone_already_used_description:
    'This phone number has already been used to verify another Almendra account. If you already have an account, you must use the one associated with the number you just entered. If you think this is an error, write to us at hola@almendra.io.',
  otp_verification_email_already_used_description: 'This email has already been used to verify another Almendra account.',
};
