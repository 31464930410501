import { LOAD_FAVORITE_FOODS, EDIT_FAVORITE_FOODS } from '../actionTypes';
const INITIAL_STATE = {
  foods: []
};

export default function favoriteFoodsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EDIT_FAVORITE_FOODS:
      return action.favoriteFoods;
    case LOAD_FAVORITE_FOODS:
      return action.favoriteFoods;
    default:
      return state;
  }
};