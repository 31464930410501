export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';

// AUTH
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const USER_LOGOUT = 'USER_LOGOUT';


// EXPERT
export const EDIT_EXPERT = 'EDIT_EXPERT';
export const LOAD_EXPERT = 'LOAD_EXPERT';

// PATIENTS
export const LOAD_PATIENTS = 'LOAD_PATIENTS';
export const LOAD_ONE_PATIENT = 'LOAD_ONE_PATIENT'; 
export const DELETE_PATIENT = 'DELETE_PATIENT';
export const ADD_PATIENT = 'ADD_PATIENT';
export const ADD_PENDING_PATIENT = 'ADD_PENDING_PATIENT';
export const UPLOAD_PATIENTS = 'UPLOAD_PATIENTS';
export const EDIT_PATIENT = 'EDIT_PATIENT';


//APPOINTMENTS
export const LOAD_APPOINTMENTS = 'LOAD_APPOINTMENTS';
export const LOAD_SOME_APPOINTMENTS = 'LOAD_SOME_APPOINTMENTS';
export const ADD_APPOINTMENT = 'ADD_APPOINTMENT';
export const EDIT_APPOINTMENT = 'EDIT_APPOINTMENT';
export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT';
export const ADD_PAST_APPOINTMENT = 'ADD_PAST_APPOINTMENT';

//CURRENTAPPOINTMENT
export const LOAD_CURRENT_APPOINTMENT = 'LOAD_CURRENT_APPOINTMENT';
export const REMOVE_CURRENT_APPOINTMENT = 'REMOVE_CURRENT_APPOINTMENT';

//SCHEDULEDAPPOINTMENTS
export const ADD_SCHEDULED_APPOINTMENT = 'ADD_SCHEDULED_APPOINTMENT';
export const EDIT_SCHEDULED_APPOINTMENT = 'EDIT_SCHEDULED_APPOINTMENT';

// FOODS
export const LOAD_FOODS = 'LOAD_FOODS';

// SEARCHED FOODS
export const LOAD_SEARCHED_FOODS = 'LOAD_SEARCHED_FOODS';
export const CLEAR_SEARCHED_FOODS = 'CLEAR_SEARCHED_FOODS';

// RECIPES
export const LOAD_RECIPES = 'LOAD_RECIPES';
export const LOAD_ONE_RECIPE = 'LOAD_ONE_RECIPE';
export const DELETE_RECIPE = 'DELETE_RECIPE';
export const ADD_RECIPE = 'ADD_RECIPE';
export const EDIT_RECIPE = 'EDIT_RECIPE';

// CREATED FOODS
export const ADD_CREATED_FOODS = 'ADD_CREATED_FOODS';
export const LOAD_CREATED_FOODS = 'LOAD_CREATED_FOODS';
export const DELETE_CREATED_FOOD = 'DELETE_CREATED_FOOD';
export const EDIT_CREATED_FOOD = 'EDIT_CREATED_FOOD';

// HEALTH PLANS
export const LOAD_HEALTHPLANS = 'LOAD_HEALTHPLANS';
export const DELETE_HEALTHPLAN = 'DELETE_HEALTHPLAN';
export const ADD_HEALTHPLAN = 'ADD_HEALTHPLAN';
export const EDIT_HEALTHPLAN = 'EDIT_HEALTHPLAN';

// PURCHASED HEALTHPLANS
export const LOAD_ONE_PURCHASEDHEALTHPLAN = 'LOAD_ONE_PURCHASEDHEALTHPLAN';


// MEAL PLANS
export const LOAD_MEAL_PLANS = 'LOAD_MEAL_PLANS';
export const LOAD_ONE_MEAL_PLAN = 'LOAD_ONE_MEAL_PLAN';
export const LOAD_SOME_MEAL_PLANS = 'LOAD_SOME_MEAL_PLANS';
export const ADD_MEAL_PLAN = 'ADD_MEAL_PLAN';
export const EDIT_MEAL_PLAN = 'EDIT_MEAL_PLAN';
export const DELETE_MEAL_PLAN = 'DELETE_MEAL_PLAN';
export const GENERATE_MEAL_PLAN = 'GENERATE_MEAL_PLAN';


// MEAL PLAN TEMPLATES
export const LOAD_MEAL_PLAN_TEMPLATES = 'LOAD_MEAL_PLAN_TEMPLATES';
export const ADD_MEAL_PLAN_TEMPLATE = 'ADD_MEAL_PLAN_TEMPLATE';
export const EDIT_MEAL_PLAN_TEMPLATE = 'EDIT_MEAL_PLAN_TEMPLATE';
export const DELETE_MEAL_PLAN_TEMPLATE = 'DELETE_MEAL_PLAN_TEMPLATE';

// MESAYES
export const LOAD_MESSAGES = 'LOAD_MESSAGES';
export const LOAD_BULK_MESSAGES = 'LOAD_BULK_MESSAGES';
export const EDIT_MESSAGES = 'EDIT_MESSAGES';
export const HANDLE_MESSAGES = 'HANDLE_MESSAGES';

// NOTIFICATIONS
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS';
export const EDIT_MANY_NOTIFICATIONS = 'EDIT_MANY_NOTIFICATIONS';

// BLOG POSTS
export const LOAD_BLOG_POSTS = 'LOAD_BLOG_POSTS';
export const ADD_BLOG_POST = 'ADD_BLOG_POST';
export const EDIT_BLOG_POST = 'EDIT_BLOG_POST';
export const DELETE_BLOG_POST = 'DELETE_BLOG_POST';

// EQUIVALENTS GROUPS
export const LOAD_EQUIVALENTS_GROUPS = 'LOAD_EQUIVALENTS_GROUPS';
export const ADD_EQUIVALENTS_GROUP = 'ADD_EQUIVALENTS_GROUP';
export const EDIT_EQUIVALENTS_GROUP = 'EDIT_EQUIVALENTS_GROUP';
export const DELETE_EQUIVALENTS_GROUP = 'DELETE_EQUIVALENTS_GROUP';

// FINANCE
export const EDIT_FINANCE = 'EDIT_FINANCE'
export const LOAD_FINANCE = 'LOAD_FINANCE'

// INDICATIONS TEMPLATES
export const ADD_INDICATIONS_TEMPLATE = 'ADD_INDICATIONS_TEMPLATE';
export const LOAD_INDICATIONS_TEMPLATES = 'LOAD_INDICATIONS_TEMPLATES';
export const EDIT_INDICATIONS_TEMPLATE = 'EDIT_INDICATIONS_TEMPLATE';
export const DELETE_INDICATIONS_TEMPLATE = 'DELETE_INDICATIONS_TEMPLATE'

// CUSTOM MEASUREMENTS
export const ADD_CUSTOM_MEASUREMENT = 'ADD_CUSTOM_MEASUREMENT';
export const LOAD_CUSTOM_MEASUREMENTS = 'LOAD_CUSTOM_MEASUREMENTS';
export const EDIT_CUSTOM_MEASUREMENT = 'EDIT_CUSTOM_MEASUREMENT';
export const DELETE_CUSTOM_MEASUREMENT = 'DELETE_CUSTOM_MEASUREMENT';

// FAVORITE FOODS
export const LOAD_FAVORITE_FOODS = 'LOAD_FAVORITE_FOODS';
export const EDIT_FAVORITE_FOODS = 'EDIT_FAVORITE_FOODS';

// SEARCHBAR
export const CHANGE_FOOD_FILTERS = 'CHANGE_FOOD_FILTERS';
export const CLEAR_FOOD_FILTERS = 'CLEAR_FOOD_FILTERS';

// CUSTOM FORMS
export const ADD_CUSTOM_FORM = 'ADD_CUSTOM_FORM';
export const LOAD_CUSTOM_FORMS = 'LOAD_CUSTOM_FORMS';
export const EDIT_CUSTOM_FORM = 'EDIT_CUSTOM_FORM';
export const DELETE_CUSTOM_FORM = 'DELETE_CUSTOM_FORM';

// CUSTOM FIELDS
export const ADD_CUSTOM_FIELD = 'ADD_CUSTOM_FIELD';
export const LOAD_CUSTOM_FIELDS = 'LOAD_CUSTOM_FIELDS';
export const EDIT_CUSTOM_FIELD = 'EDIT_CUSTOM_FIELD';
export const DELETE_CUSTOM_FIELD = 'DELETE_CUSTOM_FIELD';

// REFERRAL
export const LOAD_REFERRAL = 'LOAD_REFERRAL';
// WORKOUTS
export const ADD_WORKOUT = 'ADD_WORKOUT';
export const EDIT_WORKOUT = 'EDIT_WORKOUT';
export const DELETE_WORKOUT = 'DELETE_WORKOUT';
export const LOAD_ONE_WORKOUT = 'LOAD_ONE_WORKOUT';

// WORKOUT TEMPLATES
export const ADD_WORKOUT_TEMPLATE = 'ADD_WORKOUT_TEMPLATE';
export const LOAD_WORKOUT_TEMPLATES = 'LOAD_WORKOUT_TEMPLATES';
export const EDIT_WORKOUT_TEMPLATE = 'EDIT_WORKOUT_TEMPLATE';
export const DELETE_WORKOUT_TEMPLATE = 'DELETE_WORKOUT_TEMPLATE';

// SETTINGS
export const EDIT_SETTING = 'EDIT_SETTING';

////////////////////////////   PATIENT MAIN //////////////////////////////////
export const LOAD_PATIENT = 'LOAD_PATIENT';