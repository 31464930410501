import * as Sentry from '@sentry/react';
import { Component } from 'react';
import Error500 from '../components/Exception/500/Error500';
import SimpleLoadingPage from '../components/Exception/Loading/SimpleLoadingPage';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      hasChunkLoadError: false,
    };
  }

  static getDerivedStateFromError(err) {
    return { hasError: true, hasChunkLoadError: err && err.name === 'ChunkLoadError' ? true : false };
  }

  componentDidCatch(err, info) {
    if (process.env.REACT_APP_ENV === 'production') {
      Sentry.captureException(err);
    } else {
      console.log(err);
      console.log(info);
    }
    if (err && err.name === 'ChunkLoadError') {
      return window.location.reload();
    }
  }

  dismissError = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      return <Error500 dismissError={this.props.allowDismiss ? this.dismissError : undefined} />;
    } else if (this.state.hasChunkLoadError) {
      return SimpleLoadingPage;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
