import { ADD_APPOINTMENT, ADD_PAST_APPOINTMENT, EDIT_APPOINTMENT, DELETE_APPOINTMENT, LOAD_APPOINTMENTS, LOAD_SOME_APPOINTMENTS, ADD_SCHEDULED_APPOINTMENT, EDIT_SCHEDULED_APPOINTMENT } from '../actionTypes';

const INITIAL_STATE = [];

export default function appointmentsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_APPOINTMENT:
      return [...state, action.appointment];
    case ADD_PAST_APPOINTMENT:
      return [...state, action.appointment];
    case EDIT_APPOINTMENT:
      const editedAppointment = state.find(appointment => appointment._id === action.appointment._id);
      const index = state.indexOf(editedAppointment);
      state[index] = action.appointment;
      return [...state];
    case LOAD_APPOINTMENTS:
      return [...action.appointments];
    case LOAD_SOME_APPOINTMENTS:
      let newState = [].concat(state);
      for (let i = 0; i < action.appointments.length; i++) {
        const _ids = newState.map(x => x._id);
        const index = _ids.indexOf(action.appointments[i]._id);
        if (index > -1) {
          newState[index] = action.appointments[i];
        } else {
          newState.push(action.appointments[i]);
        }
      }
      return [...newState];
    case DELETE_APPOINTMENT:
      return state.filter(appointment => appointment._id !== action.id);
    case ADD_SCHEDULED_APPOINTMENT:
      return [...state, action.appointment];
    case EDIT_SCHEDULED_APPOINTMENT:
      const editedScheduledAppointment = state.find(appointment => appointment._id === action.appointment._id);
      const indexScheduledAppointment = state.indexOf(editedScheduledAppointment);
      state[indexScheduledAppointment] = action.appointment;
      return [...state];
    default:
      return state;
  }
};
