import { ADD_INDICATIONS_TEMPLATE, LOAD_INDICATIONS_TEMPLATES, EDIT_INDICATIONS_TEMPLATE, DELETE_INDICATIONS_TEMPLATE } from '../actionTypes';

const INITIAL_STATE = [];

export default function indicationsTemplatesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_INDICATIONS_TEMPLATE:
      return [...state, action.indicationsTemplate];
    case LOAD_INDICATIONS_TEMPLATES:
      return action.indicationsTemplates;
    case EDIT_INDICATIONS_TEMPLATE:
      const newState = [...state];
      const editedIndicationsTemplate = newState.find(x => x._id === action.indicationsTemplate._id);
      const index = newState.indexOf(editedIndicationsTemplate);
      newState[index] = action.indicationsTemplate;
      return [...newState];
    case DELETE_INDICATIONS_TEMPLATE:
      return [...state.filter(indicationsTemplate => indicationsTemplate._id !== action.id)];
    default:
      return state;
  }
};