import {
  ADD_MEAL_PLAN,
  EDIT_MEAL_PLAN,
  GENERATE_MEAL_PLAN,
  LOAD_MEAL_PLANS,
  LOAD_ONE_MEAL_PLAN,
  LOAD_SOME_MEAL_PLANS,
  DELETE_MEAL_PLAN,
} from '../actionTypes';

const INITIAL_STATE = [];

export default function mealPlansReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_MEAL_PLAN:
      return [...state, action.mealPlan];
    case LOAD_ONE_MEAL_PLAN:
      const foundMealPlan = state.find((mealPlan) => mealPlan._id === action.mealPlan._id);
      if (foundMealPlan) {
        const index = state.indexOf(foundMealPlan);
        state[index] = action.mealPlan;
        return [...state];
      } else {
        return [...state, action.mealPlan];
      }
    case EDIT_MEAL_PLAN:
      const editedMealPlan = state.find((mealPlan) => mealPlan._id === action.mealPlan._id);
      const index = state.indexOf(editedMealPlan);
      state[index] = action.mealPlan;
      return [...state];
    case GENERATE_MEAL_PLAN:
      const generatedMealPlan = state.find((mealPlan) => mealPlan._id === action.mealPlan._id);
      const mpgenIndex = state.indexOf(generatedMealPlan);
      state[mpgenIndex] = action.mealPlan;
      return [...state];
    case LOAD_SOME_MEAL_PLANS:
      if (action.clearMealPlans) {
        return [...action.mealPlans];
      } else {
        let newState = [].concat(state);
        for (let i = 0; i < action.mealPlans.length; i++) {
          const _ids = newState.map((x) => x._id);
          const index = _ids.indexOf(action.mealPlans[i]._id);
          if (index > -1) {
            newState[index] = action.mealPlans[i];
          } else {
            newState.push(action.mealPlans[i]);
          }
        }
        return [...newState];
      }
    case LOAD_MEAL_PLANS:
      let _state = [].concat(state);
      const mealPlansId = _state.map((x) => x._id);
      for (let i = 0; i < action.mealPlans.length; i++) {
        const mealPlan = action.mealPlans[i];
        const foundIdx = mealPlansId.indexOf(mealPlan._id);
        if (foundIdx > -1) {
          _state[foundIdx] = { ..._state[foundIdx], ...mealPlan };
        } else {
          _state.push(mealPlan);
        }
      }
      return _state;
    case DELETE_MEAL_PLAN:
      return state.filter((mealPlan) => mealPlan._id !== action.id);
    default:
      return state;
  }
}
