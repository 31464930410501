import { LOAD_ONE_PURCHASEDHEALTHPLAN } from '../actionTypes';

const INITIAL_STATE = [];

export default function purchasedHealthPlansReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_ONE_PURCHASEDHEALTHPLAN:
      const purchasedHealthPlan = state.find(x => x._id === action.purchasedHealthPlan._id);
      if (purchasedHealthPlan) {
        return [[...action.patient]]
      } else {
        const index = state.indexOf(purchasedHealthPlan);
        state[index] = action.purchasedHealthPlan;
        return [...state];
      }
    default:
      return state;
  }
};