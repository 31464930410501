import { CHANGE_FOOD_FILTERS, CLEAR_FOOD_FILTERS } from '../actionTypes';

const INITIAL_STATE = {
  foodSource: ["all"],
  fetchAll: false // false significa que se va realizar el fetch parte por parte cuando el query vaya cambiando o cuando se solicite ver más foods de un mismo query. 
};

export default function searchBarFiltersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CHANGE_FOOD_FILTERS:
      return { ...state, ...action.newFoodFilters };
    case CLEAR_FOOD_FILTERS:
      return INITIAL_STATE;
    default:
      return state;
  }
};