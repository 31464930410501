import {LOAD_MESSAGES, LOAD_BULK_MESSAGES,EDIT_MESSAGES, HANDLE_MESSAGES} from '../actionTypes';

const INITIAL_STATE = [];

export default function messagesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_MESSAGES: 
      return [...action.message];
    case LOAD_BULK_MESSAGES: 
      return [...action.message]
    case EDIT_MESSAGES: 
      return [...action.message];
    case HANDLE_MESSAGES:
      return action.message;
    default:
      return state;
  }
};
