import { LOAD_FOODS, CLEAR_SEARCHED_FOODS } from '../actionTypes';

const INITIAL_STATE = [];

//Para evitar tener valores=NaN
function returnNumber(thing) {
  if (isNaN(thing)) {
    return 0;
  } else {
    return thing;
  }
}

export default function foodsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_FOODS:
      let newState = [...state];
      for (let i = 0; i < action.food.length; i++) {
        if (newState.filter(x => x.id === action.food[i]._id && x.fullyFetched).length === 0) {
          let { _id, calories_kcal, fat_g, carbs_g, protein_g, ...otherProps } = action.food[i];
          otherProps.id = _id;
          otherProps.unit = 'gram';
          otherProps.quantity = 100;
          otherProps.calories_kcal = returnNumber(calories_kcal);
          otherProps.fat_g = returnNumber(fat_g);
          otherProps.carbs_g = returnNumber(carbs_g);
          otherProps.protein_g = returnNumber(protein_g);
          otherProps.key = i;
          otherProps.fullyFetched = action.fullyFetched;
          newState.push(otherProps);
        }
      }
      return newState;
    case CLEAR_SEARCHED_FOODS:
      return INITIAL_STATE;
    default:
      return state;
  }
};