import { ADD_CUSTOM_MEASUREMENT, LOAD_CUSTOM_MEASUREMENTS, EDIT_CUSTOM_MEASUREMENT, DELETE_CUSTOM_MEASUREMENT } from '../actionTypes';

const INITIAL_STATE = [];

export default function customMeasurementsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_CUSTOM_MEASUREMENT:
      return [...state, action.customMeasurement];
    case LOAD_CUSTOM_MEASUREMENTS:
      return [...action.customMeasurements];
    case EDIT_CUSTOM_MEASUREMENT:
      const editedCustomMeasurement = state.find(x => x._id === action.customMeasurement_id);
      const index = state.indexOf(editedCustomMeasurement);
      state[index] = action.customMeasurement;
      return [...state];
    case DELETE_CUSTOM_MEASUREMENT:
      return state.filter(x => x._id !== action.customMeasurement_id);
    default:
      return state;
  }
};