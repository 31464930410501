// Distinguir si un número es par o impart
export const isEven = function(value) {
	if (value % 2 === 0)
		return true;
	else
		return false;
}

// Arrays de elementos
/**
 * - Compara array de elementos (no objetos) para ver si el contenido es exactamente igual indistintamente de la posición que tenga en el array.
 * - Se recomienda usar este método para comparar arrays del mismo length, ya que comprarar arrays con diferente length podría llevar a un subset-mislead
 * @param {Array} array1 Array A
 * @param {Array} array2 Array B
 * @return True si los arrays contienen lo mismo
 */
export const compareArrays = function(array1, array2){
	return 	array1.length === array2.length && array1.sort().every(function(value, index) { return value === array2.sort()[index]});
}

// ARrays de objetos
export const arraysAreEqual = function (value, other) {

	// Get the value type
	var type = Object.prototype.toString.call(value);

	// If the two objects are not the same type, return false
	if (type !== Object.prototype.toString.call(other)) return false;

	// If items are not an object or array, return false
	if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

	// Compare the length of the length of the two items
	var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
	var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
	if (valueLen !== otherLen) return false;

	// Compare two items
	var compare = function (item1, item2) {

		// Get the object type
		var itemType = Object.prototype.toString.call(item1);

		// If an object or array, compare recursively
		if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
			if (!arraysAreEqual(item1, item2)) return false;
		}

		// Otherwise, do a simple comparison
		else {

			// If the two items are not the same type, return false
			if (itemType !== Object.prototype.toString.call(item2)) return false;

			// Else if it's a function, convert to a string and compare
			// Otherwise, just compare
			if (itemType === '[object Function]') {
				if (item1.toString() !== item2.toString()) return false;
			} else {
				if (item1 !== item2) return false;
			}

		}
	};

	// Compare properties
	if (type === '[object Array]') {
		for (var i = 0; i < valueLen; i++) {
			if (compare(value[i], other[i]) === false) return false;
		}
	} else {
		for (var key in value) {
			if (value.hasOwnProperty(key)) {
				if (compare(value[key], other[key]) === false) return false;
			}
		}
	}

	// If nothing failed, return true
	return true;

};

export const missingOrderedElement = function(chosenNames, mainName) {
	// mainName (Nombre principal): "Desayuno"
	// chosenNames (Valores de nombres elegidos): ["Desayuno", "Desayuno 2", "Desayuno 3", "Desayuno 5"]

	// Si no se han elegido nombres, entonces deberíamos botar el nombre principal.
	if(chosenNames.length === 0){
		return mainName;
	} else {
		// Verificar si existe una elemento sin número.
		if(chosenNames.indexOf(mainName) === -1){
			// Si no existe, devolver el nombre principal sin número.
			return mainName;
		} else {
			// Si existe, hacer un array de los números, excluyendo el nombre principal sin número.
			let _chosenNames = [...chosenNames];
			_chosenNames.splice(chosenNames.indexOf(mainName), 1);
			for(let i = 0; i < _chosenNames.length; i++){
				_chosenNames[i] = parseFloat(_chosenNames[i].substr(mainName.length + 1));
			}
			_chosenNames.sort((a, b) => a - b);

			// Ahora _chosenNames es un array con números ordenado de manera ascendiente.
			for(let i = 0; i < _chosenNames.length; i++){
				if(_chosenNames[i] !== i + 2){
					return `${mainName} ${i + 2}`;
				}
			}
			return `${mainName} ${chosenNames.length + 1}`;
		}
	}
};

export const toSeoUrl = function toSeoUrl(url) {
	return url.toString()               // Convert to string
			.normalize('NFD')               // Change diacritics
			.replace(/[\u0300-\u036f]/g,'') // Remove illegal characters
			.replace(/\s+/g,'-')            // Change whitespace to dashes
			.toLowerCase()                  // Change to lowercase
			.replace('-y-','-')          // Replace ampersand
			// .replace('-el-', '-')          // Replace ampersand
			// .replace('-la-', '-')          // Replace ampersand
			// .replace('-los-', '-')          // Replace ampersand
			// .replace('-las-', '-')          // Replace ampersand
			// .replace('-un-', '-')          // Replace ampersand
			// .replace('-una-', '-')          // Replace ampersand
			.replace(/[^a-z0-9\-]/g,'')     // Remove anything that is not a letter, number or dash
			.replace(/-+/g,'-')             // Remove duplicate dashes
			.replace(/^-*/,'')              // Remove starting dashes
			.replace(/-*$/,'');             // Remove trailing dashes
}

export const capitalizeFirstLetter = function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const titleCase = function titleCase(str) {
	let splitStr = str ? str.toLowerCase().split(' ') : [];
	for (let i = 0; i < splitStr.length; i++) {
			// You do not need to check if i is larger than splitStr length, as your for does that for you
			// Assign it back to the array
			splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
	}
	// Directly return the joined string
	return splitStr.join(' '); 
}


export const removeDuplicatedCharacters = (str, ch) => {
	// Si un string (str) tiene más de 1 repetición de un caracter (ch), devolvemos el string solo sin las repeticiones del caracter.
	// Ej: removeDuplicatedCharacters('aaabecedarioa') => "abecedrio"
  let newStr = '';
  let firstPosition;
  for (let i = 0; i < str.length; i++) {
    if(str[i] === ch){
      if(!firstPosition){
        firstPosition = i;
        newStr += str[i];
      }
    } else {
      newStr += str[i];
    }
  }
  if(firstPosition){
    return newStr;
  }
  return str;
}

export const isValidMail = (mail) => {
	if(!mail) return false;
	return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail.toLowerCase());
}

export const normalizeMail = (text) => {
	return text ? text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() : "";
}

export const matchStringWord = function(typed = "", text = ""){
	const normalizedTypedText = typed.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
	return text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").substr(0, normalizedTypedText.length) === normalizedTypedText;
}

export function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

export function getLinkHref(link) {
	return `${/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/igm.test(link) ? "" : "//"}${link}`;
}

export function stringHasNumber(myString) {
  return /\d/.test(myString);
}

/** Function that count occurrences of a substring in a string;
 * @param {String} string               The string
 * @param {String} subString            The sub string to search for
 * @param {Boolean} [allowOverlapping]  Optional. (Default:false)
 */
export function getOcurrences(string, subString, allowOverlapping) {

	string += "";
	subString += "";
	if (subString.length <= 0) return (string.length + 1);

	var n = 0,
			pos = 0,
			step = allowOverlapping ? 1 : subString.length;

	while (true) {
			pos = string.indexOf(subString, pos);
			if (pos >= 0) {
					++n;
					pos += step;
			} else break;
	}
	return n;
}

/**
 * Divide una cadena de texto en partes iguales.
 * @param {String} str Cadena de texto
 * @param {Number} size Número de caracteres para dividir la cadena de texto
 */
export function chunkSubstr(str, size) {
  const numChunks = Math.ceil(str.length / size)
  const chunks = new Array(numChunks)

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size)
  }

  return chunks
}

export function alphabeticalSorter (a, b, property) {
	const _a = property ? a[property] : a;
	const _b = property ? b[property] : b;
	if(_a && _b){
		return _a.localeCompare(_b);
	} else if(!_a){
		return "".localeCompare(_b);
	} else {
		return _a.localeCompare("");
	}
}

export function arrayHasDuplicates (array) {
	let valuesSoFar = Object.create(null);
	for (let i = 0; i < array.length; ++i) {
		let value = array[i];
		if (value in valuesSoFar) {
				return true;
		}
		valuesSoFar[value] = true;
	}
	return false;
}