import { LOAD_EXPERT, EDIT_EXPERT } from '../actionTypes';

const INITIAL_STATE = {};

export default function expertReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_EXPERT:
      return { ...action.expert };
    case EDIT_EXPERT:
      return { ...action.expert };
    default:
      return state;
  }
};
