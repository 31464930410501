import {
  LOAD_APPOINTMENTS,
  LOAD_BLOG_POSTS,
  LOAD_CREATED_FOODS,
  LOAD_CUSTOM_MEASUREMENTS,
  LOAD_CUSTOM_FORMS,
  LOAD_CUSTOM_FIELDS,
  LOAD_EQUIVALENTS_GROUPS,
  LOAD_EXPERT,
  LOAD_FOODS,
  LOAD_HEALTHPLANS,
  LOAD_INDICATIONS_TEMPLATES,
  LOAD_MEAL_PLANS,
  LOAD_SOME_MEAL_PLANS,
  LOAD_MEAL_PLAN_TEMPLATES,
  LOAD_PATIENTS,
  LOAD_RECIPES,
  LOAD_FAVORITE_FOODS,
  LOAD_FINANCE,
  LOAD_WORKOUT_TEMPLATES
} from '../actionTypes';

const INITIAL_STATE = {
  appointments: false,
  blogPosts: false,
  createdFoods: false,
  customMeasurements: false,
  customFields: false,
  customForms: false,
  equivalentsGroups: false,
  expert: false,
  favoriteFoods: false,
  foods: false,
  healthPlans: false,
  indicationsTemplates: false,
  mealPlans: false,
  pendingMealPlans: false,
  mealPlanTemplates: false,
  patients: false,
  recipes: false,
  finance: false,
  workoutTemplates: false
};

export default function initialFetchesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_APPOINTMENTS:
      if (!state.appointments) {
        return { ...state, appointments: true };
      }
      return state;
    case LOAD_BLOG_POSTS:
      if (!state.blogPosts) {
        return { ...state, blogPosts: true };
      }
      return state;
    case LOAD_CREATED_FOODS:
      if (!state.createdFoods) {
        return { ...state, createdFoods: true };
      }
      return state;
    case LOAD_CUSTOM_MEASUREMENTS:
      if (!state.customMeasurements) {
        return { ...state, customMeasurements: true };
      }
      return state;
    case LOAD_CUSTOM_FIELDS:
      if (!state.customFields) {
        return { ...state, customFields: true };
      }
      return state;
    case LOAD_CUSTOM_FORMS:
      if (!state.customForms) {
        return { ...state, customForms: true };
      }
      return state;
    case LOAD_EQUIVALENTS_GROUPS:
      if (!state.equivalentsGroups) {
        return { ...state, equivalentsGroups: true };
      }
      return state;
    case LOAD_EXPERT:
      if (!state.expert) {
        return { ...state, expert: true };
      }
      return state;
    case LOAD_FAVORITE_FOODS:
      if (!state.favoriteFoods) {
        return { ...state, favoriteFoods: true };
      }
      return state;
    case LOAD_FOODS:
      if (!state.foods) {
        return { ...state, foods: true };
      }
      return state;
    case LOAD_HEALTHPLANS:
      if (!state.healthPlans) {
        return { ...state, healthPlans: true };
      }
      return state;
    case LOAD_INDICATIONS_TEMPLATES:
      if (!state.indicationsTemplates) {
        return { ...state, indicationsTemplates: true };
      }
      return state;
    case LOAD_MEAL_PLANS:
      if (!state.mealPlans) {
        return { ...state, mealPlans: true };
      }
      return state;
    case LOAD_SOME_MEAL_PLANS:
      if (!state.pendingMealPlans) {
        return { ...state, pendingMealPlans: true };
      }
      return state;
    case LOAD_MEAL_PLAN_TEMPLATES:
      if (!state.mealPlanTemplates) {
        return { ...state, mealPlanTemplates: true };
      }
      return state;
    case LOAD_PATIENTS:
      if (!state.patients) {
        return { ...state, patients: true };
      }
      return state;
    case LOAD_RECIPES:
      if (!state.recipes) {
        return { ...state, recipes: true };
      }
      return state;
    case LOAD_FINANCE:
      if (!state.finance) {
        return { ...state, finance: true };
      }
      return state;
    case LOAD_WORKOUT_TEMPLATES:
      if (!state.workoutTemplates) {
        return { ...state, workoutTemplates: true };
      }
      return state;
    default:
      return state;
  }
};