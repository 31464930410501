import { LOAD_REFERRAL } from '../actionTypes';

const INITIAL_STATE = {}

export default function referralsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_REFERRAL:
        return {...action.referral};
    default:
      return state;    
  }
};
