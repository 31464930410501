import { ADD_CUSTOM_FORM, LOAD_CUSTOM_FORMS, EDIT_CUSTOM_FORM, DELETE_CUSTOM_FORM } from '../actionTypes';

const INITIAL_STATE = [];

export default function customFormsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_CUSTOM_FORM:
      return [...state, action.customForm];
    case LOAD_CUSTOM_FORMS:
      return [...action.customForms];
    case EDIT_CUSTOM_FORM:
      const editedCustomForm = state.find(x => x._id === action.customForm_id);
      const index = state.indexOf(editedCustomForm);
      state[index] = action.customForm;
      return [...state];
    case DELETE_CUSTOM_FORM:
      return state.filter(x => x._id !== action.customForm_id);
    default:
      return state;
  }
};