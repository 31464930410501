import { ADD_RECIPE, LOAD_RECIPES, LOAD_ONE_RECIPE, DELETE_RECIPE, EDIT_RECIPE } from '../actionTypes';

const INITIAL_STATE = [];

export default function recipesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_RECIPE:
      return [...state, action.recipe];
    case EDIT_RECIPE:
      const editedRecipe = state.find(recipe => recipe._id === action.recipe._id);
      if (!editedRecipe) {
        // caso cuando se recibe una receta clonada para poder editar
        return [...state.filter(recipe => recipe._id !== action.recipe.clonedFrom), action.recipe];
      } else {
        const index = state.indexOf(editedRecipe);
        state[index] = action.recipe;
        return [...state];
      }
    case LOAD_RECIPES:
      let newState = [...state];
      for (let i = 0; i < action.recipes.length; i++) {
        newState = newState.filter(x => action.recipes[i]._id !== x._id);
      }
      return [...newState, ...action.recipes]
    // return [...action.recipes];
    case LOAD_ONE_RECIPE:
      const loadedRecipe = state.find(recipe => recipe._id === action.recipe._id)
      if (loadedRecipe) {
        const index = state.indexOf(loadedRecipe);
        state[index] = action.recipe;
        return [...state];
      } else {
        return [...state, action.recipe];
      }
    case DELETE_RECIPE:
      return state.filter(recipe => recipe._id !== action.id);
    default:
      return state;
  }
};