import { EDIT_SETTING } from '../actionTypes';

const INITIAL_STATE = {
  mealPlanEditor: {
    nutrientOnHeader: false,
    nutrient: 'fiber_g',
  }
};

export default function settingsReducer (state = INITIAL_STATE, action) {
  switch(action.type){
    case EDIT_SETTING:
      return { ...state, [action.name]: action.setting };
    default: 
      return state;
  }
};