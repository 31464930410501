import { LOAD_SEARCHED_FOODS, CLEAR_SEARCHED_FOODS } from '../actionTypes';
import { compareArrays } from '../../components/Utilities/Helpers/LogicFunctions';

const INITIAL_STATE = [];

export default function searchedFoodsReducer (state = INITIAL_STATE, action) {
  switch(action.type){
    case LOAD_SEARCHED_FOODS:
      // Foods coming from foods.js handler (could be from a new query of from an existing one)
      let newFoodResponse = [];
      for(let i = 0; i < action.foodsResponse.length; i++){
        newFoodResponse.push(action.foodsResponse[i]._id);
      }
      if(state.filter(x => x.foodQuery === action.foodQuery && compareArrays(x.sourceQuery,action.sourceQuery)).length === 0){
        // If query doesnt exist, create one with the new fetched foods.
        const newQuery = {
          foodResponse: newFoodResponse,
          foodQuery: action.foodQuery,
          sourceQuery: action.sourceQuery,
          fetchAllQuery: action.fetchAllQuery,
          hasMore: newFoodResponse.length > 0
        };
        return [...state, newQuery];
      } else {
        // If query exists, just push the new fetched foods to the existing array
        return state.map(x => {
          if(x.foodQuery === action.foodQuery && compareArrays(x.sourceQuery, action.sourceQuery)){
            return {
              ...x,
              foodResponse: x.foodResponse.concat(newFoodResponse),
              hasMore: newFoodResponse.length > 0
            }
          } else {
            return x;
          }
        })
      }
    case CLEAR_SEARCHED_FOODS: 
      if(action.queryToClear === "nonFetchAll"){
        return INITIAL_STATE;
      } else {
        return INITIAL_STATE;
      }
    // case CHANGE_FOOD_FILTERS:
    //   return INITIAL_STATE;
    // case CLEAR_FOOD_FILTERS:
    //   return INITIAL_STATE;
    default: 
      return state;
  }
};