import { ADD_BLOG_POST, EDIT_BLOG_POST, LOAD_BLOG_POSTS, DELETE_BLOG_POST } from '../actionTypes';

const INITIAL_STATE = [];

export default function blogPostsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_BLOG_POST:
      return [...state, action.blogPost];
    case EDIT_BLOG_POST:
      const editedBlog = state.find(blogPost => blogPost._id === action.blogPost._id);
      const index = state.indexOf(editedBlog);
      state[index] = action.blogPost;
      return [...state];
    case LOAD_BLOG_POSTS:
      return [...action.blogPosts];
    case DELETE_BLOG_POST:
      return state.filter(blogPost => blogPost._id !== action.id);
    default:
      return state;
  }
};