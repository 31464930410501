import { ADD_WORKOUT, EDIT_WORKOUT, DELETE_WORKOUT,LOAD_ONE_WORKOUT} from '../actionTypes';

const INITIAL_STATE = [];

export default function workoutsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_WORKOUT:
      return [...state, action.workout];
    case LOAD_ONE_WORKOUT:
      const foundWorkout = state.find(wk => wk._id === action.workout._id);
      if (foundWorkout) {
        const index = state.indexOf(foundWorkout);
        state[index] = action.workout;
        return [...state];
      } else {
        return [...state, action.workout];
      }
    case EDIT_WORKOUT:
      const editedWorkout = state.find(e => e._id === action.workout._id);
      const index = state.indexOf(editedWorkout);
      state[index] = action.workout;
      return [...state];
    case DELETE_WORKOUT:
      return state.filter(e => e._id !== action.id);
    default:
      return state;
  }
};

