import { message } from 'antd';
import { ADD_ERROR, REMOVE_ERROR } from '../actionTypes.js';

const INITIAL_STATE = { 
  status: null,
  message: null, 
};

export default function errorReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_ERROR:
      if (action.error.status === 11000) {
        message.destroy();
        message.error('Este correo se encuentra en uso. Prueba con otro.', 4);
      }
      return action.error;
    case REMOVE_ERROR:
      return { status: null, message: null };
    default:
      return state;
  }
}
