import { ADD_MEAL_PLAN_TEMPLATE, EDIT_MEAL_PLAN_TEMPLATE, LOAD_MEAL_PLAN_TEMPLATES, DELETE_MEAL_PLAN_TEMPLATE } from '../actionTypes';

const INITIAL_STATE = [];

export default function mealPlanTemplatesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_MEAL_PLAN_TEMPLATE:
      return [...state, action.mealPlanTemplate];
    case EDIT_MEAL_PLAN_TEMPLATE:
      const editedMealPlanTemplate = state.find(mealPlanTemplate => mealPlanTemplate._id === action.mealPlanTemplate._id);
      const index = state.indexOf(editedMealPlanTemplate);
      state[index] = action.mealPlanTemplate;
      return [...state];
    case LOAD_MEAL_PLAN_TEMPLATES:
      return [...action.mealPlanTemplates];
    case DELETE_MEAL_PLAN_TEMPLATE:
      return state.filter(mealPlanTemplate => mealPlanTemplate._id !== action.id);
    default:
      return state;
  }
};