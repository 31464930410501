import { ADD_EQUIVALENTS_GROUP, EDIT_EQUIVALENTS_GROUP, LOAD_EQUIVALENTS_GROUPS, DELETE_EQUIVALENTS_GROUP } from '../actionTypes';

const INITIAL_STATE = [];

export default function equivalentsGroupsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_EQUIVALENTS_GROUP:
      if (action.equivalentsGroup.defaultHidden && action.equivalentsGroup.fromDefault) {
        return [...state].filter(x => x._id !== action.equivalentsGroup.fromDefault);
      } else if(action.equivalentsGroup.fromDefault) {
        return [...state, action.equivalentsGroup].filter(x => x._id !== action.equivalentsGroup.fromDefault);
      } else {
        return [...state, action.equivalentsGroup];
      }
    case EDIT_EQUIVALENTS_GROUP:
      if (action.equivalentsGroup.defaultHidden && action.equivalentsGroup.fromDefault) {
        return state.filter(equivalentsGroup => equivalentsGroup._id !== action.equivalentsGroup._id);
      } else {
        const editedEquivalentsGroup = state.find(equivalentsGroup => equivalentsGroup._id === action.equivalentsGroup._id);
        const index = state.indexOf(editedEquivalentsGroup);
        state[index] = action.equivalentsGroup;
        return [...state];
      }
    case LOAD_EQUIVALENTS_GROUPS:
      return [...action.equivalentsGroups];
    case DELETE_EQUIVALENTS_GROUP:
      return state.filter(equivalentsGroup => equivalentsGroup._id !== action.id);
    default:
      return state;
  }
};