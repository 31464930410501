import { combineReducers } from 'redux';
import currentUser from './currentUserReducer';
import expert from './expertReducer';
import error from './errorReducer';
import patients from './patientsReducer';
import appointments from './appointmentsReducer';
import currentAppointment from './currentAppointmentReducer';
import messages from './messagesReducer';
import foods from './foodsReducer';
import searchedFoods from './searchedFoodsReducer';
import createdFoods from './createdFoodsReducer';
import recipes from './recipesReducer';
import finance from './financeReducer';
import mealPlanTemplates from './mealPlanTemplatesReducer';
import mealPlans from './mealPlansReducer';
import indicationsTemplates from './indicationsTemplatesReducer'
import healthPlans from './healthPlansReducer';
// import notifications from './notificationsReducer';
import purchasedHealthPlans from './purchasedHealthPlansReducer';
import blogPosts from './blogPostsReducer';
import equivalentsGroups from './equivalentsGroupsReducer';
import customMeasurements from './customMeasurementsReducer';
import initialFetches from './initialFetchesReducer';
import favoriteFoods from './favoriteFoodsReducer';
import searchBarFilters from './searchBarFiltersReducer';
import customForms from './customFormsReducer';
import customFields from './customFieldsReducer';
import referrals from './referralsReducer';
import settings from './settingsReducer';
import workouts from './workoutsReducer';
import workoutTemplates from './workoutTemplatesReducer';
import { USER_LOGOUT } from '../actionTypes';


const appReducer = combineReducers({
  currentUser,
  initialFetches,
  expert,
  error,
  patients,
  appointments,
  currentAppointment,
  messages,
  foods,
  searchedFoods,
  createdFoods,
  recipes,
  mealPlanTemplates,
  indicationsTemplates,
  mealPlans,
  healthPlans,
  finance,
  referrals,
  // notifications,
  purchasedHealthPlans,
  blogPosts,
  equivalentsGroups,
  customMeasurements,
  favoriteFoods,
  searchBarFilters,
  customForms,
  customFields,
  workouts,
  workoutTemplates,
  settings,
});


const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

// Credits - Dan Abramov
//https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
export default rootReducer;