import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  SubTitle,
  Title,
  Tooltip,
} from 'chart.js';
import jwtDecode from 'jwt-decode';
import { Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import 'antd/dist/antd.css';
import enUS from 'antd/lib/locale/en_US';
import esES from 'antd/lib/locale/es_ES';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';
import { PATIENTS_SUBDOMAIN_NAME, PATIENTS_WEB_APP_URL, URL_SUBDOMAIN, onSubdomain } from '../../config';
import routes from '../../Routes';
import { setAuthorizationToken, setCurrentUser } from '../store/actions/auth';
import SimpleLoadingPage from '../components/Exception/Loading/SimpleLoadingPage';
import '../../App.css';
import { configureStore } from '../store';
import ErrorBoundary from './ErrorBoundary';

Chart.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, ArcElement, Tooltip, Filler, Legend, Title, SubTitle);

const AuthPage = lazy(() => import('./AuthPage'));
const PatientAuthForm = lazy(() => import('../../PatientsMain/PatientAuthForm'));
const PasswordUpdate = lazy(() => import('../containers/ResetPassword/PasswordUpdate'));
const PasswordReset = lazy(() => import('../containers/ResetPassword/PasswordReset'));
const PatientAuthToken = lazy(() => import('../../PatientsMain/PatientAuthToken'));
const PersonalPageSchedule = lazy(() => import('./PersonalPage/Schedule/PersonalPageSchedule'));
const Main = lazy(() => import('./Main'));
const PatientMain = lazy(() => import('../../PatientsMain/PatientMain'));

const store = configureStore();
if (window.localStorage.jwtToken) {
  setAuthorizationToken(window.localStorage.jwtToken);
  try {
    // Pacientes
    if (jwtDecode(window.localStorage.jwtToken).isPatient) {
      if (onSubdomain && URL_SUBDOMAIN === PATIENTS_SUBDOMAIN_NAME) {
        store.dispatch(setCurrentUser(jwtDecode(window.localStorage.jwtToken)));
      } else {
        window.localStorage.clear();
        store.dispatch(setCurrentUser({}));
      }
    }

    // Expertos
    if (jwtDecode(window.localStorage.jwtToken).isExpert) {
      if (onSubdomain && URL_SUBDOMAIN === jwtDecode(window.localStorage.jwtToken).personalSubdomain) {
        store.dispatch(setCurrentUser(jwtDecode(window.localStorage.jwtToken)));
      } else {
        window.localStorage.clear();
        store.dispatch(setCurrentUser({}));
      }
    }
  } catch (e) {
    console.log(e);
    store.dispatch(setCurrentUser({}));
  }
}

const theme = createTheme();

const App = () => {
  const { i18n } = useTranslation();
  if (!onSubdomain && window.location.pathname.indexOf(routes.book_appointment) === -1) return null;
  if (onSubdomain && URL_SUBDOMAIN === 'pacientes') {
    return (window.location.href = `${PATIENTS_WEB_APP_URL}${window.location.pathname}`);
  }
  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ConfigProvider locale={i18n.language === 'es' ? esES : enUS}>
            <Suspense fallback={SimpleLoadingPage}>
              <ErrorBoundary>
                <Switch>
                  <Route exact path="/enlaces">
                    <Redirect to={routes.links} />
                  </Route>
                  <Route exact path="/conoceme">
                    <Redirect to={routes.know_me} />
                  </Route>
                  <Route exact path="/reserva">
                    <Redirect to={routes.book_appointment} />
                  </Route>
                  {process.env.NODE_ENV !== 'production' && !onSubdomain && (
                    <Route
                      path={routes.book_appointment}
                      render={(props) => {
                        return <PersonalPageSchedule {...props} personalSubdomain={'santiago'} />;
                      }}
                    />
                  )}
                  <Route
                    exact
                    path={`${routes.login}/:token`}
                    render={(props) => {
                      if (URL_SUBDOMAIN === PATIENTS_SUBDOMAIN_NAME) {
                        return <PatientAuthToken {...props} />;
                      } else {
                        return <AuthPage {...props} />;
                      }
                    }}
                  />
                  <Route
                    exact
                    path={routes.login}
                    render={(props) => {
                      if (URL_SUBDOMAIN === PATIENTS_SUBDOMAIN_NAME) {
                        return <PatientAuthForm {...props} />;
                      } else {
                        return <AuthPage {...props} />;
                      }
                    }}
                  />
                  <Route
                    exact
                    path={`${routes.reset_password}/:token`}
                    render={(props) => {
                      return <PasswordUpdate {...props} isExpert={URL_SUBDOMAIN !== PATIENTS_SUBDOMAIN_NAME} />;
                    }}
                  />
                  <Route
                    exact
                    path={routes.reset_password}
                    render={(props) => {
                      return <PasswordReset {...props} isExpert={URL_SUBDOMAIN !== PATIENTS_SUBDOMAIN_NAME} />;
                    }}
                  />
                  <Route
                    exact
                    path={routes.confirmation}
                    render={(props) => {
                      return <AuthPage {...props} />;
                    }}
                  />
                  <Route
                    exact
                    path={`${routes.welcome}/:token`}
                    render={(props) => {
                      return <AuthPage {...props} />;
                    }}
                  />
                  {URL_SUBDOMAIN === PATIENTS_SUBDOMAIN_NAME ? (
                    <Route
                      exact
                      path={routes.home}
                      render={(props) => {
                        return <PatientMain {...props} />;
                      }}
                    />
                  ) : (
                    <Route
                      path={routes.home}
                      render={(props) => {
                        return <Main {...props} i18n={i18n} />;
                      }}
                    />
                  )}
                  <Redirect to={routes.home} />
                </Switch>
              </ErrorBoundary>
            </Suspense>
          </ConfigProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};

export default App;
