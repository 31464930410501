import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { TRANSLATIONS_ES } from './es/translations';
import { TRANSLATIONS_EN } from './en/translations';
import moment from 'moment';
import 'moment/locale/es';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'es',
    fallbackLng: 'es',
    supportedLngs: ['es', 'en'],
    debug: process.env.REACT_APP_ENV !== 'production',
    resources: {
      es: {
        translation: TRANSLATIONS_ES,
      },
      en: {
        translation: TRANSLATIONS_EN,
      },
    },
    interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        if (moment(value).isValid()) return moment(value).format(format);
        return value;
      },
    },
  });

i18n.on('languageChanged', function (lng) {
  if (lng === 'es') {
    moment.locale('es');
  } else {
    moment.locale('en');
  }
});

export default i18n;
