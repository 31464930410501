import { ADD_CREATED_FOODS, LOAD_CREATED_FOODS, DELETE_CREATED_FOOD, EDIT_CREATED_FOOD } from '../actionTypes';

const INITIAL_STATE = [];

export default function createdFoodsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_CREATED_FOODS:
      return [...state, action.createdFood];
    case EDIT_CREATED_FOOD:
      const editedCreatedFood = state.find(createdFood => createdFood._id === action.createdFood._id);
      if (!editedCreatedFood) {
        // caso cuando se recibe un alimento clonado clonada para poder editar
        return [...state, action.createdFood];
      } else {
        const index = state.indexOf(editedCreatedFood);
        state[index] = action.createdFood;
        return [...state];
      }
    case LOAD_CREATED_FOODS:
      return [...action.createdFoods];
    case DELETE_CREATED_FOOD:
      return state.filter(createdFood => createdFood._id !== action.id);
    default:
      return state;
  }
};