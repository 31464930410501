import { ADD_PATIENT, ADD_PENDING_PATIENT, LOAD_PATIENTS, LOAD_ONE_PATIENT, DELETE_PATIENT, EDIT_PATIENT } from '../actionTypes';

const INITIAL_STATE = [];

export default function patientsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_PATIENT:
      return [...state, action.patient];
    case ADD_PENDING_PATIENT:
      // Experto acepta al paciente pendiente
      if (action.patient) {
        return [...state, action.patient];
      }
      // Experto rechaza al paciente pendiente
      return state;
    case LOAD_PATIENTS:
      return action.patients.map(patient => {
        const foundPatientOnState = state.find(x => x._id === patient._id);
        if (foundPatientOnState) {
          return { ...foundPatientOnState, ...patient }
        }
        return patient;
      })
    case LOAD_ONE_PATIENT:
      const loadedPatient = state.find(patient => patient._id === action.patient._id)
      if (loadedPatient) {
        const index = state.indexOf(loadedPatient);
        state[index] = action.isBasicFetch ? { ...state[index], ...action.patient } : action.patient;
        return [...state];
      } else {
        return [...state, action.patient];
      }
    case DELETE_PATIENT:
      return state.filter(patient => patient._id !== action.id);
    case EDIT_PATIENT:
      const editedPatient = state.find(patient => patient._id === action.patient._id);
      const indexPatient = state.indexOf(editedPatient);
      state[indexPatient] = action.patient;
      return [...state];
    default:
      return state;
  }
};
