import { LOAD_HEALTHPLANS, DELETE_HEALTHPLAN, ADD_HEALTHPLAN, EDIT_HEALTHPLAN } from '../actionTypes';

const INITIAL_STATE = [];

export default function healthPlansReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_HEALTHPLANS:
      return [...action.healthPlans];
    case ADD_HEALTHPLAN:
      return [...state, action.healthPlan];
    case EDIT_HEALTHPLAN:
      const editedHealthPlan = state.find(hp => hp._id === action.healthPlan._id);
      const index = state.indexOf(editedHealthPlan);
      state[index] = action.healthPlan;
      return [...state];
    case DELETE_HEALTHPLAN:
      return state.filter(healthPlan => healthPlan._id !== action.id);
    default:
      return state;
  }
};

