import { EDIT_APPOINTMENT, LOAD_CURRENT_APPOINTMENT, REMOVE_CURRENT_APPOINTMENT } from '../actionTypes';

const INITIAL_STATE = [];

export default function currentAppointmentReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_CURRENT_APPOINTMENT:
      return [...action.currentAppointment];
    case EDIT_APPOINTMENT:
      if (state.length === 0) {
        return state;
      } else if (state[0]._id === action.appointment._id) {
        return [action.appointment];
      }
      return state
    case REMOVE_CURRENT_APPOINTMENT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
