import { apiCall, setTokenHeader } from '../../services/api';
import { SET_CURRENT_USER, USER_LOGOUT  } from '../actionTypes';
import { addError, removeError } from './error';
import { getSubdomainUrl, URL_SUBDOMAIN } from '../../../config';

export function setCurrentUser(user){
  return {
    type: SET_CURRENT_USER,
    user
  };
}

export function setAuthorizationToken(token) {
  setTokenHeader(token);
}

export function logoutUser(){
  return {
    type: USER_LOGOUT,
  };
}

export function logout(){
  return dispatch => {
    window.localStorage.removeItem("jwtToken");
    setAuthorizationToken(false);
    dispatch(logoutUser());
  };
}

/////////////////////////////////////////// EXPERTO /////////////////////////////////////////////////////
export function authExpert(type, userData){
  return dispatch => {
    return new Promise((resolve,reject) => {
      return apiCall("post",`/api/experts/${type}`,userData)
        .then(({token, needsVerification, ...expert}) => {
          if(URL_SUBDOMAIN === expert.personalSubdomain){
            if(needsVerification){
              // En AuthForm, se redirije a /confirmation para avisar que su cuenta no está verificada
              resolve({ expert, needsVerification });
            } else {
              const logInUser = () => {
                window.localStorage.setItem("jwtToken", token);
                setAuthorizationToken(token);
                dispatch(setCurrentUser(expert));
                dispatch(removeError);
              }
              resolve({ expert, needsVerification, logInUser });
            }
          } else {
            // Está loggeandose en un subdominio que no le pertenece
            window.location.href = `${getSubdomainUrl(expert.personalSubdomain)}/`;
          }
        })
        .catch(err => {
          dispatch(addError(err));
          reject(err.message);
        });
    });
  };
}
export function verifyExpertAccount(personalSubdomain, confirmationToken){
  return dispatch => {
    return new Promise((resolve,reject) => {
      return apiCall("post",`/api/experts/confirmation/`,{
        personalSubdomain,
        confirmationToken
      })
        .then(({token, validSubdomain, validToken, ...expert}) => {
          if(validSubdomain && validToken){
            const logInUser = () => {
              window.localStorage.setItem("jwtToken", token);
              setAuthorizationToken(token);
              dispatch(setCurrentUser(expert));
              dispatch(removeError);
            }
            resolve(logInUser);
          } else {
            reject("Invalid token");
          }
        })
        .catch(err => {
          console.log(err)
          reject(err.message);
        });
    });
  };
}

export function welcomeSignup(personalSubdomain, welcomeToken){
  return dispatch => {
    return new Promise((resolve,reject) => {
      return apiCall("post",`/api/experts/welcomeSignup/`,{
        personalSubdomain,
        welcomeToken
      })
        .then(({token, ...expert}) => {
            const logInUser = () => {
              window.localStorage.setItem("jwtToken", token);
              setAuthorizationToken(token);
              dispatch(setCurrentUser(expert));
              dispatch(removeError);
            }
            resolve(logInUser);
        })
        .catch(err => {
          console.log(err)
          reject(err.message);
        });
    });
  };
}
/////////////////////////////////////////// PACIENTE /////////////////////////////////////////////////////
export function authPatient(userData){
  return dispatch => {
    return new Promise((resolve,reject) => {
      return apiCall("post",`/api/patients/login`,userData) 
        .then(({token, ...patient})=>{
          window.localStorage.setItem("jwtToken", token);
          setAuthorizationToken(token);
          dispatch(setCurrentUser(patient));
          dispatch(removeError);
          resolve();
        })
        .catch(err => {
          dispatch(addError(err));
          reject(err.message);
        });
    });
  };
}

export function verifyPatientAccount(token, password){
  return dispatch => {
    return new Promise((resolve,reject) => {
      return apiCall("post", `/api/app/confirm-token`, {token, password})
        .then(res => {
          if(res.confirmToken){
            window.localStorage.setItem("jwtToken", res.token);
            setAuthorizationToken(res.token);
            dispatch(setCurrentUser(res.patient));
            dispatch(removeError);
            resolve({ scheduledAppointment_id: res.scheduledAppointment_id, expertChanged: res.expertChanged });
          } else if(res.createPassword){
            resolve({ createPassword: true, patientName: res.patientName, patientGender: res.patientGender, expertName: res.expertName });
          } else {
            reject({ message: 'res_error' });
          }
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  };
}